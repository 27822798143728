import { connect } from 'react-redux';
import { requestUserSignUp } from 'redux/actions';
import SignUpPage from './SignUpPage';

const mapStateToProps = (state) => {
  return {    
    user: state.user    
  }
}

export default connect(mapStateToProps, {
  requestUserSignUp
})(SignUpPage);