import React, { useState, useEffect } from 'react'
import { IconButton, Slider, withStyles } from '@material-ui/core'
import './Feeling.scss'

import UnacceptableIcon from 'assets/images/feelings/unacceptable.png'
import AnnoyedIcon from 'assets/images/feelings/annoyed.png'
import DisappointedIcon from 'assets/images/feelings/disappointed.png'
import FairIcon from 'assets/images/feelings/fair.png'
import NotBadIcon from 'assets/images/feelings/not_bad.png'
import GoodIcon from 'assets/images/feelings/good.png'
import ExcellentIcon from 'assets/images/feelings/excellent.png'

const FEELING = [
  {
    score: -3,
    icon: UnacceptableIcon,
    feel: 'UNACCEPTABLE'
  },
  {
    score: -2,
    icon: AnnoyedIcon,
    feel: 'ANNOYED'
  },
  {
    score: -1,
    icon: DisappointedIcon,
    feel: 'DISAPPOINTED'
  },
  {
    score: 0,
    icon: FairIcon,
    feel: 'FAIR'
  },
  {
    score: 1,
    icon: NotBadIcon,
    feel: 'NOT_BAD'
  },
  {
    score: 2,
    icon: GoodIcon,
    feel: 'GOOD'
  },
  {
    score: 3,
    icon: ExcellentIcon,
    feel: 'EXCELLENT'
  },
]

const FeelingSlider = withStyles({
  root: {
    width: '70%',
    height: 50,
    marginBottom: 50,
    display: 'flex',
    alignItems: 'center',
  },
  thumb: {
    height: 26,
    width: 26,
    backgroundColor: 'currentColor',
    border: '2px solid currentColor',
    marginTop: 0,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 20,
    borderRadius: 20,
    color: '#D9D9D9',
    opacity: 1
  },
  rail: {
    height: 20,
    borderRadius: 20,
    color: '#D9D9D9',
    opacity: 1
  },
})(Slider)

const Feeling = (props) => {
  const { data, value, onChange } = props;
  const [selectedScore, setSelectedScore] = useState(null)
  const [selectedFeeling, setSelectedFeeling] = useState(null)

  const feelings = data.filter(item => item.category === 'feeling') || [];

  //default to FAIR
  const FAIR = feelings.find(item => item.score === 0) || {}
  FAIR['icon'] = FEELING.find(o => o.feel === 'FAIR').icon;

  useEffect(() => {
    if (selectedScore !== null) {
      const newFeeling = feelings.find(item => item.score === selectedScore) || {}
      newFeeling['icon'] = FEELING.find(o => o.score === selectedScore).icon;
      setSelectedFeeling(newFeeling)
      onChange(newFeeling)
    }
  }, [selectedScore])

  const handleSliderChange = (evt, newScore) => {
    if (newScore !== selectedScore) {
      setSelectedScore(newScore)
    }
  }

  return (
    <div className='review-feeling d-flex flex-column align-items-center' >
      <h3 className='title'>How are you feeling?</h3>
      <IconButton className='feeling-button'>
        <img className='feeling-icon' src={selectedFeeling ? selectedFeeling.icon : FAIR.icon} />
      </IconButton>
      <label className='description font-weight-bold'>{selectedFeeling ? selectedFeeling.title : FAIR.title}</label>
      <FeelingSlider
        defaultValue={0}
        min={-3}
        max={3}
        step={1}
        onChange={handleSliderChange}
      />
    </div>
  )
}

export default Feeling