import React from 'react'
import { Container } from 'react-bootstrap'
import './SurveyPage.scss'

const SurveyPage = () =>{
    return(
        <Container className='survey-page-container' >
            <iframe className='form' src='https://docs.google.com/forms/d/e/1FAIpQLSfDLWhb5RcSqOLI1vvVbQmV32_mY6jol7v2g3g_2UILB0BVbw/viewform' />
        </Container>
    )
}

export default SurveyPage