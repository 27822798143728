export const ACTION_TYPES = {
  VALIDATE_LOGIN_REQUESTED        : 'VALIDATE_LOGIN_REQUESTED',
  VALIDATE_LOGIN_SUCCEEDED        : 'VALIDATE_LOGIN_SUCCEEDED',
  VALIDATE_LOGIN_FAILED           : 'VALIDATE_LOGIN_FAILED',

  USER_LOGIN_REQUESTED            : 'USER_LOGIN_REQUESTED',
  USER_LOGIN_WITH_GOOGLE_REQUESTED: 'USER_LOGIN_WITH_GOOGLE_REQUESTED',
  USER_LOGIN_WITH_FACEBOOK_REQUESTED: 'USER_LOGIN_WITH_FACEBOOK_REQUESTED',
  USER_LOGIN_SUCCEEDED            : 'USER_LOGIN_SUCCEEDED',
  USER_LOGIN_FAILED               : 'USER_LOGIN_FAILED',

  USER_SIGN_UP_REQUESTED          : 'USER_SIGN_UP_REQUESTED',
  USER_SIGN_UP_SUCCEEDED          : 'USER_SIGN_UP_SUCCEEDED',
  USER_SIGN_UP_FAILED             : 'USER_SIGN_UP_FAILED',

  USER_LOGOUT_REQUESTED           : 'USER_LOGOUT_REQUESTED',
  USER_LOGOUT_SUCCEEDED           : 'USER_LOGOUT_SUCCEEDED',
  USER_LOGOUT_FAILED              : 'USER_LOGOUT_FAILED',

  REVIEW_POST_REQUEST             : 'REVIEW_POST_REQUEST',
  REVIEW_POST_SUCCEEDED           : 'REVIEW_POST_SUCCEEDED',
  REVIEW_POST_FAILED              : 'REVIEW_POST_FAILED',
  REVIEW_POST_DONE                : 'REVIEW_POST_DONE',

  REVIEW_GET_REQUEST              : 'REVIEW_GET_REQUEST',
  REVIEW_GET_SUCCEEDED            : 'REVIEW_GET_SUCCEEDED',
  REVIEW_GET_FAILED               : 'REVIEW_GET_FAILED',

  FILE_UPLOAD_REQUEST             : 'FILE_UPLOAD_REQUEST',
  FILE_UPLOAD_SUCCEEDED           : 'FILE_UPLOAD_SUCCEEDED',
  FILE_UPLOAD_FAILED              : 'FILE_UPLOAD_FAILED',

  REVIEW_ITEMS_REQUESTED          : 'REVIEW_ITEMS_REQUESTED',
  REVIEW_ITEMS_SUCCEEDED          : 'REVIEW_ITEMS_SUCCEEDED',
  REVIEW_ITEMS_FAILED             : 'REVIEW_ITEMS_FAILED',

  USER_ANSWER_ONBOARDING          : 'USER_ANSWER_ONBOARDING',
  USER_UPDATE_PROFILE_PHOTO       : 'USER_UPDATE_PROFILE_PHOTO',
  USER_UPDATE_PROFILE             : 'USER_UPDATE_PROFILE',
};