import { connect } from 'react-redux';
import HomePage from './HomePage';
import { requestReviewItems } from 'redux/actions';


const mapStateToProps = (state) => {
  return {    
    user: state.user,
    reviewItems: state.reviewItems,
  }
}

export default connect(mapStateToProps, {
  requestReviewItems
})(HomePage);