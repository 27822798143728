import React from 'react'
import { Container } from 'react-bootstrap'

const MeetTheTeam = () => {
    return (
        <Container className='text-left main-container' >
            <h3>Meet the Team</h3>
            <br />
            <ol type='a'>
                <b><li>Our Project Funder</li></b><br />
                <p>Innovate UK, part of UK Research and Innovation, and the <a target="_blank" href='https://www.might.org.my/' >Malaysian Industry-Government Group for High Technology</a> (MIGHT) are working collaboratively under the Newton-Ungku Omar Fund. Together they will invest up to £1 million (RM 6.5 million) for innovative projects that provide collaborative solutions of urban mobility and waste to wealth in Malaysia through to the Newton Ungku Omar Fund Grand Challenge 2019 Programme.</p>

                <br />
                <b><p>About Innovate UK</p></b>
                <p>Innovate UK, part of UK Research and Innovation, is the UK’s innovation funding agency. As well as funding projects in the UK, the agency helps translate the UK’s innovation expertise into business solutions in lower and middle income countries.</p>
                <p>Innovate UK enables collaborative solutions to overseas socio-economic challenges. The agency is supporting companies from the UK to establish global partnerships and exchange knowledge with organisations in some of the most dynamic markets in the world.</p>

                <br />
                <b><p>About MIGHT</p></b>
                <p>The Malaysian Industry-Government Group for High Technology (MIGHT) is a not-for-profit company limited by guarantee under the purview of the Ministry of Science, Technology and Innovation (MOSTI). MIGHT, plays a key role in developing Malaysia’s high technology for business through its role as a think-tank, private-public consensus building and business nurturing platforms. It is an organisation built on the strength of public-private partnership with more than 80 members, both local and international, from industry, government and academia</p>
                <br />
                <b><li>Our Lead Project Partners</li></b>
                <br />
                <b><p>About Atur Trafik Sdn Bhd (ATUR TRAFIK)</p></b>
                <p>ATUR TRAFIK is a multi-disciplinary organization established in 2008 to continue the operations of Perunding Atur Sdn Bhd that was established in 1988. Our professionals are committed to create smart mobility solutions to promote sustainable and comprehensive transportation planning to create more liveable environment in Malaysia. Our professionals have been providing inputs in the formulation of National Highway Network Development Plans (HNDP), National Physical Plans (RFN), Regional and Urban Comprehensive Transport studies, Klang Valley Bus Transport Study, Transport Master Planning for Mega Development Projects, Feasibility Studies for Roads and Rail Privatisation Projects, etc.  We have also conducted many large scale traffic surveys and have developed a library of transport information and data for both road and rail transport sytems, especially in the Klang Valley.  We are familiar with the key stakeholders in the transport industry and have a proven track record in engaging community, authorities and operators to discuss and identify solutions to multiple transport issues in Malaysia, especially within the Klang Valley.</p>

                <br />
                <b><p>About <a target="_blank" href='https://www.itpworld.net/about-us' >Integrated Transport Planning Ltd (ITP)</a></p></b>
                <p>ITP is an independent sustainable transport planning and research consultancy, formed in 1998. We employ a team of skilled professionals who share a passion for well-designed sustainable mobility that meets people's needs and matches local contexts. Our mix of skills and interests brings great knowledge and insight to our work for public and private sector clients worldwide. We approach transport planning and research projects in a practically-focused way and listen carefully to our clients’ needs so we understand what they are seeking to achieve. Our work is widely recognised for its quality, innovation, and insight. We pride ourselves on being able to work on projects that make a real difference and, literally improve the way the world moves.</p>

                <br />
                <b><p>About Connected Places Catapult</p></b>
                <p>The Connected Places Catapult accelerates smarter living and travelling in and between the places of tomorrow. We focus on growing businesses with innovations in mobility services and the built environment that enable new levels of physical, digital and social connectedness. The Connected Places Catapult operates at the intersection between public and private sectors and between local government and transport authorities. We convene the disparate parts of the market to help innovators navigate the complexity of doing business, creating new commercial opportunities and improving productivity, socio-economic and environmental benefits for places.</p>
                <br />
                <b><li>Our Local Supporting Team</li></b>
                <br />
                <b><p>About HMS Perunding Sdn Bhd (HMS)</p></b>
                <p>HMS is an engineering consultancy firm established in 1994 with vast experience covering various transport engineering projects and in particular in the planning, design and deployment of Intelligent Transport Systems (ITS) with emphasis in the areas of Traffic Control and Surveillance Systems (TCSS), Electronic Toll Collections (ETC), Advanced Public transport Systems (APTS), facility management, command and control centers and telematics. HMS was involved in the Malaysia ITS Master Plan and ITS System Architecture Study; the Integrated Transport Information System (ITIS-KL) project; various taxi and bus related studies and telematics/fleet related deployment, including the Cyberjaya Dedicated Transport System and the Klang Valley Bus Performance Monitoring Hub System. The ITIS-KL Project is the 1st regional deployment of ITS in Malaysia, encompassing key ITS applications, platforms and delivery systems.  Part of the wide ranging scope for IT IS-KL were the development of facility management, traffic network and a region wide simulation model, which was partly calibrated based historical OD information and real-time traffic volumes from vehicle detectors.</p>

                <br />
                <b><p>About Hexon Data Sdn Bhd</p></b>
                <p>Hexon Data Sdn Bhd is a public transport platform that helps transit operators of any size to increase revenue and improve reputation via Artificial Intelligence (AI), analytics, and mobile ticketing. The team strives to help bus operators to ensure sustainable profitability while achieving social impact by earning customers’ trust and empowering drivers. Its solution has helped 20,000 citizens in Petaling Jaya to take public buses more efficiently, empowered more than 30 bus drivers and handled close to 1,000 complaints since 2018.</p>

                <br />
                <b><p>About Computer Applied Systems Engineering Sdn Bhd (CASE)</p></b>
                <p>CASE was established in 1984 as a software developer. Today, besides software development, it is supplying CAD/CAM/CAE systems, network products, PCs, plotters as well as providing electronic business services, maintenance and training. CASE  had won the “Retail Application Developer Award (2002)” from Microsoft USA and “Ready for Store (2003)” from IBM USA.  CASE has since successfully completed many projects in software development and system integration included integrating e-payment systems.
</p>



            </ol>
        </Container>
    )
}

export default MeetTheTeam