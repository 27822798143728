import React, { useState, useEffect } from 'react'
import "./ForgotPasswordPage.scss"
import { Loader } from 'components';
import { Card, Typography, CardHeader, TextField, Button } from '@material-ui/core'
import { Link, Redirect } from "react-router-dom";
import { isEmail } from 'validator'
import axios from 'axios';
import { API_END_POINTS } from '../../config';
import EmailSent from 'assets/images/email-sent.png';

const ForgotPasswordPage = (props) => {
  const [username, setUsername] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = {};

    if (username) {
      if (isEmail(username)) {
        data.email = username;
      } else {
        data.username = username;
      }

      try {
        setLoading(true);
        const response = await axios({
          method: 'post',
          url: API_END_POINTS.FORGOT_PASSWORD,
          data
        })
        if (response.data.error) {
          setError(response.data.message);
          setSuccess(false);
        } else {
          setError('');
          setSuccess(true);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log('ERROR: ', e.message);
      }
    }
  }

  return (
    <div className="forgot-password-page">
      <div className={`forgot-password-container`}>
        <div className={"forgot-password-form"}>
          {
            !success
              ?
              <Card style={{ padding: 30, maxWidth: 400 }} elevation={0} >
                <CardHeader title="Forgot password" />
                <form onSubmit={handleSubmit} >
                  <TextField required value={username} error={error ? true : false} helperText={error} onInput={(e) => setUsername(e.target.value)} style={{ marginTop: 10, marginBottom: 25 }} fullWidth label="USERNAME/EMAIL" />
                  <Button type='submit' style={{ borderRadius: 30, marginBottom: 50, fontWeight: 'bold' }} color='primary' variant="outlined">Submit</Button>
                  <Typography color='primary' >{"Or "}
                    <Link to="/signin" style={{ fontWeight: 'bold' }} >Sign In Here.</Link>
                  </Typography>
                </form>
              </Card>
              :
              <div className='email-sent-success'>
                <img src={EmailSent} />
                <h2>{`Email Sent!`}</h2>
                <p>We have sent you an email containing the link to reset your password</p>
                <Link className='text-decoration-none' to='/' ><Button fullWidth variant="outlined" className='m-1' >Home</Button></Link>
                <Link className='text-decoration-none' to='/signin' ><Button fullWidth variant="outlined" color="primary" className='m-1' >Login</Button></Link>
              </div>
          }
        </div>
      </div>
      <Loader loading={loading} />
    </div>
  )
}

export default ForgotPasswordPage