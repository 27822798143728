import React from 'react'
import './NotificationPage.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Typography, Divider } from '@material-ui/core'
import { Link } from 'react-router-dom'

const dummy = [
    {
        profile: 'Mrs.XYZ profile pic',
        message: 'Mrs.XYZ liked your review',
        timestamp: '2 minutes ago',
    },
    {
        profile: 'ABC profile pic',
        message: 'ABC, USERNAME and 3 other people followed Hhh\'s review',
        timestamp: '1h',
    }
]

const NotificationPage = () => {
    return (
        <Container style={{ backgroundColor: 'white', marginTop: 30, padding:20 }} >
            <Row className='d-flex align-items-center' >
                <Col className='d-flex justify-content-start' xs={12} sm={8} >
                    <Typography variant='h4' > My Notifications</Typography>
                </Col>
                <Col className='d-flex justify-content-end' xs={12} sm={4} >
                    <Link>Notification Setting</Link>
                </Col>
            </Row>
            <div>
                {
                    dummy &&
                    dummy.map(({ profie, message, timestamp }, key) =>
                        <div key={key} >
                            <Row id='menu-item' >
                                <Col xs={10}  className='d-flex justify-content-start align-items-center' >
                                    <div style={{ marginRight:10}} >
                                        <div style={{width: 20, height: 20, backgroundColor: 'black', borderRadius: 20}} />
                                    </div>
                                    <Typography style={{textAlign:'start', flex:1}} variant='subtitle2'>{message}</Typography>
                                </Col>
                                <Col xs={2}  className='d-flex justify-content-start align-items-center' >
                                    <Typography style={{textAlign:'start'}} variant='caption'>{timestamp}</Typography>
                                </Col>
                            </Row>
                            <Divider />
                        </div>
                    )
                }
            </div>
        </Container>
    )
}

export default NotificationPage 