import React, { useEffect, useState } from 'react'
import './AdvanceSearch.scss'
import { Container, Col, Row } from 'react-bootstrap'
import { TextField, Select, MenuItem, FormControl, InputLabel, ListItemText, ListItemIcon, Button } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import UnacceptableIcon from 'assets/images/feelings/unacceptable.png'
import AnnoyedIcon from 'assets/images/feelings/annoyed.png'
import DisappointedIcon from 'assets/images/feelings/disappointed.png'
import FairIcon from 'assets/images/feelings/fair.png'
import NotBadIcon from 'assets/images/feelings/not_bad.png'
import GoodIcon from 'assets/images/feelings/good.png'
import ExcellentIcon from 'assets/images/feelings/excellent.png'

import { getReview } from 'redux/api';

const feelingsIcon = [UnacceptableIcon, AnnoyedIcon, DisappointedIcon, FairIcon, NotBadIcon, GoodIcon, ExcellentIcon]

const MySelect = ({ children, value, onChange, label }) => (
    <FormControl fullWidth className='mt-2' >
        <InputLabel >{label}</InputLabel>
        <Select
            value={value}
            onChange={onChange}
            className='text-left'
        >
            <MenuItem value={null} >None</MenuItem>
            {children}
        </Select>
    </FormControl>
)

const MyDatePicker = (props) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <DatePicker
            clearable
            fullWidth
            marginBottom
            disableToolbar
            variant="dialog"
            format="yyyy / MM / dd"
            views={["year", "month", "date"]}
            maxDate={new Date().toISOString()}
            {...props}
        />
    </MuiPickersUtilsProvider>
)


const AdvanceSearch = ({ requestReviewItems, user, reviewItems, onDoneSearch, onClose, onStart }) => {
    const [reactionItems, setReactionItems] = useState([])
    const [modeItems, setModeItems] = useState([])
    const [reaction, setReaction] = useState(null)
    const [mode, setMode] = useState(null)
    const [operator, setOperator] = useState(null)
    const [line, setLine] = useState(null)
    const [postedBy, setPostedBy] = useState(null)
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)

    useEffect(() => {
        requestReviewItems()
    }, [])

    useEffect(() => {
        if (reviewItems.length > 0) {
            const reactions = reviewItems.filter(({ category }) => category === 'feeling')
            setReactionItems(reactions)
            const modes = reviewItems.filter(({ category }) => category === 'service')
            setModeItems(modes)
        }
    }, [reviewItems])

    const handleFetchFeed = async () => {
        const filter = {}
        if (reaction) filter['feeling._id'] = reaction
        if (mode) filter['service'] = mode
        if (operator) filter['body.operator_name'] = { $regex: operator, $options: 'i' }
        if (line) filter['body.route_name'] = { $regex: line, $options: 'i' }
        if (postedBy) filter['user'] = postedBy
        if (from || to) {
            const dateRange = {}
            if (from) dateRange['$gt'] = from
            if (to) dateRange['$lt'] = to
            filter['createdAt'] = dateRange
        }

        const query = {
            filter,
            options: { sort: { 'createdAt': -1 } }
        }
        try {
            const res = await getReview(query)
            onDoneSearch(res.data)
        } catch (e) {
            console.log('error with fetching reviews', e)
        }
    }

    const handleSubmit = (e) => {
        onStart()
        e.preventDefault()
        handleFetchFeed()
        onClose()
    }

    const handleResetState = () => {
        setReaction(null)
        setMode(null)
        setOperator(null)
        setLine(null)
        setPostedBy(null)
        setFrom(null)
        setTo(null)
    }

    const handleClear = async () => {
        handleResetState()
        onStart()
        onClose()
        const query = {
            filter: {},
            options: { sort: { 'createdAt': -1 } }
        }
        try {
            const res = await getReview(query)
            onDoneSearch(res.data)
        } catch (e) {
            console.log('error with fetching reviews', e)
        }
    }

    return (
        <Container className='d-flex justify-content-center align-items-center flex-column advance-search-container' >
            <Col sm={12} md={8} className='advance-search-main' >
                <form onSubmit={handleSubmit} >
                    <Row >
                        <Col className='mt-2'>
                            <MyDatePicker
                                label="Date From"
                                value={from}
                                onChange={e => setFrom(e)}
                            />
                        </Col>
                        <Col className='mt-2'>
                            <MyDatePicker
                                label="Date To"
                                value={to}
                                onChange={e => setTo(e)}
                                minDate={from}
                            />
                        </Col>
                    </Row>
                    <MySelect value={reaction || ''} onChange={(e) => setReaction(e.target.value)} label='Reaction'>
                        {
                            reactionItems.length > 0 &&
                            reactionItems.map(({ title, _id, score }, key) => (
                                <MenuItem key={key} value={_id} >
                                    <ListItemIcon><img width={30} src={feelingsIcon[+score + 3]} /></ListItemIcon>
                                    <ListItemText>{title}</ListItemText>
                                </MenuItem>
                            ))
                        }
                    </MySelect>
                    <MySelect value={mode || ''} onChange={(e) => setMode(e.target.value)} label='Mode of Travel'>
                        {
                            modeItems.length > 0 &&
                            modeItems.map(({ title, _id }, key) => (
                                <MenuItem key={key} value={_id} >{title}</MenuItem>
                            ))
                        }
                    </MySelect>
                    <TextField value={operator || ''} onChange={(e) => setOperator(e.target.value)} className='mt-2' fullWidth label='Operator / Company' />
                    <TextField value={line || ''} onChange={(e) => setLine(e.target.value)} className='mt-2' fullWidth label='Line / Route' />
                    <MySelect value={postedBy || ''} onChange={(e) => setPostedBy(e.target.value)} label='Posted by'>
                        <MenuItem value='' >Everyone</MenuItem>
                        <MenuItem value={user._id} >Me only</MenuItem>
                    </MySelect>
                    <Button onClick={handleClear} disableElevation className='mt-3' variant='contained' fullWidth >Clear</Button>
                    <Button disableElevation className='mt-1' variant='contained' color='primary' type='submit' fullWidth >Search</Button>
                </form>
            </Col>
        </Container>
    )
}

export default AdvanceSearch