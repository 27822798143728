import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { TableHead, Tooltip } from '@material-ui/core';

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

export default function CustomPaginationActionsTable({ rows, th, children, actions = false, hideKey = [], objectKey = [], hideKeyChild = [], highlightKeyChild = [], actionComponent = [], onClicked, uniqId, highlightUniqId }) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead className='bg-dark' >
                    <TableRow>
                        <TableCell className='text-light' component='th' align="left">{th}</TableCell>
                        {
                            rows.length > 0 &&
                            Object.keys(rows[0]).map((key, i) => {
                                if (hideKey.includes(key)) return ''
                                if (key===th) return ''
                                if (objectKey.includes(key)) {
                                    return Object.keys(rows[0][key]).map((keyChild, j) => {
                                        if (hideKeyChild.includes(keyChild)) return ''
                                        return (
                                            <TableCell className='text-light' key={i * j} style={{ width: 160 }} align="left">{keyChild}</TableCell>
                                        )
                                    })
                                }
                                return (
                                    <TableCell className='text-light' key={i} style={{ width: 160 }} align="left">{key}</TableCell>
                                )
                            })
                        }
                        {
                            (rows.length > 0 && actions) &&
                            <TableCell className='text-light' style={{ width: 160 }} align="right">Actions</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => (
                        <TableRow key={row[th]} className={highlightUniqId === row[uniqId] ? 'bg-primary' : ''} >
                            <TableCell className={highlightUniqId === row[uniqId] ? 'text-light' : ''} component='th' align="left">{row[th].toString()}</TableCell>
                            {
                                Object.keys(row).map((key, i) => {
                                    if (hideKey.includes(key)) return ''
                                    if (key===th) return ''
                                    if (objectKey.includes(key)) {
                                        return Object.keys(row[key]).map((keyChild, j) => {
                                            if (hideKeyChild.includes(keyChild)) return ''
                                            if (highlightKeyChild.includes(keyChild)) return <TableCell className={highlightUniqId === row[uniqId] ? 'text-light' : ''} key={i * j} style={{ width: 160 }} align="left"><text className={row[key][keyChild] ? 'text-light bg-primary rounded p-1' : ''} >{row[key][keyChild].toString()}</text></TableCell>
                                            return <TableCell className={highlightUniqId === row[uniqId] ? 'text-light' : ''} key={i * j} style={{ width: 160 }} align="left">{row[key][keyChild].toString()}</TableCell>
                                        })
                                    }
                                    return (
                                        <TableCell className={highlightUniqId === row[uniqId] ? 'text-light' : ''} key={i} style={{ width: 160 }} align="left">{row[key].toString()}</TableCell>
                                    )
                                })
                            }
                            {
                                actions &&
                                <TableCell className={highlightUniqId === row[uniqId] ? 'text-light' : ''} style={{ width: 160 }} align="right">
                                    {
                                        actionComponent.length > 0 &&
                                        actionComponent.map(({ title, placement, color, icon }, btnkey) =>
                                            <Tooltip key={btnkey} title={title} placement={placement} arrow >
                                                <IconButton onClick={() => onClicked(row[uniqId])} color={highlightUniqId === row[uniqId] ? 'inherit' : color} >{icon}</IconButton>
                                            </Tooltip>
                                        )
                                    }
                                </TableCell>
                            }
                        </TableRow>
                    ))}

                    {/* {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )} */}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}