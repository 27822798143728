import React from 'react'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import "./TimeTextInput.scss";

const TimeTextInput = (props) => {
  return (
    <div className='text-input'>
      <label className='text-input-label'>{props.title}</label>
      <div className="input-container">
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <DateTimePicker
            InputProps={{
              classes: {
                input: 'text-input-input'
              },
              disableUnderline: true
            }}
            fullWidth
            {...props}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  )
}

export { TimeTextInput };