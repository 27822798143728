import axios from 'axios';
import _ from 'lodash';
import { toast } from "react-toastify";

class ApiRequest {
  static headers() {
    let headers = { 'Content-Type': 'application/json' };
    const store = global.store.getState();
    // if (store.authCredentials['Access-Token']) headers = { ...headers, ...store.authCredentials };
    if (store.user.token) headers = { ...headers, Authorization: `Bearer ${store.user.token}` };
    return headers;
  }

  static showToastError = true;

  static async error(message, status) {
    if (status === 401) { // clean up redux
      window.location = '/signin';
    }
    if (this.showToastError) return toast.error(message);
  }

  static async fetch(options, showToast = true) {
    this.showToastError = showToast
    options.headers = _.merge(this.headers(), options.headers);

    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      const errorMessages = error.response && error.response.data && error.response.data.message;
      const errorCode = error.response && error.response.status;

      this.error(errorMessages, errorCode);
      const errorRes = { errorMessages, errorCode }
      throw errorRes;
    }
  }
}

export { ApiRequest };