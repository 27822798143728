import { ApiRequest } from 'helpers';
import { API_END_POINTS } from 'config';

export function validateLogin(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.VALIDATE_LOGIN}`,
    data: params
  });
}

export function userLogin(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.USER_LOGIN}`,
    data: params
  });
}

export function userLoginWithGoogle(code) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.USER_LOGIN_WITH_GOOGLE}/${code}`,
  });
}

export function userLoginWithFacebook(code) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.USER_LOGIN_WITH_GOOGLE}/${code}`,
  });
}

export function userSignUp(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.USER_SIGN_UP}`,
    data: params
  });
}

export const updateProfilePhoto = (data) => {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.UPDATE_PROFILE_PHOTO}`,
    data
  });
}

export const updateProfile = (data) => {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.UPDATE_PROFILE}`,
    data
  });
}

export const postOnBoardQuestion = (data) => {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.ANSWER_ONBOARD_QUESTION}`,
    data
  });
}

export const getPublicProfile = userid => {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.FETCH_PUBLIC_PROFILE}/${userid}`,
  });
}