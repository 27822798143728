import { ApiRequest } from "helpers"
import { API_END_POINTS } from "config"

export const checkIsAdmin = async () => {
    try {
        await ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.CHECK_IS_ADMIN}`
        }, false)
        return true
    } catch (e) {
        return false
    }
}

export const getAllUser = async () => {
    try {
        const users = await ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.GET_ALL_USER}`
        })
        return users
    } catch (e) {
        return false
    }
}

export const removeAdminPrivilege = async (data) => {
    try {
        const user = await ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.REMOVE_ADMIN_PRIVILEGE}`,
            data
        })
        return user
    } catch (e) {
        return false
    }
}

export const giveAdminPrivilege = async (data) => {
    try {
        const user = await ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.GIVE_ADMIN_PRIVILEGE}`,
            data
        })
        return user
    } catch (e) {
        return false
    }
}

export const getOverviewReport = async () => {
    try {
        const overview = await ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.GET_REPORT_OVERVIEW}`
        })
        return overview
    } catch (e) {
        return false
    }
}

export const downloadFullReport = async () => {
    try {
        const overview = await ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.DOWNLOAD_FULL_REPORT}`,
            responseType: "blob"
        })
        return overview
    } catch (e) {
        return false
    }
}

// export const createCSV = (data) => {
//     var lineDelimiter = '\n';
//     var csv = {
//         'title': '',
//         'head': '',
//         'body': ''
//     };

//     csv.title = 'csv-title.csv';
//     csv.head = '...'; // make your own csv head
//     csv.body = '...'; // make your own csv body with `lineDelimiter` (optional)
//     return csv;
// }

export const downloadCSV = (data, filename) => {
    const csvContent = 'data:text/csv;charset=utf-8,' + data; // use 'data:text/csv;charset=utf-8,\ufeff', if you consider using the excel
    const uri = encodeURI(csvContent);

    const link = document.createElement('a');
    link.href = uri;
    link.download = filename + '.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}