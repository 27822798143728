import React from 'react'
import { TextField } from '@material-ui/core'
import "./TextInput.scss";

const TextInput = (props) => {
  return (
    <div className='text-input'>
      <label className='text-input-label' >{props.title}</label>
      <div
        onInvalid={(e) => {
          e.currentTarget.className = `input-container ${props.multiline ? 'multiline' : ''} border border-danger`
        }}
        onInput={(e) => {
          e.currentTarget.className = `input-container ${props.multiline ? 'multiline' : ''}`
        }}
        className={`input-container ${props.multiline ? 'multiline' : ''}`}>
        <TextField
          InputProps={{
            classes: {
              input: `text-input-input ${props.upperCase ? 'text-uppercase' : ''}`
            },
            disableUnderline: true
          }}
          fullWidth
          {...props}
        />
      </div>
    </div>
  )
}

export { TextInput };