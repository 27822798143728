import { ACTION_TYPES } from "config/action-types"
import { toast } from "react-toastify"

const initialPost = {
    loading: false,
    // successText: undefined,
    // errorStatus: undefined
}

export const postReviewReducer = (state = initialPost, action) => {
    switch (action.type) {
        case ACTION_TYPES.REVIEW_POST_REQUEST:
            return {
                // ...state,
                loading: true,
            }

        case ACTION_TYPES.REVIEW_POST_SUCCEEDED:
            toast.success('Your Review has been submitted!')
            return {
                // ...state,
                loading: false,
                // successText: 'Your Review has been submitted!',
            }

        case ACTION_TYPES.REVIEW_POST_FAILED:
            return {
                // ...state,
                loading: false,
                // errorStatus: action.payload.error
            }
        // case ACTION_TYPES.REVIEW_POST_DONE:
        //     return initialPost
        default:
            return state
    }
}

const initialGet = {
    reviews: [],
    loading: false
}

export const getReviewReducer = (state = initialGet, action) => {
    switch (action.type) {
        case ACTION_TYPES.REVIEW_GET_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ACTION_TYPES.REVIEW_GET_SUCCEEDED:
            return {
                ...state,
                loading: false,
                reviews: action.payload.data
            }

        case ACTION_TYPES.REVIEW_GET_FAILED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}