import React from 'react'
import { Button, TextField } from '@material-ui/core'
import './Service.scss'

const ServiceItem = (props) => (
    <Button 
        className="service-item"
        {...props}
        onClick={() => props.onClick({ id: props._id, title: props.title })}
    >
        {props.title}
    </Button>
);

const Service = (props) =>{
    const { data = [] } = props;
    const services = data.filter(item => item.category === 'service') || [];
    const OTHER = services.find(item => item.title === 'Other') || {};

    return(
        <div className='review-service' >
            <div className='divider'></div>
            <h3 className='title'>Which service?</h3>
            <p className='description' >Please select one only</p>
            <div className='services-list'>
                {
                    services.map((service, index) => <ServiceItem 
                        key={`service-item-${index}`}
                        {...service} 
                        onClick={props.onChange} 
                        color={props.value === service._id ? 'primary' : 'dark'}
                        variant={props.value === service._id ? 'contained' : 'outlined'}
                    />)
                }
            </div>
            {
                props.value === OTHER._id
                ?
                <TextField  
                    className='other-text'
                    multiline 
                    fullWidth
                    placeholder='Please specify'
                    InputProps={{ 
                        classes: {
                            input: 'other-text-input'
                        },
                        disableUnderline: true 
                    }}
                    onChange={(e) => props.onChangeText(e.target.value)}
                />            
                :
                null
            }                        
        </div>
    )
}

export default Service