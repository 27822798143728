import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { eventBusMiddleware } from '../middlewares';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
const { NODE_ENV } = process.env;

const middlewares = [eventBusMiddleware];

if (NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

export function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middlewares)
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
