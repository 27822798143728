import React from 'react'
import { Container } from 'react-bootstrap'
// import '../ContentPages.scss'

const AboutUs = () => {
    return (
        <Container className='text-left main-container' >
            <h3>About MTA</h3>
            <p>
                MaaS Transit Advisor (<b>MTA</b>) is a community engagement platform that gathers public feedback on various travel modes in the effort of representing public voices, interests, and opinions on transportation matters. Use it to express your needs.
            </p>
            <div className='content-container'>
                <h5>Purpose</h5>
                <ol>
                    <li>Harness public’s reviews and provide solutions to improve user experience, service delivery, first-and-last mile, as well as multimodal connectivity to encourage public transport usage, especially in Klang Valley, Malaysia.</li>
                    <li>Gather public opinions, expectations and ideas in order to guide the design and development of a Mobility-as-a-Solution (MaaS) solution.</li>
                </ol>
                <h5>How can I get involved?</h5>
                <p>
                    As one of the main components of urban transportation, the public’s reviews on the current transit services are our main concern in exploring their mobility needs.
                </p>
                <p>
                    We’d love to hear your travelling experience, and how you would expect the MaaS solution to be. Sign up and express your feedback, suggestions and ideas on the following areas:
                </p>
                <ul>
                    <li>Transport experience review</li>
                    <li>Mobility needs</li>
                    <li>MaaS ideation</li>
                </ul>
                <h5>What happens next?</h5>
                <p>
                    Your response will be carefully analysed, and be considered in the demonstration of our mobility plan.
                </p>
            </div>
            {/* <br />
            <h3>Newton-Ungku Omar Fund (NUOF) Grand Challenge 2019</h3>
            <p>
                A project with collaboration between Innovate UK, UK Research and Innovation, and the Malaysian Industry-Government Group for High Technology (MIGHT) under the Newton-Ungku Omar Fund. Check out the link below for more information:
            </p>
            <div className='content-container' >
                <h5>Purpose</h5>
                <p>
                    <a target="_blank" href='https://apply-for-innovation-funding.service.gov.uk/competition/414/overview#summary'>Learn about the project</a><br />
                    <a target="_blank" href='https://www.britishcouncil.my/programmes/newton-ungku-omar-fund'>Learn about the Newton-Ungku Omar Fund</a>
                </p>
                <h5>Objective</h5>
                <p>
                    The project is aimed to develop a mobility innovation that can present real time, integrated transport journey information to help users in getting from A to B in a simple and integrated manner, rather than individual modes and service.
                </p>
                <p>
                    We are conducting a feasibility study to demonstrate how the UK and Malaysian innovators would collaborate to develop a proof-of-concept Mobility-as-a-Service (MaaS) app to increase public transport use in Klang Valley:
                </p>
                <ul>
                    <li>Improving service delivery: The MaaS app will provide a data platform that empowers passengers to make better decisions by making integrated journey information accessible across multiple transportation modes and multiple operators. This helps to make journeys safer, reliable and faster for passengers.</li>
                    <li>First and last mile connectivity: The MaaS app will enable the provision of mobility to be personalised, on demand and responsive to meet the changing needs and circumstances of transport users. Potentially, the app can be extended to include new and emerging transport services such as car-sharing, ride-hailing, bike-sharing, or on demand transit to provide greater choices of connecting people to public transport.</li>
                    <li>Multi-modal connectivity: By putting the passengers first and helping them to get from A to B as efficiently as possible, the MaaS app enables multi-modal transport connectivity through a user-centric entire journey experience, focusing on easy to use, integrated and responsive technological tools.</li>
                </ul>
            </div> */}
        </Container>
    )
}

export default AboutUs