import React, { useState } from 'react'
import { Dialog, Typography, Chip, IconButton, DialogActions } from '@material-ui/core'
import './ReportDialog.scss'
import { postReviewReport } from 'redux/api'
import { toast } from 'react-toastify'
import BackIcon from 'assets/images/step-back.png'
import propTypes from 'prop-types'


const reportReasons = {
    spam: ['It\'s spam'],
    inappropriate: ["False information", "Scam or fraud", "Harassment or Bullying", "Posting Inappropriate Things"],
    profile: ["Fake Account", "Fake Name", "Pretending to be someone else"]
}

// contentType options = Review, Comment, User
const ReportDialog = ({ open, onClose, contentId, contentType }) => {

    const [reportReason, setReportReason] = useState(null)

    const handleReport = async (report) => {
        if (!report) return toast.error('Need to select a reason to report')
        // console.log('test', report)
        try {
            await postReviewReport(contentType, contentId, { report })
            toast.success('Thank you for letting us know!')
        } catch (e) {
            console.log('error when report review', e)
        }
        setReportReason(null)
        onClose()
    }

    const handleClose = () => {
        setReportReason(null)
        onClose()
    }

    return (
        <Dialog fullWidth open={open} onClose={handleClose} >
            <DialogActions className='back-button-container' >
                <IconButton className='back-button' onClick={handleClose}>
                    <img width={20} height={20} src={BackIcon} />
                </IconButton>
            </DialogActions>
            <div className='report-container' >
                <div className='report-container-sub' >
                    <Typography className='font-weight-bold' >Why are you reporting this {contentType}?</Typography>
                    <Typography variant='caption' >You can report the {contentType} after selecting a problem below</Typography>
                </div>
                <div className='report-container-sub' >
                    <div>
                        {
                            reportReasons.spam.map((text, key) => (
                                <Chip style={{ backgroundColor: reportReason === text ? '#965fa155' : 'white' }} key={key} id='report-chip' label={text} variant='outlined' onClick={() => setReportReason(text)} />))
                        }
                    </div>                </div>
                <div className='report-container-sub' >
                    <Typography variant='caption' className='font-weight-bold' >It's inappropriate</Typography>
                    <div>
                        {
                            reportReasons.inappropriate.map((text, key) => (
                                <Chip style={{ backgroundColor: reportReason === text ? '#965fa155' : 'white' }} key={key} id='report-chip' label={text} variant='outlined' onClick={() => setReportReason(text)} />))
                        }
                    </div>
                </div>
                {
                    contentType === 'User' &&
                    < div className='report-container-sub'>
                        <Typography variant='caption' className='font-weight-bold' >It's Fake</Typography>
                        <div>
                            {
                                reportReasons.profile.map((text, key) => (
                                    <Chip style={{ backgroundColor: reportReason === text ? '#965fa155' : 'white' }} key={key + 10} id='report-chip' label={text} variant='outlined' onClick={() => setReportReason(text)} />))
                            }
                        </div>
                    </div>
                }
                <div onClick={() => handleReport(reportReason)} className='block-account-button w-100 btn' >
                    <Typography className='font-weight-bold white-font' >Report</Typography>
                </div>
            </div>
        </Dialog >
    )
}

export { ReportDialog }