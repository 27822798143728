import React, { useState, useEffect, useRef } from 'react'
import { Button } from '@material-ui/core'
import { TextInput, ImageUpload, TimeTextInput, DateTextInput } from 'components'
import { Map, TileLayer, Marker, Tooltip } from 'react-leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import L from 'leaflet'
import * as ELG from "esri-leaflet-geocoder"
import { toast } from 'react-toastify'
import EventBus from 'eventing-bus'
import { ACTION_TYPES } from "config/action-types"
import moment from 'moment';
import _, { capitalize } from 'lodash';
import './TellUsMore.scss'

const issues = [
    { name: 'Driving Speed', value: 1 },
    { name: 'Attitude', value: 2 },
    { name: 'Issued Ticket', value: 3 },
    { name: 'Driving Behaviour', value: 4 },
    { name: 'Smoking', value: 5 },
    { name: 'Using handphone while driving', value: 6 }
];

L.GeoIP = L.extend({
    getPosition: function (ip) {
        try {
            var url = "https://freegeoip.app/json/";
            var result = L.latLng(0, 0);

            if (ip !== undefined) {
                url = url + ip;
            } else {
                //lookup our own ip address
            }

            var xhr = new XMLHttpRequest();
            xhr.open("GET", url, false);
            xhr.onload = function () {
                var status = xhr.status;
                if (status == 200) {
                    var geoip_response = JSON.parse(xhr.responseText);
                    result.lat = geoip_response.latitude;
                    result.lng = geoip_response.longitude;
                } else {
                    console.log("Leaflet.GeoIP.getPosition failed because its XMLHttpRequest got this response: " + xhr.status);
                }
            };
            xhr.send();
            return result;
        } catch (e) {
            console.log('cant fetch location from ip')
            return ({ lat: 3.127887, lng: 101.594490 }) //use pj coord
        }
    }
})
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;
const ipCoordinate = L.GeoIP.getPosition()

const IssueItem = (props) => (
    <Button
        className="issue-item"
        {...props}
        onClick={() => props.onClick(props._id)}
    >
        {props.title}
    </Button>
);

const TellUsMore = (props) => {
    const { data } = props;
    const [timeValue, setTimeValue] = useState(null)
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState([]);
    let reactMap = useRef()

    const [mapCenterDetails, setMapCenterDetails] = useState({
        center: { latitude: ipCoordinate.lat, longitude: ipCoordinate.lng },
        marker: { latitude: ipCoordinate.lat, longitude: ipCoordinate.lng, address: '' },
        zoom: 13
    })

    useEffect(() => {
        const subscribeFileUpload = EventBus.on(ACTION_TYPES.FILE_UPLOAD_REQUEST, () => setUploading(true));
        const subscribeFileUploadSuccess = EventBus.on(ACTION_TYPES.FILE_UPLOAD_SUCCEEDED, onFileUploadSuccess);
        const subscribeFileUploadFailure = EventBus.on(ACTION_TYPES.FILE_UPLOAD_FAILED, () => setUploading(false));

        return () => {
            subscribeFileUpload();
            subscribeFileUploadSuccess();
            subscribeFileUploadFailure();
        };
    })

    useEffect(() => {
        //run once
        searchAndCurrentLocationControl() // enable current location by gps
        //should initialize and reverse user location upon starting
        initializeLocation()
    }, [])

    const searchAndCurrentLocationControl = () => {
        const map = reactMap.leafletElement
        L.control.locate({
            locateOptions: {
                enableHighAccuracy: true
            }
        }).addTo(map)
        const searchResult = new ELG.Geosearch().addTo(map)
        new L.LayerGroup().addTo(map)

        //change marker location upon searchbar
        searchResult.on('results', async (evt) => {
            const { latlng, address } = await reverseGeocoding(evt.latlng)
            const marker = { latitude: latlng.lat, longitude: latlng.lng, address: address.Match_addr }
            onFieldValueChange("location", marker);
            setMapCenterDetails(prev => ({ ...prev, marker }))
        })

        //change marker location upon current gps location
        map.on('locationfound', async (evt) => {
            const { latlng, address } = await reverseGeocoding(evt.latlng)
            const marker = { latitude: latlng.lat, longitude: latlng.lng, address: address.Match_addr }
            onFieldValueChange("location", marker);
            setMapCenterDetails(prev => ({ ...prev, marker }))
        })
    }

    const initializeLocation = async () => {
        const currLatLong = mapCenterDetails.marker
        const { latlng, address } = await reverseGeocoding({ lat: currLatLong.latitude, lng: currLatLong.longitude })
        const marker = { latitude: latlng.lat, longitude: latlng.lng, address: address.Match_addr }
        onFieldValueChange("location", marker);
        setMapCenterDetails(prev => ({ ...prev, marker }))
    }

    const reverseGeocoding = (_latlng) => {
        return new Promise(resolve => {
            new ELG.ReverseGeocode().latlng(_latlng).run((error, data) => {
                if (!error) {
                    resolve({ latlng: data.latlng, address: data.address })
                } else (
                    toast.error(error.details[0])
                )
            })
        })
    }

    const handleDragMarker = async (evt) => {
        const { latlng, address } = await reverseGeocoding(evt.target._latlng)
        const marker = { latitude: latlng.lat, longitude: latlng.lng, address: address.Match_addr }
        onFieldValueChange("location", marker);
        setMapCenterDetails(prev => ({ ...prev, marker }))
    }

    const uploadFilesHandler = async (formData) => {
        props.onFileUpload(formData)
    }

    const onFileUploadSuccess = (response) => {
        setUploading(false);
        let updatedFiles = files;        
        updatedFiles = updatedFiles.concat(response.data);        
        onFieldValueChange("attachments", updatedFiles);        
        setFiles(updatedFiles);
    }

    const onRemoveFiles = (fileIndex) => {
        const updatedFiles = [];

        for (let i = 0; i < files.length; i++) {
            if (i !== fileIndex) {
                updatedFiles.push(files[i]);
            }
        }
        onFieldValueChange("attachments", updatedFiles);
        setFiles(updatedFiles);
    }

    const onFieldValueChange = (fieldName, value, timeVal) => {
        if (fieldName === 'incident_date') {
            const dateTimeObj = moment(props.incidentDate)
            const dateObj = moment(value)
            dateTimeObj.set({
                year: dateObj.get('year'),
                month: dateObj.get('month'),
                date: dateObj.get('date')
            });
            props.onChange('incidentDate', dateTimeObj.format());
        } else if (fieldName === 'incident_time') {
            setTimeValue(timeVal)
            const dateTimeObj = moment(props.incidentDate)
            const timeObj = moment(value, 'HH:mm')
            dateTimeObj.set({
                hour: timeObj.get('hour'),
                minute: timeObj.get('minute')
            });
            props.onChange('incidentDate', dateTimeObj.format());
        } else {
            props.onChange(fieldName, value);
        }
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        props.submitForm()
    }

    return (
        <form onSubmit={handleSubmitForm} className='review-tell-us-more'>
            <div className='divider'></div>
            <h3 className='title'>Tell us more</h3>
            {/* <h4 className='sub-title'>ABOUT WHAT?</h4>
            <div className='issue-list'>
                {
                    sections.map((service, index) => <IssueItem
                        key={`issue-item-${index}`}
                        {...service}
                        onClick={props.onSectionChange}
                        color={props.section === service._id ? 'primary' : 'dark'}
                        variant={props.section === service._id ? 'contained' : 'outlined'}
                    />)
                }
            </div> */}
            <div className="form-container">
                <DateTextInput
                    disableToolbar
                    variant="inline"
                    format="yyyy / MM / dd"
                    title="INCIDENT DATE"
                    views={["year", "month", "date"]}
                    required
                    maxDate={new Date().toISOString()}
                    value={moment(props.incidentDate).format('YYYY-MM-DD')}
                    onChange={e => onFieldValueChange("incident_date", moment(e).format('YYYY-MM-DD'))}
                />
                <TimeTextInput
                    ampm={false}
                    variant="inline"
                    disableToolbar
                    title="INCIDENT TIME"
                    format="hh : mm aa"
                    views={["hours", "minutes"]}
                    invalidDateMessage={false}
                    autoOk
                    required
                    value={timeValue || moment(props.incidentDate).format('HH:mm')}
                    onChange={e => onFieldValueChange("incident_time", moment(e).format('HH:mm'), e)}
                />
                <TextInput
                    multiline
                    required
                    rows={3}
                    title="WHAT WAS YOUR EXPERIENCE"
                    placeholder="The driver was smoking..."
                    onChange={(e) => onFieldValueChange("experience", e.target.value)}
                />
                <ImageUpload
                    loading={uploading}
                    onChange={uploadFilesHandler}
                    data={files}
                    onRemove={onRemoveFiles}
                />
            </div>
            <div className='map-container'>
                <h4 className='sub-title'>WHERE THIS HAPPENED?</h4>
                <span className='legend-text'>Drop over the map to get address.</span>
                <Map
                    ref={m => { if (m) reactMap = m }}
                    center={[mapCenterDetails.center.latitude, mapCenterDetails.center.longitude]} zoom={mapCenterDetails.zoom}>
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                        position={[mapCenterDetails.marker.latitude, mapCenterDetails.marker.longitude]}
                        draggable={true}
                        ondragend={handleDragMarker}
                    >
                        <Tooltip direction='top' offset={[10, 0]} opacity={1}>
                            {mapCenterDetails.marker.address}
                        </Tooltip>
                    </Marker>
                </Map>
            </div>
            <div className="form-container">
                <TextInput
                    title="LINE / ROUTE"
                    placeholder="PJ Old Town"
                    onChange={(e) => onFieldValueChange("route_name", e.target.value)}
                />
                <TextInput
                    title="OPERATOR / COMPANY"
                    placeholder="RapidKL"
                    onChange={(e) => onFieldValueChange("operator_name", e.target.value)}
                />
                <TextInput
                    title="STATION / STOP / TERMINAL"
                    placeholder="Old Town Terminal"
                    onChange={(e) => onFieldValueChange("stop_name", e.target.value)}
                />
                <TextInput
                    upperCase={true}
                    title="PLATE NUMBER"
                    placeholder="VJ 1002"
                    onChange={(e) => onFieldValueChange("vehicle_plate", e.target.value)}
                />
                <TextInput
                    multiline
                    rows={3}
                    title="ANY COMMENT OR SUGGESTION FOR THAT"
                    placeholder="Needs to be improved."
                    onChange={(e) => onFieldValueChange("comment", e.target.value)}
                />
            </div>
            <Button style={{display: props.enableSubmitButton ? '' : 'none'}} type='submit' variant='contained' id='submit-button' color='primary'>Submit</Button>
        </form>
    )
}

export default TellUsMore