import React from 'react'
import { Navbar, Row, Col } from 'react-bootstrap'
import './Footer.scss'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import LogoMaas from 'assets/images/logoMaas.png'

const footerLinks = [
    {
        title: 'Terms of Use',
        to: '/term-of-use'
    },
    {
        title: 'Privacy and Cookies Statement',
        to: '/privacy-cookies'
    },
    {
        title: 'Content Policy',
        to: '/content-policy'
    },
    {
        title: 'Disclaimer',
        to: '/disclaimer'
    },
    // {
    //     title: 'Site Map',
    //     to: '/site-map'
    // }
]

export const Footer = () => {
    return (
        <Navbar className='footer-container text-light' expand="lg" bg='dark' sticky="bottom" >
            <div className='footer-row-main'>
                <Row className='footer-row' >
                    <Col lg={4} xs={12} className='footer-segment' >
                        <Typography variant='h6' className='font-weight-bold' >About MaaS Transit Advisor</Typography>
                        <Typography><Link className='link' to='/about-us' >About Us</Link> </Typography>
                        <Typography><Link className='link' to='/meet-the-team' >Meet The Team</Link> </Typography>
                        <Typography><a className='link' target="_blank" href='https://drive.google.com/file/d/1uShmr4nNTliPU9dDZzIZzWMFQ5EXvACM/view?usp=sharing' >Press Kit</a> </Typography>
                        <Typography><Link className='link' to='/credit-attribution' >Credit/Attribution</Link> </Typography>
                        <Typography><Link className='link' to='/contact-us' >Contact Us</Link> </Typography>
                        <br /><br />
                    </Col>
                    <Col lg={4} xs={12} className='footer-segment' >
                        <Typography variant='h6' className='font-weight-bold' >Explore</Typography>
                        <Typography><Link className='link' to='/review' >Write a Review</Link> </Typography>
                        <Typography><Link className='link' to='/popular' >Vote an Experience</Link></Typography>
                        <Typography><Link className='link' to='/survey' >Take Survey, Earn Reward</Link></Typography>
                        <Typography><Link className='link' to='/maas' >MaaS Ideation</Link></Typography>
                        <Typography><Link className='link' to='/faq' >FAQ</Link></Typography>
                        {/* <Typography><Link className='link' to='/report-bug' >Report a Bug</Link></Typography> */}
                    </Col>
                </Row>
                {/* <Row className='footer-row' lg={6} md={3} sm={1} >
                    <img style={{ width: '90vw' }} src={LogoMaas} />
                </Row> */}
                <Row className='footer-row' >
                    <small >&#169; Copyright 2023 MaaS Transit Advisor. All Rights Reserved.
                        {" "}
                        {
                            footerLinks.map(({ title, to }, key) => (
                            <Link className='text-white' key={key} to={to} >{"| "}{title}{" "}</Link>
                            ))
                        }
                        {" "}
                    </small>
                    <small>MaaS Transit Advisor (MTA) is a community engagement platform that gathers public feedback on various travel modes. We aggregate, verify and curate user-generated contents, reviews, feedback, and provide recommendations to help transit operators to improve service delivery.</small>
                </Row>
            </div>
        </Navbar>
    )
}
