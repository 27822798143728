import React, { Component } from 'react';
import "./Loader.scss";

class Loader extends Component {
  render() {
    if (this.props.loading) {
      return (
        <div className="app-loader">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      );      
    }
    return null;
  }
};

export { Loader };