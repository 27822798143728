import React, { useState } from 'react'
import "./ResetPasswordPage.scss"
import { Loader } from 'components';
import { Card, CardHeader, TextField, Button } from '@material-ui/core'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { API_END_POINTS } from '../../config';

import VerifySuccessImage from 'assets/images/verify-success.png';
import VerifyFailedImage from 'assets/images/verify-failed.png';

const isValidPassword = (password) => {
  const regExp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return regExp.test(password);
}

const ResetPasswordPage = (props) => {
  const { code } = useParams();
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')  
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSetPassword = (e) => {
    setPasswordError('')
    setPassword(e.target.value);
  }

  const onSetConfirmPassword = (e) => {
    setConfirmPasswordError('')
    setConfirmPassword(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (password && confirmPassword) {
      if (!isValidPassword(password)) {
        return setPasswordError('Should consist of alphanumic and atleast one special character');
      } 

      if (password !== confirmPassword) {
        return setConfirmPasswordError('Confirm password did not match');
      } 

      try { 
        setLoading(true);        
        const response = await axios({
          method: 'post',
          url: API_END_POINTS.UPDATE_PASSWORD,
          data: {
            code,
            password
          }
        })
        if (response.data.error) {
          setError(response.data.message);
          setSuccess(false);
        } else {
          setError('');
          setSuccess(true);
        }
        setLoading(false);            
      } catch (e) {
        setLoading(false);        
        console.log('ERROR: ', e.message);
      }
    }
  }

  return (
    <div className="reset-password-page">
      <div className={`reset-password-container`}>
        <div className={"reset-password-form"}>
          {
            !success && !error
            ?
            <Card style={{ padding: 30, maxWidth: 400 }} elevation={0} >
              <CardHeader title="Reset password" />
              <form onSubmit={handleSubmit} >
                <TextField required value={password} type="password" error={passwordError ? true : false} helperText={passwordError} onInput={onSetPassword} style={{ marginTop: 10, marginBottom: 25 }} fullWidth label="ENTER NEW PASSWORD" />                              
                <TextField required value={confirmPassword} type="password" error={confirmPasswordError ? true : false} helperText={confirmPasswordError} onInput={onSetConfirmPassword} style={{ marginTop: 10, marginBottom: 25 }} fullWidth label="CONFIRM NEW PASSWORD" />                              
                <Button type='submit' style={{ borderRadius: 30, marginTop: 20, marginBottom: 20, fontWeight: 'bold' }} color='primary' variant="outlined">Submit</Button>                
              </form>              
            </Card>
            :
            null
          }
          {
            !error && success
            ?
            <div className='update-success'>
              <img src={VerifySuccessImage} />
              <h2>{`Password Updated!`}</h2>
              <p>Your password is updated successfully</p>                
              <Link to="/signin" style={{ fontWeight: 'bold' }} >Sign In here.</Link>
            </div>
            :
            null
          }     
          {
            error && !success
            ?
            <div className='update-success'>
              <img src={VerifyFailedImage} />
              <h2>{`Error!`}</h2>
              <p>{error}</p>                
              <Link to="/forgot-password" style={{ fontWeight: 'bold' }} >Request reset Link.</Link>
            </div>
            :
            null
          }     
        </div>
      </div> 
      <Loader loading={loading} />
    </div>
  )
}

export default ResetPasswordPage