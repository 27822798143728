import { ACTION_TYPES } from "config/action-types"
import { INITIAL_STATE } from '../store';

export const reviewItemsReducer = (state = INITIAL_STATE.reviewItems, action) => {
  switch (action.type) {
      case ACTION_TYPES.REVIEW_ITEMS_REQUESTED:
          return [
              ...state              
          ]

      case ACTION_TYPES.REVIEW_ITEMS_SUCCEEDED:
          return [                         
              ...action.payload.data              
          ]

      case ACTION_TYPES.REVIEW_ITEMS_FAILED:
          return [
              ...state
          ]
      default:
          return state
  }
}