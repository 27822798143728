import { ApiRequest } from 'helpers';
import { API_END_POINTS } from 'config';

export function vote(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.VOTE_POLL}`,
    data: params
  });
}

export function getPollResult(question) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.RESULT_POLL}`,
    params: { question }
  });
}
