import React from 'react'
import { Container } from 'react-bootstrap'
// import '../ContentPages.scss'

const CreditAttribution = () => {
    return (
        <Container className='text-left main-container' >
            <div>
                <h3>Credit / Attribution Statement</h3>
                <p>Members from MaasTransitAdvisor site would like to acknowledge the following works created by the respective authors that we used or adapted partially or fully in the creation of this Site:</p>
                <div className='content-container'>
                    <ol>
                        <li>Car vector by 3ab2ou extracted from <a target="_black" href="http://www.freepik.com" >Freepik</a> and is used under a <a target="_blank" href="https://creativecommons.org/licenses/by/2.0/" >CC-BY 2.0 license</a>. Modifications to this photo include cropping.</li>
                        <li>Heart vector by rawpixel.com extracted from <a target="_black" href="http://www.freepik.com" >Freepik</a> and is used under a <a target="_blank" href="https://creativecommons.org/licenses/by/2.0/" >CC-BY 2.0 license</a>. Modifications to this photo include cropping.</li>
                        <li>Openstreetmap (OSM) -<a target="_black" href="http://www.openstreetmap.org/" >http://www.openstreetmap.org.</a>Openstreetmap is a large effort to get geographical information from around the world. The Global Gazetteer uses data within OSM to complement the Geonet data.</li>
                        <li>Petaling Jaya bus routes data from <a target="_black" href="http://www.mbpj.gov.my" >Petaling Jaya City Council (MBPJ)</a> obtained through <a target="_blank" href="https://www.hexondata.com/" >Hexon Data Sdn Bhd</a>.</li>
                        <li>Map layout is extracted from Google My Maps. Modifications to this photo include cropping.</li>
                        <li>Pictures from <a target="_black" href="https://lyko.tech/en/" >https://lyko.tech/en/.</a>. Modifications to this photo include cropping.</li>
                        <li>Pictures from <a target="_black" href="https://citymapper.com/singapore" >https://citymapper.com/singapore.</a>. Modifications to this photo include cropping.</li>
                        <li>Pictures from <a target="_black" href="https://www.searchenginejournal.com/" >https://www.searchenginejournal.com/.</a>. Modifications to this photo include cropping.</li>
                        <li>Pictures from <a target="_black" href="https://transportr.app/" >https://transportr.app/.</a>. Modifications to this photo include cropping.</li>
                        <li>Pictures from <a target="_black" href="https://www.larepublica.net/" >https://www.larepublica.net/.</a>. Modifications to this photo include cropping.</li>
                        <li>Pictures from <a target="_black" href="https://www.shutterstock.com/" >https://www.shutterstock.com/.</a>. Modifications to this photo include cropping.</li>
                        <li>Vector images of cabs, rail, bus, cycling, red push-pin and yellow push-pin from Google Map <a target="_black" href="http://maps.google.com/mapfiles/kml/shapes" >(http://maps.google.com/mapfiles/kml/shapes)</a>. Modifications to this photo include cropping.</li>
                        <li>Icon car made from <a target="_black" href="http://www.onlinewebfonts.com/icon" >http://www.onlinewebfonts.com/icon</a>. Icon Fonts is licensed by CC BY 3.0. Modifications to this photo include cropping.</li>
                        <li>Icons made by mavadee <a target="_black" href="https://www.flaticon.com" >https://www.flaticon.com</a>. Modifications to this photo include cropping.</li>
                        <li>MaaS features from Neil Taylor, Integrated Transport Planning Ltd </li>
                        {/* <li>Walking icon from <a target="_black" href="https://icons8.com/icons" >https://icons8.com/icons</a>. Modifications to this photo include cropping.</li> */}
                        <li>The method of writing the attribution statement from <a target="_black" href="https://pressbooks.library.ryerson.ca/authorsguide/chapter/attribution-statements/" >Ryerson University</a></li>
                    </ol>
                </div>
                <p>We hereby express our appreciation to the aforementioned authors that have allowed us to use their works partially or fully, either directly or indirectly.</p>
                <i>Updated: 6 July 2020</i>
            </div>
        </Container>
    )
}

export default CreditAttribution