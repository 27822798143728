import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { requestUserLoginWithGoogle, requestUserLoginWithFacebook } from 'redux/actions'
import { CircularProgress, Typography, Dialog, DialogContent } from '@material-ui/core';

const SocialLoginRedirect = ({ requestUserLoginWithGoogle, requestUserLoginWithFacebook, user }) => {
    const { provider, code } = useParams()
    const [count, setCount] = useState(0)

    useEffect(() => {
        switch (provider) {
            case 'google':
                requestUserLoginWithGoogle(code)
                break

            case 'facebook':
                requestUserLoginWithFacebook(code)
                break
        }
    }, [])

    useEffect(() => {
        if (count >= 1) {
            const timeout = setTimeout(() => {
                window.location = '/'
                clearTimeout(timeout)
            }, 1000)
        }
        setCount(count + 1)
    }, [user])

    return (
        <Dialog fullWidth open={true} className='text-center' >
            <DialogContent>
                <CircularProgress />
                <Typography variant='h5' >
                    Verifying your account
                </Typography>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {
    requestUserLoginWithGoogle,
    requestUserLoginWithFacebook
})(SocialLoginRedirect);