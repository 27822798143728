import { takeEvery, call, put } from 'redux-saga/effects'
import { ACTION_TYPES } from 'config/action-types'
import { reviewPostSuccess, reviewPostFailure, reviewPostDone, reviewGetSuccess, reviewGetFailure, uploadFileSuccess, uploadFileFailure, reviewItemsSuccess, reviewItemsFailure } from 'redux/actions/review.actions'
import { postReview, getReview, uploadFile, getReviewItems } from 'redux/api/review.api'

export function* watchReviewRequests() {
    yield takeEvery(ACTION_TYPES.REVIEW_POST_REQUEST, requestReviewPost)
    yield takeEvery(ACTION_TYPES.REVIEW_GET_REQUEST, requestReviewGet)
    yield takeEvery(ACTION_TYPES.FILE_UPLOAD_REQUEST, requestFileUpload)
    yield takeEvery(ACTION_TYPES.REVIEW_ITEMS_REQUESTED, requestReviewItems);
}

function* requestReviewPost(action) {
    try {
        const res = yield call(postReview, action.payload)
        yield put(reviewPostSuccess(res))
    } catch (e) {
        yield put(reviewPostFailure(e))
    }

    // yield delay(10000)
    // yield put(reviewPostDone())
}

function* requestReviewGet(action) {
    try {
        const res = yield call(getReview, action.payload)
        if (res.data.length > 0) {
            yield put(reviewGetSuccess(res))
        }
    } catch (e) {
        yield put(reviewGetFailure())
    }
}

function* requestFileUpload(action) {
    try {
        const res = yield call(uploadFile, action.payload)        
        yield put(uploadFileSuccess(res))        
    } catch (e) {
        yield put(uploadFileFailure())
    }
}

function* requestReviewItems(action) {
    try {
        const res = yield call(getReviewItems, action.payload)        
        yield put(reviewItemsSuccess(res))        
    } catch (e) {
        yield put(reviewItemsFailure())
    }
}