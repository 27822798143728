import React, { useState, useEffect } from 'react'
import './OnBoardQuestionPage.scss'
import { Container } from 'react-bootstrap'
import { SimpleCollapse } from 'components'
import { Card, Select, MenuItem, Button, CardHeader, Dialog, DialogContent, Typography } from '@material-ui/core'
import { Loader } from 'components'
import SubmittedIcon from 'assets/images/submitted.png'
import { Link } from 'react-router-dom'

const OnboardSubmittedCard = ({ name }) => (
    <Dialog fullWidth open className='review-submitted-dialog'>
        <DialogContent className='review-submitted-card'>
            <img width={30} height={30} src={SubmittedIcon} />
            <Typography variant='h5' style={{ fontWeight: 'bold', margin: '10px 0px 10px 0px' }} color='primary' className='text-capitalize' >Thank you {name}!</Typography>
            <p className='description'>You can always change your preference in Profile page</p>
            <Link className='btn btn-primary btn-ok' to="/popular">Got it!</Link>
        </DialogContent>
    </Dialog>
)

const ageGroup = [
    'Below 15',
    '15 - 19',
    '20 - 24',
    '25 - 29',
    '30 - 34',
    '35 - 39',
    '40 - 44',
    '45 - 49',
    '50 - 54',
    '55 - 59',
    '60 - 64',
    '65 and above',
]

const frequency = [
    "Everyday",
    "4 – 6 times a week",
    "1 – 3 times a week",
    "Once a month",
    "Every 2 – 3 months",
    "2 – 3 times per year",
    "Never"
]

const OnBoardQuestionPage = ({ postOnBoardQuestion, user }) => {
    const [gender, setGender] = useState(null)
    const [age, setAge] = useState(null)
    const [nationalStatus, setNationalStatus] = useState(null)
    const [location, setLocation] = useState(null)
    const [disability, setDisability] = useState(null)
    const [publicTransportUsage, setPublicTransportUsage] = useState(null)
    const [allStates, setAllStates] = useState(null)
    const [submiting, setSubmiting] = useState(false)
    const currUserName = global.store.getState().user

    useEffect(() => {
        const states = require('assets/data/MalaysiaStates.json')
        setAllStates(states)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmiting(true)
        const data = {
            gender, age, nationalStatus, location, disability, publicTransportUsage
        }
        // console.log(data)
        postOnBoardQuestion(data)
        handleResetForm()
    }

    const handleResetForm = () => {
        setGender(null)
        setAge(null)
        setNationalStatus(null)
        setLocation(null)
        setDisability(null)
        setPublicTransportUsage(null)
    }

    return (
        <Container>
            {
                submiting &&
                <OnboardSubmittedCard name={user.name} />
            }
            <Loader loading={user.loading} />
            <Card elevation={0} className='on-board-main' >
                <CardHeader className='text-capitalize' title={`Welcome Onboard ${currUserName.name}!`} />
                <h6 className="mb-5" >But before we proceed, Please fill in the questions below</h6>
                <form onSubmit={handleSubmit} >
                    <SimpleCollapse open >
                        <div className='d-flex flex-column align-items-center' >
                            <h5 className='title'>Gender</h5>
                            <Select value={gender} className='input-field text-left mb-5' onChange={evt => setGender(evt.target.value)} >
                                <MenuItem value={'male'} >Male</MenuItem>
                                <MenuItem value={'female'} >Female</MenuItem>
                                <MenuItem value={'other'} >Non-binary / third gender</MenuItem>
                            </Select>
                        </div>
                        {/* <div className='divider'></div> */}
                    </SimpleCollapse>
                    <SimpleCollapse open={gender} >
                        <div className='d-flex flex-column align-items-center' >
                            <h5 className='title'>Age</h5>
                            <Select value={age} className='input-field text-left mb-5' onChange={evt => setAge(evt.target.value)} >
                                {
                                    ageGroup.map((val, key) => (
                                        <MenuItem key={key} value={val} >{val}</MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                        {/* <div className='divider'></div> */}
                    </SimpleCollapse>
                    <SimpleCollapse open={age} >
                        <div className='d-flex flex-column align-items-center pt-5' >
                            <h5 className='title'>You are</h5>
                            <Select required value={nationalStatus} className='input-field text-left' onChange={evt => setNationalStatus(evt.target.value)} >
                                <MenuItem value={'local'} >a local</MenuItem>
                                <MenuItem value={'ex-local'} >an ex-local</MenuItem>
                                <MenuItem value={'foreigner'} >a foreigner</MenuItem>
                                <MenuItem value={'tourist'} >a tourist</MenuItem>
                            </Select>
                            <h5 className='title pt-5'>living in</h5>
                            <Select value={location} native className='input-field text-left mb-5' onChange={evt => setLocation(evt.target.value)} >
                                <option value={null} >{location}</option>
                                {
                                    allStates &&
                                    Object.keys(allStates).map((state, key1) => (
                                        <optgroup className="mt-5" label={state} key={key1} >
                                            {
                                                allStates[state].map((district, key2) => (
                                                    <option value={`${district}, ${state}, MALAYSIA`} key={key1 * key2} >{district}</option>
                                                ))
                                            }
                                        </optgroup>
                                    ))
                                }
                            </Select>
                        </div>
                        {/* <div className='divider'></div> */}
                    </SimpleCollapse>
                    <SimpleCollapse open={location} >
                        <div className='d-flex flex-column align-items-center pt-5' >
                            <h5 className='title'>Do you use wheelchair or walking stick</h5>
                            <Select value={disability} className='input-field text-left mb-5' onChange={evt => setDisability(evt.target.value)} >
                                <MenuItem value={true} >Yes</MenuItem>
                                <MenuItem value={false} >No</MenuItem>
                            </Select>
                        </div>
                        {/* <div className='divider'></div> */}
                    </SimpleCollapse>
                    <SimpleCollapse open={disability !== null ? true : false} >
                        <div className='d-flex flex-column align-items-center pt-5' >
                            <h5 className='title'>How often you use public transport (bus, train, park and ride, etc.)</h5>
                            <Select value={publicTransportUsage} className='input-field text-left mb-5' onChange={evt => setPublicTransportUsage(evt.target.value)} >
                                {
                                    frequency.map((value, key) => (
                                        <MenuItem value={value} key={key} >{value}</MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                        <Button type='submit' color='primary' variant='contained' fullWidth >SUBMIT</Button>
                    </SimpleCollapse>
                </form>
            </Card>
        </Container>
    )
}

export default OnBoardQuestionPage