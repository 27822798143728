import { SettingPage } from './SettingPage'
import { connect } from 'react-redux'
import { updateProfile, updateProfilePhoto } from 'redux/actions'


const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, {
    updateProfile,
    updateProfilePhoto
})(SettingPage);
