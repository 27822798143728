import React, { useRef } from 'react'
import { Button } from '@material-ui/core';
import { Loader } from 'components';
import "./ImageUpload.scss";

import ImageUploadIcon from 'assets/images/image-upload.png'
import CreateIcon from 'assets/images/icons-create.png';
import PdfIcon from 'assets/images/pdf-icon.png'

const FileItem = (props) => (
  <div className='file-item-container'>
    {
      props.url.substring(props.url.lastIndexOf(".") + 1) === 'pdf'
      ?
      <img src={PdfIcon} className="file-item-icon" />
      :
      <img src={props.url} className="file-item-image" />
    }   
    <div className="file-item-name">{props.originalName}</div> 
    <button className="file-item-delete" onClick={() => props.onRemove(props.index)}>REMOVE</button>
  </div>
)

const ImageUpload = (props) => {
  let fileRef = useRef(null)

  const uploadFile = () => {
    fileRef.click();
  }

  const onChooseUpload = () => {
    if (fileRef.files.length > 0) {
      const formData = new FormData(); 
      
      for (let i = 0; i < fileRef.files.length; i++) {
        formData.append('files', fileRef.files[i]);
      }
      props.onChange(formData);
    }
  }

  return (
      <div className='image-upload'>
        <h4 className='title'>UPLOAD IMAGE</h4>  
        <span className='legend-text'>Attach incident photos / screenshots</span>
        {
          props.data && props.data.length > 0
          ?
          <div className="image-list">
            {
              props.data.map((file, index) => <FileItem 
                index={index} 
                {...file} 
                key={`review-file-${index}`} 
                onRemove={props.onRemove}
              />)
            }
          </div>
          :
          <img className='upload-icon' src={ImageUploadIcon} />        
        }
        {
          props.data.length === 0
          ?
          <Button className="upload-button" variant="outlined" onClick={uploadFile}>BROWSE FILES</Button>                  
          :
          <Button className="upload-button" variant="outlined" onClick={uploadFile}>
            <img src={CreateIcon} className="create-icon" /> ADD FILES
          </Button>                  
        }        
        <input 
          type="file" 
          name="files" 
          ref={input => fileRef = input} 
          multiple 
          style={{ display: 'none' }} 
          accept="image/*, application/pdf"
          onChange={onChooseUpload}
        />
        <Loader loading={props.loading} />
      </div>
  )
}

export { ImageUpload };