import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const TermOfUse = () => {
    return (
        <Container className='text-left main-container' >
            <h2>Terms of Use</h2>
            <br />
            <h6>Effective on July 21, 2020.</h6>
            <br />
            <p><b>This User Agreement and your conduct make that possible.</b></p>
            <p>
                This MaaSTransitAdvisor (<b>MTA</b>) Site User Agreement (the "<b>Terms</b>") applies to your access to and use of the Site and other online products and <b>Services</b> (collectively, the “Services”) provided by Atur Trafik Sdn Bhd, a company registered in Malaysia, with the company number 200801032791 and its operating address at Unit 3, Level 3, Bangunan Komersial Kejiranan NC2, Jalan P16/1, Presint 16, 62150 Putrajaya, Malaysia (“<b>MTA</b>”, or “we,” or “us” or “our”).
            </p>
            <p>
                The <b>Services</b> may contain information, text, links, graphics, photos, videos, or other materials (“Content”). Remember <b>MTA</b> is for collecting feedback, but we still need some basic rules. By accessing or using the <b>Services</b>  you agree to be bound by the <b>Terms</b>. If you do not agree to the <b>Terms</b>, you may not access or use the <b>Services</b> 
            </p>
            <p>
                Please take a look at MTA’s <Link to="/privacy-cookies" >Privacy Policy</Link> too — it explains how we collect, use, and share information about you when you access or use the <b>Services</b> or <b>Content</b>.
            </p>
            <br />
            <ol>
                <h3><li>Your Access to the Services</li></h3>
                <div>
                    <p>Only persons above the age of 18 shall be allowed to create an account or otherwise use the <b>Services</b>  </p>
                    <p>In addition, please read all notices and any <b>Additional Terms</b> carefully when you access the <b>Services</b> </p>
                    <p>If you are accepting the <b>Terms</b> on behalf of another legal entity, including a business or a government, you represent that you have full legal authority to bind such entity to the <b>Terms</b>.</p>
                </div>
                <br />

                <h3><li>Your Use of the Services</li></h3>
                <div>
                    <p><b>MTA</b> grants you a personal, non-transferable, non-exclusive, revocable, limited license to use and access the <b>Services</b> solely as permitted by the <b>Terms</b>. We reserve all rights not expressly granted to you by the <b>Terms</b>.</p>
                    <p>Except as permitted through the <b>Services</b> or as otherwise permitted by us in writing, your license does not include the right to:</p>
                    <ul>
                        <li>license, sell, transfer, assign, distribute, host, or otherwise commercially exploit the <b>Services</b> or <b>Content</b>;</li>
                        <li>modify, prepare derivative works of, disassemble, decompile, or reverse engineer any part of the <b>Services</b> or <b>Content</b>; or</li>
                        <li>access the <b>Services</b> or <b>Content</b> in order to build a similar or competitive website, product, or service.</li>
                    </ul>
                    <br />
                    <p>We reserve the right to modify, suspend, or discontinue the <b>Services</b> (in whole or in part) at any time, with or without notice to you. Any future release, update, or other addition to functionality of the <b>Services</b> will be subject to the <b>Terms</b>, which may be updated from time to time. You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuation of the <b>Services</b> or any part thereof.</p>
                </div>
                <br />

                <h3><li>Your Account and Account Security</li></h3>
                <div>
                    <p>To use certain features of the <b>Services</b>  you may be required to create a MaaSTransitAdvisor (“<b>MTA</b>”) account (“<b>Your Account</b>”) and provide us with a username, password, and certain other information about yourself as set forth in the <Link to='/privacy-cookies'>Privacy Policy</Link>.</p>
                    <p>By accepting the <b>Terms</b>, you confirm that you have reviewed and understand our <Link to='/privacy-cookies'>Privacy Policy</Link>, and you acknowledge that any information shared by, or collected from or about, you may be used by <b>MTA</b> in accordance with our <Link to='/privacy-cookies'>Privacy Policy</Link>, as it is updated or changed from time to time.</p>
                    <p>You are solely responsible for the information associated with <b>Your Content</b> and anything that happens related to Your Account. You must maintain the security of <b>Your Content</b> and promptly notify us if you discover or suspect that someone has accessed <b>Your Content</b> without your permission. We recommend that you use a strong password that is used only with the <b>Services</b> </p>
                    <p>You will not license, sell, or transfer <b>Your Content</b> without our prior written approval.</p>
                    <p>If you wish to modify or terminate your user name, password and/or any other additional information you may do so by contacting us at: <a href="mailto:info@maastransitadvisor.com">info@maastransitadvisor.com</a>. <b>Your Content</b> will be terminated within a reasonable timeframe following your request.</p>
                </div>
                <br />

                <h3><li>Your Content</li></h3>
                <div>
                    <p><b>MTA</b> platform allows you to upload, post and edit reviews, publish and make available through it, data including Public Transit Information (“<b>Your Content</b>”). Please be sure that while you use the <b>MTA</b> Site you respect the proprietary rights including any intellectual property and privacy rights of third parties who have any rights with respect to <b>Your Content</b> you uploaded to the <b>MTA</b> Site. <b>MTA</b> will not bear any liability for any loss, damage, cost or expense that you may suffer or incur as a result of or in connection with uploading <b>Your Content</b>.</p>
                    <p>You hereby further acknowledge and agree that <b>Your Content</b> is non-confidential. You understand and agree that you are solely responsible for <b>Your Content</b> and the consequences of posting or publishing <b>Your Content</b>, on the <b>MTA</b> Site, in any way. You hereby warrant that <b>Your Content</b> is true, current, accurate and complete.</p>
                    <p>IT IS YOUR RESPONSIBILITY TO OBTAIN ANY AND ALL CONSENTS REQUIRED UNDER ANY APPLICABLE LAWS, REGARDING THE POSTING OF ANY INFORMATION INCLUDING PERSONAL INFORMATION OF OTHERS WHICH IS PART OF YOUR CONTENT, AND TO ADHERE TO ANY APPLICABLE LAWS OF MALAYSIA REGARDING SUCH INFORMATION.</p>
                    <p>You agree and acknowledge that to the extent required you have (and will continue to have) all the necessary rights consents, and permissions needed to use <b>Your Content</b> pursuant to the <b>Terms</b>, and that <b>Your Content</b> does not infringe any third party's intellectual property rights or other rights (including without limitation, any privacy rights, publicity rights, copyrights, or any other intellectual property rights).</p>
                    <p>You expressly agree that <b>Your Content</b> will not include any unsolicited promotions, advertising, contests or raffles. You agree that you will not post or upload <b>Your Content</b> containing <b>Content</b> which is unlawful for you to possess, post or upload in the country in which you are resident, or which it would be unlawful for us to use or possess in connection with the Service (including but not limited to any <b>Content</b> which is defamatory, libelous, pornographic, indecent, harassing, threatening, abusive or fraudulent).</p>
                    <p>Although we have no obligation to screen, edit or monitor any of the <b>Your Content</b>, we explicitly reserve the right, at our sole discretion, to remove or edit, without giving any prior notice, <b>Your Content</b> available on the <b>MTA</b> Site at any time and for any reason, and you are solely responsible for creating backup copies of and replacing <b>Your Content</b> you post or store on the <b>MTA</b> Site at your sole expense. </p>
                    <p>When you upload, post, edit, publish or make available <b>Your Content</b> (such as, reviews) on the <b>MTA</b> Site, you hereby irrevocably furnish and grant to <b>MTA</b> the full rights in and to <b>Your Content</b> and any part thereof. To the extent that <b>Your Content</b> you provide via the <b>MTA</b> Site is subject to the applicable copyright law or other intellectual property right, <b>Your Content</b> shall remain at all times, and to the extent permitted by law, <b>MTA</b>'s sole and exclusive property. <b>MTA</b> may publish and use any part of <b>Your Content</b> without your prior consent including without limitation reproduce, distribute, transmit, create derivative works of, display, copy, make available to the public, in any medium of form whatsoever. You hereby agree and understand that you will not be entitled to prevent any transfer of <b>Your Content</b> or any part or derivative thereof to any third party (and to the extent required by law agree to such transfer including cross-border and to any third party). <b>MTA</b> shall not bear any liability for any use by any third party of <b>Your Content</b>. In addition, you hereby explicitly waive any moral right (including without limitation a right of attribution of <b>Your Content</b>, a right to prevent distortion or other change in Your Content) and any equivalent right you may have in and to <b>Your Content</b> and forever waive and agree not to claim or assert any entitlement to any and all moral rights in or in connection with any part of <b>Your Content</b>.</p>
                    <p>Any ideas, suggestions, and feedback about the  <b>MTA</b> Site or the <b>Services</b> that you provide to us are entirely voluntary, and you agree that <b>MTA</b> may use such ideas, suggestions, and feedback without compensation or obligation to you.</p>
                </div>
                <br />

                <h3><li>Third-Party Content, Advertisements and Promotions</li></h3>
                <div>
                    <p>The <b>Services</b> may contain links to third-party websites, products, or services, which may be posted by advertisers, our affiliates, our partners, or other users (“Third-Party Content”). Third-Party <b>Content</b> is not under our control, and we are not responsible for any of their websites, products, or services. Your use of Third-Party <b>Content</b> is at your own risk and you should make any investigation you feel necessary before proceeding with any transaction in connection with such Third-Party <b>Content</b>.</p>
                    <p>The <b>Services</b> may also contain sponsored Third-Party <b>Content</b> or advertisements. The type, degree, and targeting of advertisements are subject to change, and you acknowledge and agree that we may place advertisements in connection with the display of any <b>Content</b> or information on the <b>Services</b>  including the <b>Your Content</b> uploaded through Your Account.</p>
                </div>
                <br />

                <h3><li>Things You Cannot Do</li></h3>
                <div>
                    <p>When accessing or using the <b>Services</b>  you must respect others and their rights, including by following the <b>Terms</b> and the <Link to='/content-policy' >Content Policy</Link>, so that we all may continue to use and enjoy the <b>Services</b>  We support the responsible reporting of security vulnerabilities. To report a security issue, please send an email to <a href="mailto:info@maastransitadvisor.com">info@maastransitadvisor.com</a>.</p>
                    <p>When accessing or using the <b>Services</b>  you will not:</p>
                    <ul>
                        <li>Create or submit <b>Content</b> that violates our <Link to='/content-policy' >Content Policy</Link> or attempt to circumvent any Content-filtering techniques we use;</li>
                        <li>Use the <b>Services</b> to violate applicable law or infringe any person or entity's intellectual property or any other proprietary rights;</li>
                        <li>Attempt to gain unauthorized access to another user’s Account or to the <b>Services</b> (or to other computer systems or networks connected to or used together with the Services);</li>
                        <li>Upload, transmit, or distribute to or through the <b>Services</b> any computer viruses, worms, or other software intended to interfere with the intended operation of a computer system or data;</li>
                        <li>Use the <b>Services</b> to harvest, collect, gather or assemble information or data regarding the <b>Services</b> or users of the <b>Services</b> except as permitted in the <b>Terms</b> or in a separate agreement with <b>MTA</b> platform operators;</li>
                        <li>Use the <b>Services</b> in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the <b>Services</b> or that could damage, disable, overburden, or impair the functioning of the <b>Services</b> in any manner;</li>
                        <li>Intentionally negate any user's actions to delete or edit their <b>Content</b> on the Services; or</li>
                        <li>Access, query, or search the <b>Services</b> with any automated system, other than through our published interfaces and pursuant to their applicable <b>Terms</b>. However, we conditionally grant permission to crawl the <b>Services</b> for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials subject to the parameters set forth in our robots.txt file.</li>
                    </ul>
                    <br />
                </div>
                <br />

                <h3><li>Copyright & Takedowns</li></h3>
                <div>
                    <p><b>MTA</b> respects the intellectual property of others and requires that users of the <b>Services</b> do the same. We have a policy that includes the removal of any infringing materials from the <b>Services</b> and for the termination, in appropriate circumstances, of users of the <b>Services</b> who are repeat infringers. If you believe that anything on the <b>Services</b> infringes a copyright that you own or control, you may notify <b>MTA</b> by contacting us at: <a href="mailto:info@maastransitadvisor.com">info@maastransitadvisor.com</a>.</p>
                    <p>Also, please note that if you knowingly misrepresent that any activity or material on the <b>Services</b> is infringing, you may be liable to <b>MTA</b> for costs and damages.</p>
                    <p>If we remove <b>Your Content</b> in response to a copyright or trademark notice, we will notify you via email. If you believe <b>Your Content</b> was wrongly removed due to a mistake or misidentification, you can send a counter notification to us at: <a href="mailto:info@maastransitadvisor.com">info@maastransitadvisor.com</a>.</p>
                    <p>You must not use or re-purpose our logo or <b>Content</b>. You must not copy or use <b>Content</b> from the <b>MTA</b> site without our express permission.</p>
                </div>
                <br />

                {/* <h3><li>User Generated Content</li></h3>
                <div>
                    <p><b>MTA</b> platform allows you to upload, post and edit reviews, publish and make available through it, data including Public Transit Information (the "User Generated Content"). Please be sure that while you use the Site you respect the proprietary rights including any intellectual property and privacy rights of third parties who have any rights with respect to the User Generated <b>Content</b> you uploaded to the Site. <b>MTA</b> will not bear any liability for any loss, damage, cost or expense that you may suffer or incur as a result of or in connection with uploading any User Generated <b>Content</b>.</p>
                    <p>You hereby further acknowledge and agree that the User Generated <b>Content</b> is non-confidential. You understand and agree that you are solely responsible for your User Generated <b>Content</b> and the consequences of posting or publishing such User Generated <b>Content</b>, on the <b>MTA</b> Site, in any way. You hereby warrant that your User Generated <b>Content</b> is true, current, accurate and complete.</p>
                    <p>IT IS THE USER'S RESPONSIBILITY TO OBTAIN ANY AND ALL CONSENTS REQUIRED UNDER ANY APPLICABLE LAWS, REGARDING THE POSTING OF ANY INFORMATION INCLUDING PERSONAL INFORMATION OF OTHERS WHICH IS PART OF THE USER GENERATED <b>Content</b>, AND TO ADHERE TO ANY APPLICABLE STATE AND FEDERAL LAWS REGARDING SUCH INFORMATION.</p>
                    <p>You agree and acknowledge that to the extent required you have (and will continue to have) all the necessary rights consents, and permissions needed to use the User <b>Content</b> pursuant to these Site's <b>Terms</b>, and that such User <b>Content</b> does not infringe any third party's intellectual property rights or other rights (including without limitation, any privacy rights, publicity rights, copyrights, or any other intellectual property rights.</p>
                    <p>You expressly agree that the User Generated <b>Content</b> will not include any unsolicited promotions, advertising, contests or raffles. You agree that you will not post or upload any User Generated <b>Content</b> containing <b>Content</b> which is unlawful for you to possess, post or upload in the country in which you are resident, or which it would be unlawful for the Company to use or possess in connection with the Service (including but not limited to any <b>Content</b> which is defamatory, libelous, pornographic, indecent, harassing, threatening, abusive or fraudulent).</p>
                    <p>Although the Company has no obligation to screen, edit or monitor any of the User Generated <b>Content</b>, the Company explicitly reserves the right, at its sole discretion, to remove or edit, without giving any prior notice, any User Generated <b>Content</b> available on the <b>MTA</b> Site at any time and for any reason, and you are solely responsible for creating backup copies of and replacing any User Generated <b>Content</b> you post or store on the Site at your sole expense.</p>
                    <p>When you upload, post, edit, publish or make available any User Generated <b>Content</b> (such as, reviews) on the Site, you hereby irrevocably furnish and grant to <b>MTA</b> platform operators the full rights in and to the User Generated Data and any part thereof. To the extent that the User Generated <b>Content</b> you provide via this Site is subject to the applicable copyright law or other intellectual property right, such User Generated <b>Content</b> shall remain at all times, and to the extent permitted by law, <b>MTA</b>'s sole and exclusive property. <b>MTA</b> may publish and use any User Generated <b>Content</b> without the User's prior consent including without limitation reproduce, distribute, transmit, create derivative works of, display, copy, make available to the public, in any medium of form whatsoever. You hereby agree and understand that you will not be entitled to prevent any transfer of User Generated <b>Content</b> or any part or derivative thereof to any third party (and to the extent required by law agree to such transfer including cross-border and to any third party). <b>MTA</b> shall not bear any liability for any use by any third party of the User Generated <b>Content</b>. In addition, you hereby explicitly waive any moral right (including without limitation a right of attribution of the User Generated <b>Content</b>, a right to prevent distortion or other change in the User Generated Content) and any equivalent right you may have in and to the User Generated <b>Content</b> and forever waive and agree not to claim or assert any entitlement to any and all moral rights in or in connection with any of the User Generated <b>Content</b>.</p>
                </div>
                <br /> */}

                <h3><li>Indemnity</li></h3>
                <div>
                    <p>You shall agree to defend, indemnify, and hold us, our licensors, our third party service providers and our officers, employees, licensors, and agents harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the <b>Services</b>  (b) your violation of the <b>Terms</b>, (c) your violation of applicable laws or regulations, or (d) <b>Your Content</b>. We reserve the right to control the defense of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims.</p>
                </div>
                <br />

                <h3><li>Disclaimers</li></h3>
                <div>
                    <p>THE <b>SERVICES</b> ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. <b>MTA</b>, ITS LICENSORS, AND ITS THIRD PARTY SERVICE PROVIDERS DO NOT WARRANT THAT THE <b>SERVICES</b> ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR FREE. <b>MTA</b> DOES NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY CONTENT AVAILABLE ON OR LINKED TO THE <b>SERVICES</b> OR THE ACTIONS OF ANY THIRD PARTY OR USER, INCLUDING MODERATORS. WHILE <b>MTA</b> ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE <b>SERVICES</b> SAFE, WE DO NOT REPRESENT OR WARRANT THAT THE <b>SERVICES</b> OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
                    <p><b>MTA</b> IS INTENDED TO BE A PLATFORM OF SHARED AND COMMON INTEREST.  <b>MTA</b> DOES NOT ENDORSE ANY COMMENTS, OR STATEMENTS EXPRESSED IN THE FORUMS OR ON THE SITE IN ANY WAY. USERS ARE REQUIRED TO FACT CHECK AND PERFORM SELF-CENSORSHIP AND ARE PERSONALLY LIABLE AND RESPONSIBLE TO OTHER PARTIES FOR THEIR STATEMENTS AND MATERIALS POSTED ON THE SITE.</p>
                    <p><b>MTA</b> CANNOT REVIEW OR AUTHENTICATE THE MATERIALS POSTED ON THE SITE AND IS NOT RESPONSIBLE FOR ANY CONTENT POSTED BY USERS.  THE SITE MAY THEREFORE INADVERTENTLY HAVE CONTENT THAT MAY BE OFFENSIVE, INDECENT OR OTHERWISE OBJECTIONABLE TO OTHER USERS, INCLUDING CONTENT THAT ARE FACTUALLY ERRONEOUS OR INFRINGES ON RIGHTS OF OTHER USERS.  <b>MTA</b> DISCLAIMS ANY RESPONSIBILITY, HARM OR DAMAGES RESULTING FROM SUCH CONTENT.</p>
                </div>
                <br />

                <h3><li>Limitation of Liability</li></h3>
                <div>
                    <p><b>MTA</b> ENTITIES SHALL NOT BE LIABLE AT ALL TO YOU FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO THE <b>TERMS</b> OR THE SERVICES, INCLUDING THOSE ARISING FROM OR RELATING TO CONTENT MADE AVAILABLE ON THE <b>SERVICES</b> THAT IS ALLEGED TO BE DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE OF, THE <b>SERVICES</b> IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM. </p>
                </div>
                <br />

                <h3><li>Governing Law and Venue</li></h3>
                <div>
                    <p>We want you to enjoy <b>MTA</b> Site platform, so if you have an issue or dispute, you agree to raise it and try to resolve it with us informally. You can contact us with feedback and concerns here or by emailing us at: <a href="mailto:info@maastransitadvisor.com">info@maastransitadvisor.com</a>.</p>
                    <p>The <b>Terms</b> or the <b>Services</b> shall be governed by the laws of Malaysia. Any dispute related to the <b>Terms</b> or the <b>Services</b> shall be subject to the jurisdiction in the courts of Malaysia.</p>
                </div>
                <br />

                <h3><li>Changes to the Terms</li></h3>
                <div>
                    <p>We may make changes to the <b>Terms</b> from time to time. If we make changes, we will post the amended <b>Terms</b> of Use and update the Effective Date above. If the changes, in our sole discretion, are material, we may also notify you by sending an email to the address associated with <b>Your Content</b> (if you have chosen to provide an email address) or by otherwise providing notice through the <b>Services</b>  By continuing to access or use the <b>Services</b> on or after the Effective Date of the revised <b>Terms</b>, you agree to be bound by the revised <b>Terms</b>. If you do not agree to the revised <b>Terms</b>, you must stop accessing and using the <b>Services</b> before the changes become effective.</p>
                </div>
                <br />

                <h3><li>Additional Terms</li></h3>
                <div>
                    <p>Because we offer a variety of <b>Services</b>  you may be asked to agree to additional <b>Terms</b> before using a specific product or service offered by <b>MTA</b> (“<b>Additional Terms</b>”). To the extent any <b>Additional Terms</b> conflict with the <b>Terms</b>, the <b>Additional Terms</b> govern with respect to your use of the corresponding service.</p>
                </div>
                <br />

                <h3><li>Termination</li></h3>
                <div>
                    <p>You may terminate this <b>Terms</b> of Use at any time and for any reason by deleting <b>Your Content</b> and discontinuing your use of the <b>Services</b>  If you stop using the <b>Services</b> without deactivating Your Account, we may deactivate <b>Your Content</b> due to prolonged inactivity.</p>
                    <p>We may suspend or terminate Your Account, status as a moderator, or ability to access or use the <b>Services</b> at any time for any or no reason, including for a violation of the <b>Terms</b> or our <Link to='/content-policy' >Content Policy</Link>.</p>
                    <p>The following sections will survive any termination of this <b>Terms</b> of Use or of Your Account: </p>
                    <ul>
                        <li>Section 4 (Your Content), </li>
                        <li>Section 6 (Things You Cannot Do),  </li>
                        <li>Section 8 (Indemnity), </li>
                        <li>Section 9 (Disclaimers), </li>
                        <li>Section 10 (Limitation of Liability), </li>
                        <li>Section 11 (Governing Law and Venue), </li>
                        <li>Section 14 (Termination), and </li>
                        <li>Section 15 (Miscellaneous).</li>
                    </ul>
                </div>
                <br />

                <h3><li>Miscellaneous</li></h3>
                <div>
                    <p>The <b>Terms</b> constitute the entire agreement between you and us regarding your access to and use of the <b>Services</b>  Our failure to exercise or enforce any right or provision of the <b>Terms</b> will not operate as a waiver of such right or provision. If any provision of the <b>Terms</b> is, for any reason, held to be illegal, invalid or unenforceable, the rest of the <b>Terms</b> will remain effective. You may not assign or transfer any of your rights or obligations under the <b>Terms</b> without our consent. We may freely assign the <b>Terms</b>.</p>
                </div>
            </ol>
        </Container>
    )
}

export default TermOfUse