import React from 'react'
import './ReviewPhotoview.scss'
import { Image } from 'react-bootstrap'
import PdfIcon from 'assets/images/pdf-icon.png'

const ReviewPhotoview = ({ src }) => {
    if (src && src.type === 'application/pdf') {
        return (
            <div className='image-container-main' >
                <Image className='image-pdf' src={PdfIcon} />
                <a target="_blank" href={src.url}>Click here to preview</a>
            </div>
        )
    }

    return (
        <div className='image-container-main' >
            <Image className='image-image' src={src ? src.url : null || 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/480px-No_image_available.svg.png'} />
        </div>
    )
}

export default ReviewPhotoview