import React, { useState } from 'react'
import { Dialog, IconButton, DialogActions, DialogContent } from '@material-ui/core'
import BackIcon from 'assets/images/verify-failed.png'

import './HomepagePosterDialog.scss'

const HomepagePosterDialog = (props) => {
    const [open, setOpen] = useState(true)

    const onClose = () => {
        setOpen(false)
    }

    return (
        <Dialog open={open} {...props} onClose={onClose} fullWidth >
            <DialogActions >
                <IconButton title='close' className='p-0 m-0' onClick={onClose} >
                    <img width={20} height={20} src={BackIcon} />
                </IconButton>
            </DialogActions>
            <DialogContent>
                <img title={props.description} alt={props.description} className='poster' src={props.url} />
            </DialogContent>
        </Dialog>
    )
}

export { HomepagePosterDialog }