import React, { useState, useEffect } from 'react'
import "./SimpleCollapse.scss";

const SimpleCollapse = (props) => {
  let container = null;
  const [ height, setHeight ] = useState(0);    

  useEffect(() => {       
    setHeight(container.clientHeight + 20);   
  }, [props.open]);
  
  return (
      <div className='simple-collapse'>
        <div           
          className='simple-collapse-content'
          style={{ height: props.open ? height : 0 }}
        >
          <div className="refs" ref={ref => container = ref}>
            {props.children}
          </div>
        </div>
      </div>
  )
}

export { SimpleCollapse };