import { ACTION_TYPES } from 'config';
import storage from 'redux-persist/lib/storage';

export function requestValidateLogin(payload) {
  return {
    type: ACTION_TYPES.VALIDATE_LOGIN_REQUESTED,
    payload
  };
}

export function validateLoginSuccess(payload) {
  return {
    type: ACTION_TYPES.VALIDATE_LOGIN_SUCCEEDED,
    payload
  };
}

export function validateLoginFailure(payload) {
  return {
    type: ACTION_TYPES.VALIDATE_LOGIN_FAILED,
    payload
  };
}

// User Login
export function requestUserLogin(payload) {
  return {
    type: ACTION_TYPES.USER_LOGIN_REQUESTED,
    payload
  };
}

// User Login with Google
export function requestUserLoginWithGoogle(payload) {
  return {
    type: ACTION_TYPES.USER_LOGIN_WITH_GOOGLE_REQUESTED,
    payload
  };
}

// User Login with Facebook
export function requestUserLoginWithFacebook(payload) {
  return {
    type: ACTION_TYPES.USER_LOGIN_WITH_FACEBOOK_REQUESTED,
    payload
  };
}

export function userLoginSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_LOGIN_SUCCEEDED,
    payload
  };
}

export function userLoginFailure(payload) {
  return {
    type: ACTION_TYPES.USER_LOGIN_FAILED,
    payload
  };
}

// User Signup
export function requestUserSignUp(payload) {
  return {
    type: ACTION_TYPES.USER_SIGN_UP_REQUESTED,
    payload
  };
}

export function userSignUpSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_SIGN_UP_SUCCEEDED,
    payload
  };
}

export function userSignUpFailure(payload) {
  return {
    type: ACTION_TYPES.USER_SIGN_UP_FAILED,
    payload
  };
}

// User Logout
export function requestUserLogout(payload) {
  return {
    type: ACTION_TYPES.USER_LOGOUT_REQUESTED,
    payload
  };
}

export function userLogoutSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_LOGOUT_SUCCEEDED,
    payload
  };
}

export function userLogoutFailure(payload) {
  return {
    type: ACTION_TYPES.USER_LOGOUT_FAILED,
    payload
  };
}

export function postOnBoardQuestion(payload) {
  return {
    type: ACTION_TYPES.USER_ANSWER_ONBOARDING,
    payload
  };
}

export function updateProfilePhoto(payload) {
  return {
    type: ACTION_TYPES.USER_UPDATE_PROFILE_PHOTO,
    payload
  };
}

export function updateProfile(payload) {
  return {
    type: ACTION_TYPES.USER_UPDATE_PROFILE,
    payload
  };
}