const { NODE_ENV } = process.env;
export const API_URL = NODE_ENV === 'production' ? 'https://api.maastransitadvisor.com' : 'http://localhost:5000';

export const API_END_POINTS = {
  VALIDATE_LOGIN: `${API_URL}/auth/validate`,
  USER_LOGIN: `${API_URL}/auth/signin`,
  USER_LOGIN_WITH_GOOGLE: `${API_URL}/auth/google/verification`,
  USER_LOGIN_WITH_FACEBOOK: `${API_URL}/auth/facebook/verification`,

  USER_SIGN_UP: `${API_URL}/auth/signup`,
  VERIFY_EMAIL: `${API_URL}/auth/verify-email`,
  CHECK_USERNAME: `${API_URL}/auth/check-username`,
  FORGOT_PASSWORD: `${API_URL}/auth/reset-password`,
  UPDATE_PASSWORD: `${API_URL}/auth/update-password`,

  REVIEW_POST: `${API_URL}/review`,
  REVIEW_GET: `${API_URL}/review`,
  REVIEW_ITEMS: `${API_URL}/review/items`,
  REVIEW_GET_MAPVIEW: `${API_URL}/review/mapView`,
  REVIEW_GET_DETAILS: `${API_URL}/review-details`, // /:reviewId
  REVIEW_DELETE: `${API_URL}/review`, // /:reviewId
  REVIEW_REPORT: `${API_URL}/report-abuse`, //v2
  REVIEW_COMMENT: `${API_URL}/comment-review`, // /:reviewId
  REVIEW_COMMENT_DELETE: `${API_URL}/comment`, // /:commentId
  REVIEW_UPVOTE: `${API_URL}/upvote-review`, // /:reviewId
  REVIEW_DOWNVOTE: `${API_URL}/downvote-review`, // /:reviewId
  REVIEW_LIKE_DELETE: `${API_URL}/like/:likeId`,
  COMMENT_REPLY: `${API_URL}/reply-comment`, // /:commentId
  COMMENT_REPLY_DELETE: `${API_URL}/reply`, // /:replyId
  COMMENT_REPLY_UPVOTE: `${API_URL}/upvote-reply`, // /:replyId
  COMMENT_REPLY_DOWNVOTE: `${API_URL}/downvote-reply`, // /:replyId
  COMMENT_UPVOTE: `${API_URL}/upvote-comment`, // /:commentId
  COMMENT_DOWNVOTE: `${API_URL}/downvote-comment`, // /:commentId
  FILE_UPLOAD: `${API_URL}/review/upload`,

  UPDATE_PROFILE_PHOTO: `${API_URL}/users/avatar`,
  UPDATE_PROFILE: `${API_URL}/users`,

  ANSWER_ONBOARD_QUESTION: `${API_URL}/answer-onboard-question`,

  VOTE_POLL: `${API_URL}/maaspoll`,
  RESULT_POLL: `${API_URL}/maaspoll/get-result`,

  REVIEW_SEARCH: `${API_URL}/review/search`,

  SEND_CONTACT_US: `${API_URL}/contact`,

  FETCH_TRENDING: `${API_URL}/trending-review`,
  FETCH_PUBLIC_PROFILE: `${API_URL}/public-profile`, // /:userid

  FETCH_POSTER_DIALOG: `${API_URL}/poster-dialog`,

  //admin apis
  CHECK_IS_ADMIN: `${API_URL}/admin/check`,
  GET_ALL_USER: `${API_URL}/admin/get-all-user`,
  REMOVE_ADMIN_PRIVILEGE: `${API_URL}/admin/remove-admin`,
  GIVE_ADMIN_PRIVILEGE: `${API_URL}/admin/add-admin`,
  GET_REPORT_OVERVIEW: `${API_URL}/admin/report-overview`,
  DOWNLOAD_FULL_REPORT: `${API_URL}/admin/download-full-report`
};