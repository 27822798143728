import React, { useState } from 'react'
import { Nav } from 'react-bootstrap'
import NavBarIcon from './NavBarIcon'
import Notification from 'assets/images/icons-notification.png';
import { Link } from 'react-router-dom';
import { Badge, Typography, Menu, MenuItem } from '@material-ui/core';
import './NotificationIcon.scss'

const NotificationIconPublic = () => {
    const [anchorEl, setAnchorEl] = useState()

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Nav.Link onClick={(e) => setAnchorEl(e.currentTarget)}  >
                {/* <Badge badgeContent="1" color="secondary" overlap='circle' > */}
                    <NavBarIcon icon={Notification} label={"Notification"} />
                {/* </Badge> */}
            </Nav.Link>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                elevation={1}
                className='mt-5'
            >
                <div style={{ marginRight: 15 }} className='d-flex justify-content-end' >
                    <Typography style={{ margin: 5 }} variant='caption' >Notification</Typography>
                </div>
                <MenuItem className='d-flex justify-content-center flex-column' >
                    Join the community to add reviews, get updates, and read news
                    <Link to='/signup' >Join the community</Link>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default NotificationIconPublic