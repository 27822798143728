import React, { useEffect, useState } from 'react'
import './ProfilePage.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Typography, Card, CardContent, Button, Avatar, Tooltip } from '@material-ui/core'
import { FeedCard, SkeletonFeedCard, Commenting, Loader } from 'components'
import { Link } from 'react-router-dom'
import CreateReviewIcon from 'assets/images/create_review.png'
import { getReview, getReviewDetails } from 'redux/api'
import { checkIsAdmin } from 'utils'

const ProfilePage = ({ user }) => {
    const [myReview, setMyReview] = useState(null)
    const [openComment, setOpenComment] = useState(false)
    const [commentDetails, setCommentDetails] = useState(undefined)
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        handleFetchMyReview()
        checkIsAdmin()
            .then(res => {
                if (res) return setIsAdmin(res)
                return setIsAdmin(false)
                // return window.location = './profile'
            })
    }, [])

    const fetchReviewDetails = async (_id) => {
        try {
            const res = await getReviewDetails(_id)
            setCommentDetails(res.data)
        } catch (e) {
            console.log('error on get review detail', e)
        }
    }

    const handleFetchMyReview = async () => {
        const query = {
            filter: { user: user._id },
            options: { sort: { 'createdAt': -1 } }
        }
        try {
            const res = await getReview(query)
            setMyReview(res.data)
        } catch (e) {
            console.log('error with fetching reviews', e)
        }
    }

    const handleOpenComment = (_id) => {
        setOpenComment(true)
        fetchReviewDetails(_id)
    }

    const handleCloseComment = () => {
        setOpenComment(false)
        setCommentDetails(undefined)
    }

    const handleRefresh = (_id) => {
        fetchReviewDetails(_id)
    }

    return (
        <Container >
            <Loader loading={user.loading} />
            <Commenting refresh={handleRefresh} data={commentDetails} onClose={handleCloseComment} open={openComment} />
            <Card elevation={0} className='card-profile' >
                <CardContent>
                    <Row>
                        <Col className='d-flex justify-content-center align-items-center' sm={2} xs={12} >
                            <Avatar id='avatar' src={user.avatar ? user.avatar : ''} >
                                {user.avatar ? '' : String(user.name || 'test-bot').charAt(0).toUpperCase()} {/* check whether got avatar, if none then use initial as avatar. test-bot is only for testing purpose  */}
                            </Avatar>
                        </Col>
                        <Col className='text-sm-left text-xs-center' sm={10} xs={12}>
                            <div className='p-2' >
                                <Typography variant='h4' className='font-weight-bold' >{user.name || 'test-bot'} </Typography>
                                <Typography variant='h5' >{user.username || 'test-bot'}</Typography>
                                {
                                    isAdmin &&
                                    <Tooltip title='You are an Admin' arrow placement='right' >
                                        <small><Link to='/admin-dashboard' className='text-decoration-none btn-primary p-1 rounded m-1' >Administrator</Link></small>
                                    </Tooltip>
                                }
                                {
                                    user.emailVerified ?
                                        <Typography className='bg-success text-light p-1 rounded m-1' variant='caption' >Verified User</Typography>
                                        : <Typography className='bg-warning text-light p-1 font-weight-bold' variant='subtitle2' >Not Verified : Please check <text className='text-dark' >{user.email}</text> inbox for verification link</Typography>
                                }
                            </div>
                            {
                                user.about &&
                                <Typography className='font-weight-bold p-2' >About Me <p className='font-weight-normal' >{user.about ? user.about : ''}</p></Typography>
                            }
                            <div className='d-flex justify-content-sm-start justify-content-center' >
                                <Typography className='font-weight-bold p-2' >Review <p className='font-weight-normal' >{myReview ? myReview.length : 0}</p></Typography>
                            </div>
                        </Col>
                        <Col>
                            <Button onClick={() => window.location = '/setting'} fullWidth color='primary' variant='text' >Settings</Button>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
            <Card elevation={0} className='body-card' >
                <CardContent style={{ width: 'inherit' }} >
                    <Row className='mb-4 d-flex justify-content-center align-items-center' >
                        <Col sm={6} xs={12} className='text-sm-left text-xs-center' >
                            <Typography variant='h5' className='font-weight-bold' >My Review</Typography>
                        </Col>
                        <Col sm={6} xs={12} className='text-sm-right text-xs-center' >
                            <Button >
                                <img src={CreateReviewIcon} width={30} height={30} />
                                <Link to='/review' style={{ paddingLeft: 10 }} >Create A Review</Link>
                            </Button>
                        </Col>
                    </Row>
                    <div>
                        {
                            myReview ?
                                <Row className="justify-content-start" >
                                    {
                                        myReview.length > 0 ?
                                            myReview.map((data, key) => <FeedCard onClick={() => handleOpenComment(data._id)} data={data} key={key} xs='12' md='6' lg='4' />)
                                            :
                                            <div className='text-center h-100 w-100' >
                                                <Typography variant='subtitle1' >You have no review yet</Typography>
                                                <Typography variant='caption' >Have your voice heard now!</Typography>
                                            </div>
                                    }
                                </Row>
                                :
                                <Row >
                                    {
                                        [1, 2, 3].map((x, key) => <SkeletonFeedCard key={key} />)
                                    }
                                </Row>
                        }
                    </div>
                </CardContent>
            </Card>
        </Container>
    )
}

export default ProfilePage