import { ACTION_TYPES } from "config/action-types";

export const requestReviewPost = payload => ({
    type: ACTION_TYPES.REVIEW_POST_REQUEST,
    payload
})

export const reviewPostSuccess = payload => ({
    type: ACTION_TYPES.REVIEW_POST_SUCCEEDED,
    payload
})

export const reviewPostFailure = error => ({
    type: ACTION_TYPES.REVIEW_POST_FAILED,
    payload: {
        error
    }
})

// export const reviewPostDone = () => ({
//     type: ACTION_TYPES.REVIEW_POST_DONE,
// })

export const requestReviewGet = payload => ({
    type: ACTION_TYPES.REVIEW_GET_REQUEST,
    payload
})

export const reviewGetSuccess = payload => ({
    type: ACTION_TYPES.REVIEW_GET_SUCCEEDED,
    payload
})

export const reviewGetFailure = () => ({
    type: ACTION_TYPES.REVIEW_GET_FAILED
})

export const requestUploadFile = payload => ({
    type: ACTION_TYPES.FILE_UPLOAD_REQUEST,
    payload
})

export const uploadFileSuccess = payload => ({
    type: ACTION_TYPES.FILE_UPLOAD_SUCCEEDED,
    payload
})

export const uploadFileFailure = () => ({
    type: ACTION_TYPES.FILE_UPLOAD_FAILED
})

export const requestReviewItems = payload => ({
    type: ACTION_TYPES.REVIEW_ITEMS_REQUESTED,
    payload
})

export const reviewItemsSuccess = payload => ({
    type: ACTION_TYPES.REVIEW_ITEMS_SUCCEEDED,
    payload
})

export const reviewItemsFailure = () => ({
    type: ACTION_TYPES.REVIEW_ITEMS_FAILED
})