import React, { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import { SimpleCollapse, Loader } from 'components';
import EventBus from 'eventing-bus'
import { ACTION_TYPES } from "config/action-types"
import { Typography, Dialog, DialogContent } from '@material-ui/core'
import { Link } from "react-router-dom";
import './ReviewPage.scss'

import Feeling from './common/Feeling';
import Service from './common/Service';
import Aspect from './common/Aspect';
import TellUsMore from './common/TellUsMore';

import SubmittedIcon from 'assets/images/submitted.png'

const ReviewSubmittedCard = ({ ticketNumber }) => (
    <Dialog fullWidth open className='review-submitted-dialog'>
        <DialogContent className='review-submitted-card'>
            <img width={30} height={30} src={SubmittedIcon} />
            <Typography variant='h5' style={{fontWeight:'bold', margin:'10px 0px 10px 0px'}} color='primary' >Review Submitted</Typography>  
            <p>TICKET NUMBER: #{ticketNumber}</p>         
            <p className='description'>Thank you for completing the review of this submission.<br/>It helps us get better.</p>
            <Link className='btn btn-primary btn-ok' to="/">Ok</Link>
        </DialogContent>
    </Dialog>
)

const ReviewPage = (props) =>{    
    const [ ticketNumber, setTicketNumber ] = useState('');
    const [ feeling, setFeeling ] = useState('');
    const [ service, setService ] = useState('');
    const [ aspect, setAspect ] = useState('');
    const [ serviceText, setServiceText ] = useState('');
    const [ aspectText, setAspectText ] = useState('');
    const [ section, setSection ] = useState('');
    const [ incidentDateTime, setIncidentDate ] = useState(new Date().toISOString());
    const [ body, setBody ] = useState({});
    const [ attachments, setAttachments ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ reviewItemRequested, setReviewItemRequested ] = useState(false);    
    const [ showSubmitReview, setShowSubmitReview ] = useState(false)

    useEffect(() => {
        if (!reviewItemRequested) {
            setReviewItemRequested(true);
            props.requestReviewItems();
        }

        const subscribeReview = EventBus.on(ACTION_TYPES.REVIEW_POST_REQUEST, () => setLoading(true));
        const subscribeReviewSuccess = EventBus.on(ACTION_TYPES.REVIEW_POST_SUCCEEDED, onReviewSubmitted);
        const subscribeReviewFailure = EventBus.on(ACTION_TYPES.REVIEW_POST_FAILED, () => setLoading(false));

        return () => {
            subscribeReview();
            subscribeReviewSuccess();
            subscribeReviewFailure();
        };
    });

    const onHandleFileUpload = (formData) => {        
        props.requestUploadFile(formData)
    }

    const scrollToTop = (duration) => {
        // cancel if already on top
        if (document.scrollingElement.scrollTop === 0) return;
    
        const totalScrollDistance = document.scrollingElement.scrollTop;
        let scrollY = totalScrollDistance, oldTimestamp = null;
    
        function step (newTimestamp) {
            if (oldTimestamp !== null) {
                // if duration is 0 scrollY will be -Infinity
                scrollY -= totalScrollDistance * (newTimestamp - oldTimestamp) / duration;
                if (scrollY <= 0) return document.scrollingElement.scrollTop = 0;
                document.scrollingElement.scrollTop = scrollY;
            }
            oldTimestamp = newTimestamp;
            window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
    }

    const onServiceChange = ({ id, title }) => {
        setService(id);
        setServiceText(title);
    }

    const onAspectChange = ({ id, title }) => {
        setAspect(id);
        setAspectText(title);
    }

    const onTellUsMoreChange = (fieldName, value) => {
        if (fieldName === 'incidentDate') {
            setIncidentDate(value);
        } else if (fieldName === 'attachments') {
            setAttachments(value);
        } else  {
            const updatedBody = body;
            updatedBody[fieldName] = value;
            setBody(updatedBody);
        }        
    }
    
    const onSubmitForm = () => {
        scrollToTop(500);
        
        const reviewObj = {
            feeling, service, aspect, serviceText, aspectText, section, incidentDateTime, attachments, body
        };

        // console.log('review', reviewObj)

        props.requestReviewPost(reviewObj);        
    }

    const onReviewSubmitted = (response) => {
        setTicketNumber(response.data.ticketNumber);
        setLoading(false);
        setFeeling('');
        setService('');        
        setAspect('');
        setServiceText('');
        setAspectText('');
        // setSection('');
        setIncidentDate(new Date().toISOString());
        setBody({});
        setAttachments([]);
        setShowSubmitReview(true);
    }
    
    return(
        <Container className='review-page' >
            <div className='review-container'>
                <div className='review-wrapper'>
                    <SimpleCollapse open>
                        {
                            /*!props.user.emailVerified &&
                            <Typography variant='subtitle2' className='bg-warning text-light p-2 m-1 font-weight-bold' >
                                Uh-oh! You need to verify your account first in order to post Review
                            </Typography>*/
                        }
                        <Feeling 
                            data={props.reviewItems}
                            value={feeling} 
                            onChange={setFeeling} 
                        />
                    </SimpleCollapse>
                    <SimpleCollapse open={feeling}>
                    {/*<SimpleCollapse open={feeling && props.user.emailVerified}>*/}
                        <Service 
                            data={props.reviewItems}
                            value={service}
                            onChange={onServiceChange}
                            onChangeText={setServiceText}
                        />
                    </SimpleCollapse>
                    <SimpleCollapse open={service}>
                        <Aspect 
                            service={service} //to disable Driver aspect if certain service is selected. refer to https://app.clickup.com/t/3kuazp
                            data={props.reviewItems}
                            value={aspect}
                            onChange={onAspectChange}
                            onChangeText={setAspectText}
                        />
                    </SimpleCollapse>
                    <SimpleCollapse open={aspect}>
                        <TellUsMore 
                            data={props.reviewItems}
                            // section={section}
                            incidentDate={incidentDateTime}
                            attachments={attachments}
                            body={body}
                            // onSectionChange={setSection}
                            onFileUpload={onHandleFileUpload}
                            onChange={onTellUsMoreChange}
                            submitForm={onSubmitForm}
                            enableSubmitButton={aspect? true : false}
                        />
                    </SimpleCollapse>                    
                </div>                
                <Loader loading={loading} />
            </div>   
            {
                showSubmitReview &&
                <ReviewSubmittedCard ticketNumber={ticketNumber} />
            }       
        </Container>
    )
}

export default ReviewPage
