import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import { configureStore } from 'redux/store';
import './App.scss';

import { NavBar, Footer } from 'components';
import HomePage from 'containers/HomePage';
import PopularPage from 'containers/PopularPage';
//import ReviewPage from 'containers/ReviewPage_Old';
import ReviewPage from 'containers/ReviewPage';
import SignInPage from 'containers/SignInPage';
import SignUpPage from 'containers/SignUpPage';
import ForgotPasswordPage from 'containers/ForgotPasswordPage';
import ResetPasswordPage from 'containers/ResetPasswordPage';
import VerifyEmailPage from 'containers/VerifyEmailPage';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { ToastContainer } from 'react-toastify';
import Purple from '@material-ui/core/colors/purple'
import NotificationPage from 'containers/NotificationPage';
import SurveyPage from 'containers/SurveyPage';
import ProfilePage from 'containers/ProfilePage';
import DashBoardPage from 'containers/DashBoardPage';
/*Dashboard page - added by RJ*/

import { NoRouteMatch } from 'components';
import { CreditAttribution, AboutUs, TermOfUse, ContentPolicy, Disclaimer, PrivacyCookies, Faq, ContactUs, MeetTheTeam, SocialLoginRedirect } from 'containers/ContentPages';
import OnBoardQuestionPage from 'containers/OnBoardQuestionPage';
import MaaSPage from 'containers/MaaSPage';
import Axios from 'axios';
import localStorage from 'redux-persist/es/storage';
import ScrollToTop from 'components/ScrollToTop';
import PublicProfilePage from 'containers/PublicProfilePage';
import SettingPage from 'containers/SettingPage';
import AdminPage from 'containers/AdminPage';

const newTheme = createMuiTheme({
  palette: {
    primary: Purple,
    text: {
      primary: '#444444',
      secondary: "#8e24aa"
    }
  }
})

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    global.store.getState().user._id ? <HasAnsweredOnboard component={Component} {...props} /> : <Redirect to='/signin' />
  )} />
)

const HasAnsweredOnboard = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    global.store.getState().user.hasAnsweredOnBoardQuestion ? <Component {...props} /> : <Redirect to='/on-board-question' />
  )} />
)

const saveUserIP = () => {
  const url = "https://freegeoip.app/json/"

  Axios.get(url)
    .then(res => {
      const clientIP = res.data.ip
      localStorage.setItem('clientIP', clientIP)
    })
}

function App() {
  const store = configureStore();
  const persistor = persistStore(store);
  global.store = store;

  useEffect(() => {
    saveUserIP()
  }, [])

  return (
    <MuiThemeProvider theme={newTheme}>
      <div className="App">
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Router>
              <ScrollToTop />
              <Switch>
                <Route path="/signin">
                  <SignInPage />
                </Route>
                <Route path="/signup">
                  <SignUpPage />
                </Route>
                <Route path="/verify-email/:emailCode">
                  <VerifyEmailPage />
                </Route>
                <Route path="/forgot-password">
                  <ForgotPasswordPage />
                </Route>
                <Route path="/reset-password/:code">
                  <ResetPasswordPage />
                </Route>
                <Route path='/social-login-redirect/:provider/:code' component={SocialLoginRedirect} />
                <Route >
                  <NavBar />
                  <Route exact path='/' component={HomePage} />
                  <Route path='/popular' component={PopularPage} />
                  {/*<ProtectedRoute path='/popular' component={PopularPage} />*/}
                  <Route path='/review' component={ReviewPage} />
                  {/*<ProtectedRoute path='/review' component={ReviewPage} />*/}
                  <ProtectedRoute path='/notification' component={NotificationPage} />
                  <ProtectedRoute path='/profile' component={ProfilePage} />
                  <Route path='/survey' component={SurveyPage} />
                  <Route path='/meet-the-team' component={MeetTheTeam} />
                  <Route path='/about-us' component={AboutUs} />
                  <Route path='/credit-attribution' component={CreditAttribution} />
                  <Route path='/term-of-use' component={TermOfUse} />
                  <Route path='/content-policy' component={ContentPolicy} />
                  <Route path='/disclaimer' component={Disclaimer} />
                  <Route path='/privacy-cookies' component={PrivacyCookies} />
                  <Route path='/on-board-question' component={OnBoardQuestionPage} />
                  <Route path='/faq' component={Faq} />
                  <Route path='/maas' component={MaaSPage} />
                  <Route path='/contact-us' component={ContactUs} />
                  <Route path='/public-profile/:userId' component={PublicProfilePage} />
                  <Route path='/setting' component={SettingPage} />
                  <Route path='/admin-dashboard' component={AdminPage} />
                  {/*Dashboard page - added by RJ*/}
                  <Route path ='/dashboard' component={DashBoardPage} />
                  {/* <Route component={NoRouteMatch}/> */}
                  <Footer />
                </Route>
              </Switch>
            </Router>
          </PersistGate>
        </Provider>
        <ToastContainer />
      </div>
    </MuiThemeProvider>
  );
}

export default App;
