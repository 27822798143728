import { connect } from 'react-redux';
import AdvanceSearch from './AdvanceSearch';
import { requestReviewItems } from 'redux/actions';


const mapStateToProps = (state) => {
    return {
        user: state.user,
        reviewItems: state.reviewItems,
    }
}

export default connect(mapStateToProps, {
    requestReviewItems
})(AdvanceSearch);