import { INITIAL_STATE } from '../store';
import { ACTION_TYPES } from 'config';

export const UserReducer = (state = INITIAL_STATE.user, action) => {
  switch (action.type) {
    case ACTION_TYPES.VALIDATE_LOGIN_REQUESTED:
      return {
        ...state,
        error: false,
        success: false,
        loading: true
      };

    case ACTION_TYPES.VALIDATE_LOGIN_SUCCEEDED:
      return {
        ...state,
        error: false,
        success: true,
        loading: false
      };

    case ACTION_TYPES.VALIDATE_LOGIN_FAILED:
      return {
        ...state,
        error: true,
        success: false,
        loading: false
      };

    // user login
    case ACTION_TYPES.USER_LOGIN_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.USER_LOGIN_SUCCEEDED:
      return {
        ...state,
        ...action.payload.data,
        loading: false
      };

    case ACTION_TYPES.USER_LOGIN_FAILED:
      return {
        ...state,
        loading: false
      };

    case ACTION_TYPES.USER_SIGN_UP_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.USER_SIGN_UP_SUCCEEDED:
      return {
        ...state,
        // ...action.payload.data, //user need to login after signup - did not get token if straightaway login (quick fix)
        loading: false
      };

    case ACTION_TYPES.USER_SIGN_UP_FAILED:
      return {
        ...state,
        loading: false
      };

    // Logout
    case ACTION_TYPES.USER_LOGOUT_REQUESTED:
      return {
        ...state
      };

    case ACTION_TYPES.USER_LOGOUT_SUCCEEDED:
      return INITIAL_STATE.user;

    case ACTION_TYPES.USER_LOGOUT_FAILED:
      return {
        ...state
      };

    //answer onboarding question
    case ACTION_TYPES.USER_ANSWER_ONBOARDING:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.USER_UPDATE_PROFILE_PHOTO:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.USER_UPDATE_PROFILE:
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
};
