import { connect } from "react-redux";
import OnBoardQuestionPage from "./OnBoardQuestionPage";
import { postOnBoardQuestion } from 'redux/actions';

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {
    postOnBoardQuestion
})(OnBoardQuestionPage)