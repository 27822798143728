import React, { useState } from 'react'
import './SettingPage.scss'
import { Avatar, Button, Typography, TextField, MenuItem, Card, CardHeader, CardContent, Dialog } from '@material-ui/core'
import { toast } from 'react-toastify'
import { Container, FormLabel } from 'react-bootstrap'
import { isEmail } from 'validator'
import { API_END_POINTS } from 'config'
import Axios from 'axios'

const MyInputText = (props) => (
    <div className='mt-3' >
        <TextField variant='outlined' fullWidth {...props} />
    </div>
)

const ChangePasswordCard = ({ open, onClose }) => {
    const [username, setUsername] = useState(null)

    const handleChangePassword = async (e) => {
        e.preventDefault()
        const data = {};

        if (username) {
            if (isEmail(username)) {
                data.email = username;
            } else {
                data.username = username;
            }

            // console.log('data', data)
            onClose()

            try {
                const response = await Axios({
                    method: 'post',
                    url: API_END_POINTS.FORGOT_PASSWORD,
                    data
                })

                if (response.data.error) {
                    return toast.error(response.data.message);
                }

                toast.success('We have sent you an email containing the link to reset your password')

            } catch (e) {
                console.log('ERROR: ', e.message);
            }
        }
    }

    return (
        <Dialog fullWidth open={open} onClose={onClose} >
            <Card>
                <CardHeader title='Change Password' />
                <CardContent>
                    <form onSubmit={handleChangePassword} >
                        <MyInputText required onInput={(evt) => setUsername(evt.target.value)} label='Username or Email' />
                        <Button className='mt-3' color='primary' disabled={username ? false : true} type='submit' fullWidth variant='outlined' >Change password</Button>
                        <Button onClick={onClose} className='mt-1' fullWidth variant='outlined' >cancel</Button>
                    </form>
                </CardContent>
            </Card>

        </Dialog>
    )
}

const noSpacingValidator = (name) => {
    const regExp = /^\S*$/
    return !(regExp.test(name))
}

export const SettingPage = ({ updateProfile, updateProfilePhoto, user }) => {
    const [showAvatarOverlay, setShowAvatarOverlay] = useState(false)
    const [showAvatarPreview, setShowAvatarPreview] = useState(null)
    const [changedProfile, setChangedProfile] = useState({})
    const [changedProfilePhoto, setChangedProfilePhoto] = useState(null)
    const [editLanguage, setEditLanguage] = useState(null)
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false)

    const handleSubmitChangeProfile = (e) => {
        e.preventDefault()
        if (changedProfilePhoto) {
            const formData = new FormData()
            formData.append('avatar', changedProfilePhoto)
            updateProfilePhoto(formData)
        }

        if (Object.keys(changedProfile).length > 0) {
            // if(changedProfile.username && noSpacingValidator(changedProfile.username)) 
            updateProfile(changedProfile)
        }

        handleStateReset()
    }

    const handleStateReset = () => {
        setShowAvatarOverlay(false)
        setShowAvatarPreview(null)
        setChangedProfile({})
        setChangedProfilePhoto(null)
        setEditLanguage(null)
        setOpenChangePasswordDialog(false)
    }

    const handlePreviewNewAvatar = (e) => {
        const input = e.currentTarget
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.readAsDataURL(input.files[0]) // convert to base64 string 
            reader.onload = function (e) {
                const filesize = ((input.files[0].size / 1024) / 1024).toFixed(4) //in MB
                if (filesize <= 0.5) {
                    setShowAvatarPreview(e.target.result)
                    setChangedProfilePhoto(input.files[0])
                } else {
                    toast.error('Photo size exceeded 500kb size')
                }
            }
        } else {
            setShowAvatarPreview(null)
            setChangedProfilePhoto(null)
        }
    }

    return (
        <Container className='main-container text-left' >
            <ChangePasswordCard open={openChangePasswordDialog} onClose={() => setOpenChangePasswordDialog(false)} />
            <Typography variant='h4' >Settings</Typography>
            <div className='text-center' >
                {
                    showAvatarPreview &&
                    <p onClick={() => setShowAvatarPreview(null)} className='btn text-danger p-0 m-0' >clear</p>
                }
            </div>
            <div className='d-flex justify-content-around align-items-center avatar-container'  >
                <div>
                    {
                        showAvatarPreview ?
                            <Avatar id='avatar' src={showAvatarPreview} />
                            :
                            <Avatar id='avatar' src={user.avatar ? user.avatar : ''} >
                                {user.avatar ? '' : String(user.name || 'test-bot').charAt(0).toUpperCase()} {/* check whether got avatar, if none then use initial as avatar. test-bot is only for testing purpose  */}
                            </Avatar>
                    }
                </div>
                {
                    showAvatarOverlay &&
                    <p className='avatar-overlay' >Change profile photo</p>
                }
                <input onChange={handlePreviewNewAvatar} accept='image/*' onMouseEnter={() => setShowAvatarOverlay(true)} onMouseLeave={() => setShowAvatarOverlay(false)} className='file-upload' type="file" name="avatar" />
            </div>
            <form autoComplete='off' onSubmit={handleSubmitChangeProfile} >
                <div>
                    <FormLabel>Profile</FormLabel>
                    <MyInputText
                        defaultValue={user.name || 'test-bot'}
                        label='Full Name'
                        onInput={(evt) => {
                            const name = evt.target.value
                            setChangedProfile(prev => ({ ...prev, name }))
                        }}
                    />
                    <MyInputText
                        label='Username'
                        defaultValue={user.username || 'test-bot'}
                        onInput={(evt) => {
                            const username = evt.target.value
                            setChangedProfile(prev => ({ ...prev, username }))
                        }}
                    />
                    <MyInputText
                        disabled
                        defaultValue={user.email || 'test-bot@test-bot.com'}
                        label='Email'
                        onInput={(evt) => {
                            const email = evt.target.value
                            setChangedProfile(prev => ({ ...prev, email }))
                        }}
                    />
                    <MyInputText
                        defaultValue={user.phone || null}
                        label='Mobile No.'
                        onInput={(evt) => {
                            const phone = evt.target.value
                            setChangedProfile(prev => ({ ...prev, phone }))
                        }}
                    />
                    <MyInputText
                        value='password' type='password'
                        label='Password'
                        InputProps={{
                            endAdornment: <Button onClick={() => setOpenChangePasswordDialog(true)} variant='outlined' color='primary' >change</Button>
                        }}
                    />
                    <MyInputText
                        defaultValue={user.location || ''}
                        label='Current City'
                        onInput={(evt) => {
                            const location = evt.target.value
                            setChangedProfile(prev => ({ ...prev, location }))
                        }}
                    />
                    <MyInputText
                        defaultValue={user.about || ''}
                        placeholder='A short description about you'
                        label='About You'
                        multiline
                        rows={3}
                        onInput={(evt) => {
                            const about = evt.target.value
                            setChangedProfile(prev => ({ ...prev, about }))
                        }}
                    />
                </div>
                <div className='mt-5' >
                    <FormLabel>Preferences</FormLabel>
                    <MyInputText
                        select
                        value={user.language || editLanguage || 'en'}
                        label='Language'
                        onChange={(evt) => {
                            const language = evt.target.value
                            setChangedProfile(prev => ({ ...prev, language }))
                            setEditLanguage(language)

                        }}
                    >
                        <MenuItem value='en' >en - English</MenuItem>
                        <MenuItem value='ms' >ms - Bahasa Malaysia</MenuItem>
                    </MyInputText>
                    {/* <MyInputText
                        defaultValue={user.time_format || ''}
                        label='Time Format'
                    // onInput={(evt) => {
                    //     const email = evt.target.value
                    //     setChangedProfile(prev => ({ ...prev, email }))
                    // }}
                    /> */}
                </div>
                <div className='d-flex justify-content-center mt-3' >
                    {/* <Button onClick={handleCloseEditProfile} ><Typography className='white-button-cancel font-weight-bold' >cancel</Typography></Button> */}
                    <Button type='submit' ><Typography className='white-button-save font-weight-bold' >save changes</Typography></Button>
                </div>
            </form>
        </Container >
    )
}
