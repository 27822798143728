import React, { Component } from "react";
import { SkeletonFeedCard, TrendingCard, HomepagePosterDialog } from 'components';
import { Typography, Card, CardHeader, CardContent, CardActions, Button as Btn } from "@material-ui/core";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import MoreIcon from 'assets/images/serviceIcons/icons-more.png'
import MapImage from 'assets/images/map-homepage-static.png'
import { Link } from "react-router-dom";

import _ from 'lodash'

import "./HomePage.scss";
import { fetchTrending } from "redux/api";
import { ApiRequest } from "helpers";
import { API_END_POINTS } from "config";

const feedLimit = []
feedLimit.length = 4
feedLimit.fill('')

class HomePage extends Component {

  state = {
    communityFeed: [],
    openMore: false,
    sorting: o => o.slice(0, 5),
    posterDialog: []
  }

  handleFetchFeed = async () => {
    try {
      const trendingRes = await fetchTrending()
      this.setState({ communityFeed: trendingRes.data })
    } catch{ }
  }

  handleFetchPosterDialog = async () => {
    try {
      const posterRes = await ApiRequest.fetch({
        method: 'get',
        url: `${API_END_POINTS.FETCH_POSTER_DIALOG}`
      })
      // console.log('test-poster', posterRes)
      this.setState({ posterDialog: posterRes.data })
    } catch{ }

  }

  componentDidMount() {
    this.handleFetchFeed()
    this.props.requestReviewItems()
    this.handleFetchPosterDialog()
  }

  render() {
    const availableModes = _.filter(this.props.reviewItems, (o) => o.category === 'service')
    if (availableModes.length > 0) {
      availableModes.map((mode) => {
        //name might have / which is bad for path.. so we change it to &.
        //  just remember to save icon png as "icons-taxi-&-cab.png" instead of "icons-taxi-/-cab.png" or "icons-taxi/cab.png"
        const name = String(mode.title).toLowerCase().replace(/ /g, "-").replace(/\//g, "&")
        const iconName = `icons-${name}`
        mode['to'] = `/review/${name}`
        try {
          mode['icon'] = require(`assets/images/serviceIcons/${iconName}.png`)
        } catch{
          mode['icon'] = ''
        }
      })
    }
    return (
      <div className='home-page' >
        {
          this.state.posterDialog.length > 0 &&
          this.state.posterDialog.map(({ poster_url, description }, key) => (
            <HomepagePosterDialog key={key} description={description} url={poster_url} />
          ))
        }
        <Container className='p-5' >
          <Typography variant='h4' className='font-weight-bold pb-3' >Tell us what you think about our public transport services</Typography>
          <Typography variant='h6' >We aim at bringing all voices in one place to enable a safe, accessible, affordable, and efficient public transport. Be part of the solution by leaving a review.</Typography>
          <Typography variant='h4' className='font-weight-bold pt-3' >JOM! MaaS</Typography>
        </Container>
        <Container fluid className="main-banner">
          <Typography variant='h4' color='primary' className='banner-text' style={{ fontWeight: 'bold' }} ><Link className='text-decoration-none banner-link' to='/review' >Have your say</Link></Typography>
        </Container>
        <Container className='middle-container' >
          <Card elevation={0} className='middle-toolbar' >
            <Container>
              <Row className="justify-content-md-center middle-icon">
                {
                  this.props.reviewItems &&
                  this.state.sorting(availableModes).map(({ title, icon, to }, key) => {
                    return (
                      <Col className="p-3" as={Link} to={to} key={key} xs='4' lg="2">
                        <img src={icon} width={50} />
                        <Typography variant='subtitle2' >{title}</Typography>
                      </Col>
                    )
                  })
                }
                {
                  !this.state.openMore &&
                  <Col onClick={() => this.setState({ openMore: true, sorting: o => o })} className='btn' xs='4' lg="2">
                    <img src={MoreIcon} width={50} />
                    <Typography variant='subtitle2' >More</Typography>
                  </Col>
                }
              </Row>
            </Container>
            {
              this.state.openMore &&
              <CardActions><Btn onClick={() => this.setState({ openMore: false, sorting: o => o.slice(0, 5) })} color='primary' variant='outlined' fullWidth >Show Less</Btn></CardActions>
            }
          </Card>
          {
            this.state.communityFeed.length > 0 &&
            <Card elevation={0} className='body-card body-container-home' >
              <CardHeader title='Trending' />
              <CardContent className="trending-container-main" >
                {
                  <div className="trending-container" >
                    {
                      this.state.communityFeed ?
                        this.state.communityFeed.map((data, key) => (
                          <TrendingCard data={data} key={key} />
                        ))
                        :
                        [1, 2, 3].map((x, key) => <SkeletonFeedCard key={key} />)
                    }
                    <div className='load-more' >
                      <Link to='/popular' ><Btn variant='contained' color='primary' fullWidth >More</Btn></Link>
                    </div>
                  </div>
                }
              </CardContent>
            </Card>
          }
          <Card elevation={0} className='body-card body-container-home text-left' >
            <CardHeader title='What is Mobility-as-a-Service (MaaS)?' />
            <CardContent >
              <Typography>
                Mobility-as-a-Service (MaaS) is a relatively new concept that provides a user-focused end-to-end multi-modal transport service through an integrated planning and payment interface. MaaS is gaining momentum in the transport industry with many trials, pilots and commercial deployments in regions and cities around the world including UK, Australia, and many European countries.
                  </Typography>
              <Link to='/maas'><Button className='mt-4 text-white' >Learn More</Button></Link>
            </CardContent>
          </Card>

          <Card elevation={0} className='body-card bg-purple body-container-home text-left' >
            <CardContent >
              <Typography variant='h5' className='font-weight-bold text-light' >Stand a chance to win RM50 Touch ‘n Go card</Typography>
              <Typography variant='h6' className='text-white' >25 lucky winners will be selected during the survey period. Hurry up! T&C apply</Typography>
              <Link to='/survey'><Button className='btn-light mt-4 text-purple' >Go to Survey</Button></Link>
            </CardContent>
          </Card>

          <Card elevation={0} className='body-card bg-light-grey body-container-home text-left' >
            <CardContent>
              <Row>
                <Col className='d-flex flex-column' md={6} sm={12} >
                  <Typography variant='h5' className='font-weight-bold text-dark' >Be part of the solution</Typography>
                  <Typography className='text-dark' >Join the community to voice out your demands, praises, or even ideas, and find them on a map.</Typography>
                  <Link to='/popular'><Button className='btn-dark mt-4 mb-3 text-light-grey' >See popular reviews</Button></Link>
                </Col>
                <Col md={6} sm={12} >
                  <Image width='100%' className='rounded-right' fluid src={MapImage} />
                </Col>
              </Row>
            </CardContent>
          </Card>
        </Container>
      </div >
    );
  }
};

export default HomePage;