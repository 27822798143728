import { connect } from 'react-redux';
import { requestValidateLogin, requestUserLogin } from 'redux/actions';
import SignInPage from './SignInPage';

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, {
  requestValidateLogin,
  requestUserLogin
})(SignInPage);