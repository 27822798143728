import React from 'react'
import { Typography } from '@material-ui/core'

const NavBarIcon = ({ label, icon }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
            <img src={icon} width={30} />
            <Typography variant='subtitle2' >{label}</Typography>
        </div>
    )
}

export default NavBarIcon