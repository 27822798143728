import React from 'react'
import { Container } from 'react-bootstrap'

const ContentPolicy = () => {
    return (
        <Container className='text-left main-container' >
            <h3>MaasTransitAdvisor Content Policy</h3>
            <br />
            <p>MaasTransitAdvisor (<b>MTA</b>) is a vast network of communities that are created, run, and populated by you, the <b>MTA</b> users.</p>
            <p>Through these communities, you can post, comment, vote, discuss, learn, debate, support, and connect with people who share your interests.</p>
            <p>While not every review may be for you (and you may find some unrelatable or even offensive), none of the reviews should be used as a weapon. Communities should create a sense of belonging for their members, not try to diminish it for others. Likewise, everyone on <b>MTA</b> site should have an expectation of privacy and safety, so please respect the privacy and safety of others.</p>
            <p>Every community on <b>MTA</b> is defined by its users. The culture of each community is shaped explicitly, by the community rules enforced by moderators, and implicitly, by the upvotes, downvotes, and discussions of its community members. Please abide by the rules of communities in which you participate and do not interfere with those in which you are not a member.</p>
            <p>Below the rules governing each community are the platform-wide rules that apply to everyone on <b>MTA</b> site. These rules are enforced by us, the admins.</p>
            <p><b>MTA</b> and its members are only what we make of them together, and can only exist if we operate by a shared set of rules and values. We ask that you abide by not just the letter of these rules, but the spirit as well.</p>
            <br />

            <h4>Rules</h4>
            <br />

            <div >
                <h5>Rule 1</h5>
                <p>Remember the human. <b>MTA</b> is a place for creating community and belonging, not for attacking marginalized or vulnerable groups of people. Everyone has a right to use <b>MTA</b> free of harassment, bullying, and threats of violence. Communities and users that incite violence or that promote hate based on identity or vulnerability will be removed.</p>
                <br />

                <h5>Rule 2</h5>
                <p>Abide by community rules. Post authentic content into communities where you have a personal interest, and do not cheat or engage in content manipulation (including spamming, vote manipulation) or otherwise interfere with or disrupt <b>MTA</b> platform users.</p>
                <br />

                <h5>Rule 3</h5>
                <p>Respect the privacy of others. Instigating harassment, for example by revealing someone’s personal or confidential information, is not allowed. Posting or threatening to post intimate or sexually-explicit media of someone shall be strictly prohibited.</p>
                <br />

                <h5>Rule 4</h5>
                <p>Do not post or encourage the posting of sexual or suggestive content involving minors.</p>
                <br />

                <h5>Rule 5</h5>
                <p>You don’t have to use your real name to use <b>MTA</b>, but do not impersonate an individual or an entity in a misleading or deceptive manner.</p>
                <br />

                <h5>Rule 6</h5>
                <p>Ensure people have predictable experiences on <b>MTA</b> platform by properly labeling content and communities, particularly graphic content that may be considered explicit or offensive.</p>
                <br />

                <h5>Rule 7</h5>
                <p>Keep it legal, and avoid posting illegal content or soliciting or facilitating illegal or prohibited transactions.</p>
                <br />

                <h5>Rule 8</h5>
                <p>Don’t break the site or do anything that interferes with normal use of <b>MTA</b>.</p>
                <br />

            </div>
            <br />

            <h4>Enforcement</h4>
            <br />

            <p>We have a variety of ways of enforcing our rules, including, but not limited to</p>
            <ul>
                <li>Asking you nicely to knock it off</li>
                <li>Asking you less nicely</li>
                <li>Temporary or permanent suspension of accounts</li>
                <li>Removal of content</li>
            </ul>
            <p>Updated: 27 July 2020</p>
        </Container>
    )
}

export default ContentPolicy