import React, { useState, useEffect, useRef } from 'react'
import "./SignInPage.scss"
import { Loader } from 'components';
import { Card, Typography, CardHeader, TextField, Button, CardActions, IconButton, Tooltip } from '@material-ui/core'
import { Link, Redirect } from "react-router-dom";
import { isEmail } from 'validator'
import BackIcon from 'assets/images/step-back.png'
import Google from 'assets/images/google.png'
import Facebook from 'assets/images/facebook.png'
import { API_URL } from 'config';

const SignInPage = (props) => {
  const [username, setUsername] = useState('')
  const [slideIndex, setSlideIndex] = useState(0)
  const [password, setPassword] = useState('')

  const passRef = useRef()

  useEffect(() => {
    if (username && props.user.success) {
      setSlideIndex(slideIndex + 1);
      passRef.current.focus()
    }
  }, [props.user.error, props.user.success]);

  if (props.user._id) {
    return <Redirect to='/' />
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const params = {};
    if (username && !password) {
      if (isEmail(username)) {
        params.email = username;
      } else {
        params.username = username;
      }
      props.requestValidateLogin(params);
    } else if (username && password) {
      if (isEmail(username)) {
        params.email = username;
      } else {
        params.username = username;
      }
      params.password = password;
      props.requestUserLogin(params);
    }
  }

  const onBack = (e) => {
    setSlideIndex(slideIndex - 1);
    setPassword('')
  }

  const handleLoginWithGoogle = () => {
    window.location = `${API_URL}/auth/google`
  }

  const handleLoginWithFacebook = () => {
    window.location = `${API_URL}/auth/facebook`
  }

  return (
    <div className="sign-in-page">
      <div className={`sign-in-container ${slideIndex === 1 ? 'email' : ''}`}>
        <div className={"sign-in-form"}>
          <Card style={{ padding: 30, maxWidth: 400 }} elevation={0} >
            <CardHeader title="Sign In" />
            <CardActions className="login-with" >
              <Tooltip title="Login with Google" >
                <IconButton onClick={handleLoginWithGoogle} style={{ padding: 0 }}><img src={Google} width={30} /></IconButton>
              </Tooltip>
              {/* {<Tooltip title="Login with Facebook" >
                <IconButton onClick={handleLoginWithFacebook} style={{ padding: 0 }} ><img src={Facebook} width={30} /></IconButton>
              </Tooltip>} */}
            </CardActions>
            <form onSubmit={handleSubmit} >
              <TextField required value={username} onInput={(e) => setUsername(e.target.value)} style={{ marginTop: 10, marginBottom: 25 }} fullWidth label="USERNAME/EMAIL" />
              <Button type='submit' style={{ borderRadius: 30, marginBottom: 50, fontWeight: 'bold' }} color='primary' variant="outlined">Next</Button>
              <Typography color='primary' >{"Or "}
                <Link to="/signup" style={{ fontWeight: 'bold' }} >Create An Account Here.</Link>
              </Typography>
            </form>
            <Loader loading={props.user.loading} />
          </Card>
        </div>
        <div className="sign-in-form">
          <Card style={{ padding: 30, maxWidth: 400 }} elevation={0} >
            <Button className="back-button" color='primary' variant="outlined" onClick={onBack}>
              <img width={20} height={20} src={BackIcon} />
            </Button>
            <div className="header-container">
              <CardHeader title="Welcome Back!" />
              <Typography className="email-address" color='primary'>{username}</Typography>
            </div>
            <form onSubmit={handleSubmit} >
              <TextField inputRef={passRef} required value={password} onInput={(e) => setPassword(e.target.value)} style={{ marginBottom: 20 }} fullWidth label="PASSWORD" type='password' />
              <Typography color='primary' style={{ marginBottom: 20 }}><Link to='/forgot-password' >Forgot your password?</Link></Typography>
              <Button type='submit' style={{ borderRadius: 30, marginBottom: 20, fontWeight: 'bold' }} color='primary' variant="outlined" >Sign In</Button>
              <Typography color='primary' >{"Or "}
                <Link to="/signup" style={{ fontWeight: 'bold' }} >Create An Account Here.</Link>
              </Typography>
            </form>
            <Loader loading={props.user.loading} />
          </Card>
        </div>
      </div>
    </div>
  )
}

export default SignInPage