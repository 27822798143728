import React from 'react'
import './MaaSPage.scss'
import { Container } from 'react-bootstrap'
import mainImage from 'assets/images/MaaSPage_img/MAAS-main.png'
import MaasPollCard from 'components/MaasPollCard'
import MaasImg1 from 'assets/images/MaaSPage_img/MAAS-AllModes.png'
import MaasImg2_1 from 'assets/images/MaaSPage_img/MAAS_JourneyPlanning1.png'
import MaasImg2_2 from 'assets/images/MaaSPage_img/MAAS_JourneyPlanning2.png'
import MaasImg3 from 'assets/images/MaaSPage_img/MAAS-Routing_Direction.png'
import MaasImg4 from 'assets/images/MaaSPage_img/MAAS-Payment.png'
import MaasImg5 from 'assets/images/MaaSPage_img/MAAS-Customer-Service-Chatbot.png'
import { DiscussionEmbed } from 'disqus-react'

const maasFeatures = [
    {
        title: 'Integrate All Modes of Transport',
        description: 'Connect public and private transports in one place',
        imgSrc: [MaasImg1],
    }, {
        title: 'Maps and Journey Planning',
        description: 'Providing information of arrival and departure time, fare cost, fastest route, shortest distance route, real time information etc ',
        imgSrc: [MaasImg2_1, MaasImg2_2],
    }, {
        title: 'Accurate Routing and Directions',
        description: 'Present best connections, travel options, paths, and nearby stops',
        imgSrc: [MaasImg3],
    }, {
        title: 'Seamless Digital Payment',
        description: 'Pay tickets online securely via your preferred e-wallet (QR)',
        imgSrc: [MaasImg4],
    }, {
        title: 'Personalized, Fast, 24/7 Support',
        description: 'Report issues, lost items, or even go live chat with customer service anytime. Support multilingual',
        imgSrc: [MaasImg5],
        linkSrc: 'https://m.me/mbpjsta',
        linkName: 'Click here for chatbot demo'
    },
]

const MaaSPage = () => {
    return (
        <Container className='text-left main-container'>

            <h3>CAST YOUR VOTE: WHAT KEY FUNCTIONS DO YOU LIKE TO HAVE ON AN INTEGRATED JOURNEY PLANNING AND PAYMENT APP? </h3>
            <br />
            <p>An important feature of our study is to understand users’ needs in Klang Valley. We work from the principle that the proposed innovation should be driven by the needs of local people. Please let us know the key features you would like to have on an integrated journey planning and payment app.</p>
            <a href="#what-is-maas" ><p>Learn more about MaaS</p></a>
            <br />
            <div>
                {
                    maasFeatures.map(({ title, description, imgSrc, linkName, linkSrc }, key) => <MaasPollCard title={title} description={description} imgSources={imgSrc} linkName={linkName} linkSrc={linkSrc} />)
                }
            </div>
            <br />
            <br />
            <br />
            <a id="what-is-maas" ><h3>What is Mobility-as-a-Service (MaaS)?</h3></a>
            <p>
                Mobility-as-a-Service (MaaS) is a relatively new concept that provides a user-focused end-to-end multi-modal transport service through an integrated planning and payment interface. MaaS is gaining momentum in the transport industry with many trials, pilots and commercial deployments in regions and cities around the world including UK, Australia, and many European countries. The main components of MaaS system are likely to provide the following functions and features:
            </p>
            <div className='text-center' >
                <img className='image-content' src={mainImage} />
            </div>
            <p>
                The core of the MaaS concept is that all user transport needs are delivered through a single entity in order to improve the transport user experience. This could be through a single multimodal mobility app for example, where all public transport choices, e-hailing services, car rental, and on-demand transport along with payment options (e-wallet, smart and account based ticketing) are supplied through a one platform.
            </p>
            <p>
                A commonly-used analogy is that mobility will be commoditized and offered to consumers in the way mobile phone services are currently offered (e.g. by Pay-As-You-Go and subscription); through a single provider, but with the service delivered across shared infrastructure and different areas (including international coverage through roaming services). In reality this is likely to be a longer-term aspiration, with more practical near-term MaaS goals focused on incremental simplification of multi-modal journey planning and integrated payment through:
            </p>
            <ul>
                <li>Generation, sharing, and integration of transport data about static (e.g. bus routes and location of bus stops) and variable (e.g. bus location and occupancy level) assets</li>
                <li>Digital information delivered in a user-focused way.</li>
                <li>Dynamic travel alerts, to advise travellers to use alternative modes in order to avoid disruptions or service congestion.</li>
                <li>Multi-operator fares and ticketing that homogenise ticketing/right to travel (smartcard, mobile phone, bank card, e-wallets) and provide seamless payment.</li>
                <li>Payment to multiple service operators, most likely using algorithms that calculate the combined cost of joined-up journeys and (via an app/API) to take payment and distribute it to all service providers.</li>
            </ul>
            <br />
            {/* <b><p>How public transport operators are using the MaaS platform?</p></b>
            <p>Under the MaaS platform, the public transport operators will negotiate pricing with the MaaS operator, who will package services and offer plans and payment options to suit consumers. Public transport operators have to get their systems ready and provide real-time data, schedules as well as their e-payment systems to be connect to MaaS operators platform.</p> */}
            <p>Potential benefits of MaaS</p>
            <ul>
                <li>Improving Journey experience for users</li>
                <li>Enhancing revenue in public transport</li>
                <li>Supporting innovation in transport</li>
                <li> Delivering economics, social and environmental benefits</li>
                <li>Gaining more insight about users behaviour and mode choices</li>
                <li>Providing a tool for influencing travel behaviour (e.g. through real-time dynamic pricing)</li>
            </ul>

            {/* <div className='mt-5' >
                <DiscussionEmbed
                    shortname='MTA'
                    config={{
                        url: 'https://maastransitadvisor.disqus.com',
                        identifier: 'https://maastransitadvisor.disqus.com'
                    }}
                />
            </div> */}
        </Container>
    )
}

export default MaaSPage