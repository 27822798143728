import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import "./VerifyEmailPage.scss"
import { Loader } from 'components';
import { Button } from '@material-ui/core'
import axios from 'axios';
import { API_END_POINTS } from '../../config';
import { requestUserLogout } from 'redux/actions';

import VerifySuccessImage from 'assets/images/verify-success.png';
import VerifyFailedImage from 'assets/images/verify-failed.png';

const VerifyEmailPage = (props) => {  
  const { emailCode } = useParams();
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  
  useEffect(() => {
    const verifyEmail = async () => {
      try {      
        const response = await axios({
          method: 'post',
          url: API_END_POINTS.VERIFY_EMAIL,
          data: { emailCode }
        })
        setLoading(false);
        setError(response.data.error);    
      } catch (e) {
        setLoading(false);
        setError(true);
        console.log('ERROR: ', e.message);
      }
    }
    if (!verified) {
      setVerified(true);
      verifyEmail();
    }
  });

  const onLogout = () => {
    global.store.dispatch(requestUserLogout({ requiredLogin: true }));        
  }

  return (
    <div className="verify-email-page">
      {
        !loading
        ?
        <div className="verify-email-container">
          <div className="verify-email-wrapper">
            <img src={!error ? VerifySuccessImage : VerifyFailedImage} alt="verify success" className="verify-success-image" />
            <h1>{!error ? `Awesome!` : `Opps!`}</h1>
            {
              !error
              ?
              <p>You have successfully verified your email address</p>
              :
              <p>The verification link is either invalid or expired</p>
            }
            <Button onClick={onLogout} color='primary' variant="outlined" to="/signin">Start Exploring</Button>
          </div>
        </div>        
        :
        null
      }      
      <Loader loading={loading} />
    </div>
  )
}

export default VerifyEmailPage