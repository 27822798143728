import { ApiRequest } from 'helpers';
import { API_END_POINTS } from 'config/api-end-points';

export const postReview = data => {
    return ApiRequest.fetch({
        method: 'post',
        url: `${API_END_POINTS.REVIEW_POST}`,
        data
    });
}

export const getReview = params => {
    return ApiRequest.fetch({
        method: 'get',
        url: `${API_END_POINTS.REVIEW_GET}`,
        params
    });
}

export const getReviewMapView = () => {
    return ApiRequest.fetch({
        method: 'get',
        url: `${API_END_POINTS.REVIEW_GET_MAPVIEW}`
    });
}

export const getReviewDetails = (reviewId) => {
    return ApiRequest.fetch({
        method: 'get',
        url: `${API_END_POINTS.REVIEW_GET_DETAILS}/${reviewId}`
    });
}

export const postReviewComment = (reviewId, data) => {
    return ApiRequest.fetch({
        method: 'post',
        url: `${API_END_POINTS.REVIEW_COMMENT}/${reviewId}`,
        data
    });
}

export const postReviewReport = (contentType, contentId, data) => {
    return ApiRequest.fetch({
        method: 'post',
        url: `${API_END_POINTS.REVIEW_REPORT}/${contentType}/${contentId}`,
        data
    });
}

export const deleteComment = (commentId) => {
    return ApiRequest.fetch({
        method: 'delete',
        url: `${API_END_POINTS.REVIEW_COMMENT_DELETE}/${commentId}`,
    });
}

export const deleteReply = (replyId) => {
    return ApiRequest.fetch({
        method: 'delete',
        url: `${API_END_POINTS.COMMENT_REPLY_DELETE}/${replyId}`,
    });
}

export const deleteReview = (reviewId) => {
    return ApiRequest.fetch({
        method: 'delete',
        url: `${API_END_POINTS.REVIEW_DELETE}/${reviewId}`,
    });
}

export const upvoteReview = (reviewId) => {
    return ApiRequest.fetch({
        method: 'get',
        url: `${API_END_POINTS.REVIEW_UPVOTE}/${reviewId}`,
    });
}

export const downvoteReview = (reviewId) => {
    return ApiRequest.fetch({
        method: 'get',
        url: `${API_END_POINTS.REVIEW_DOWNVOTE}/${reviewId}`,
    });
}

export const upvoteComment = (commentId) => {
    return ApiRequest.fetch({
        method: 'put',
        url: `${API_END_POINTS.COMMENT_UPVOTE}/${commentId}`,
    });
}

export const downvoteComment = (commentId) => {
    return ApiRequest.fetch({
        method: 'put',
        url: `${API_END_POINTS.COMMENT_DOWNVOTE}/${commentId}`,
    });
}

export const upvoteReply = (replyId) => {
    return ApiRequest.fetch({
        method: 'put',
        url: `${API_END_POINTS.COMMENT_REPLY_UPVOTE}/${replyId}`,
    });
}

export const downvoteReply = (replyId) => {
    return ApiRequest.fetch({
        method: 'put',
        url: `${API_END_POINTS.COMMENT_REPLY_DOWNVOTE}/${replyId}`,
    });
}

export const replyComment = (commentId, data) => {
    return ApiRequest.fetch({
        method: 'post',
        url: `${API_END_POINTS.COMMENT_REPLY}/${commentId}`,
        data
    });
}

export const uploadFile = (data) => {
    return ApiRequest.fetch({
        method: 'post',
        url: `${API_END_POINTS.FILE_UPLOAD}`,
        data
    });
}

export const getReviewItems = () => {
    return ApiRequest.fetch({
        method: 'get',
        url: `${API_END_POINTS.REVIEW_ITEMS}`
    });
}

export const searchItem = (params) => {
    return ApiRequest.fetch({
        method: 'get',
        url: `${API_END_POINTS.REVIEW_SEARCH}`,
        params
    });
}

export const fetchTrending = () => {
    return ApiRequest.fetch({
        method: 'get',
        url: `${API_END_POINTS.FETCH_TRENDING}`
    });
}