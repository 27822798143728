import PopularPage from './PopularPage';
import { connect } from 'react-redux';
import { requestReviewGet } from 'redux/actions';

const mapStateToProps = ({ getReviewStatus}) => {
    return {
        getReviewStatus
    }
}

export default connect(mapStateToProps,{
    requestReviewGet
})(PopularPage);