import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import '../ContentPages.scss'

const Disclaimer = () => {
    return (
        <Container className='text-left main-container' >
            <h4>DISCLAIMER</h4>
            <br />
            <p>The information provided by Atur Trafik Sdn. Bhd. (“we,” “us” or “our”) on <Link to='/' >https://www.maastransitadvisor.com</Link> (the “<b>MTA</b> Site”) is for general informational purposes only. All information on the <b>MTA</b> Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the <b>MTA</b> Site.</p>
            <p>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the <b>MTA</b> Site or reliance on any information provided on the <b>MTA</b> Site. Your use of the <b>MTA</b> Site and your reliance on any information on the <b>MTA</b> Site is solely at your own risk. This disclaimer was created using Termly’s disclaimer generator.</p>

            <br />
            <p><b>External Links Disclaimer for Website</b></p>
            <p>The <b>MTA</b> Site may contain (or you may be sent through the <b>MTA</b> Site links to other websites or content belonging to or originating from third parties or links to websites and features in) banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.</p>
            <p>We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the <b>MTA</b> Site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</p>

            <br />
            <p><b>Professional Disclaimer for Website</b></p>
            <p>The <b>MTA</b> Site cannot and does not contain professional traffic/route advice. The traffic/route information is provided for general informational purposes only and is not a substitute for professional advice.</p>
            <p>Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of professional traffic/route advice. The use or reliance of any information contained on this <b>MTA</b> Site is solely at your own risk.</p>

            <br />
            <p><b>Testimonials Disclaimer for Website</b></p>
            <p>The <b>MTA</b> Site may contain testimonials by users of our services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary. </p>
            <p>The testimonials on the <b>MTA</b> Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the <b>MTA</b> Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public. </p>
            <p>The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials.</p>

            <br />
            <p><b>User Content on Review Cards</b></p>
            <p>The <b>MTA</b> Site may offer users the ability to post messages and photos on review cards, maps, comments, survey, and other interactive areas as a part of the <b>MTA</b> Site, which may be open to the public generally, to all users of the <b>MTA</b> Site. You acknowledge that all contents posted on the <b>MTA</b> Site is User Content, and by posting on the <b>MTA</b> Site you agree to comply with the rules and restrictions on User Content set forth above and any other rules specifically applicable to <b>MTA</b> Site. We reserve the right, but disclaims any obligation or responsibility, to prevent you from posting User Content on <b>MTA</b> Site and to restrict or remove your User Content from <b>MTA</b> Site or refuse to include your User Content on <b>MTA</b> Site for any reason at any time, in our sole discretion and without notice to you.</p>
            <p>You acknowledge that messages and photos posted on <b>MTA</b> Site are public, and we cannot guarantee the security of any information you disclose through on <b>MTA</b> Site; you make such disclosures at your own risk. We are not responsible for the content or accuracy of any information posted on <b>MTA</b> Site and shall not be responsible for any decisions made based on such information.</p>

            <br />
            <p><b>What to do if you can’t access parts of this website</b></p>
            <p>If you can't access parts of this website, we will consider your request and provide a suitable solution. Please contact us via email or phone:</p>
            <ul>
                <li>Email <a className='text-dark' href="mailto:info@maastransitadvisor.com">info@maastransitadvisor.com</a></li>
                <li>Phone 03-8893 0488 / 5700</li>
            </ul>

            <br />
            <p><b>Reporting accessibility problems with this website</b></p>
            <p>We’re always looking to improve the accessibility of this website. If you find any problems that we’re not meeting accessibility requirements, please email <a className='text-dark' href="mailto:info@maastransitadvisor.com">info@maastransitadvisor.com</a>.</p>

            <br />
            <p>Last updated 27 July 2020</p>
        </Container>
    )
}

export default Disclaimer