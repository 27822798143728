import React, { useState, useEffect } from 'react'
import { Nav } from 'react-bootstrap'
import NavBarIcon from './NavBarIcon'
import Notification from 'assets/images/icons-notification.png';
import { Link } from 'react-router-dom';
import { Badge, Typography, Menu, MenuItem, Divider } from '@material-ui/core';
import './NotificationIcon.scss'

const dummy = [
    {
        profile: 'Mrs.XYZ profile pic',
        message: 'Mrs.XYZ liked your review',
        timestamp: '2 minutes ago',
    },
    {
        profile: 'ABC profile pic',
        message: 'ABC, USERNAME and 3 other people followed Hhh\'s review',
        timestamp: '1h',
    }
]

const NotificationItems = ({ data }) => {
    return (
        <div>
            <div style={{ marginRight: 15 }} className='d-flex justify-content-end' >
                <Link ><Typography variant='caption' >Mark all as read</Typography></Link>
                {/* <Typography style={{ margin: 5 }} variant='caption' >.</Typography> */}
                {/* <Link ><Typography variant='caption' >Setting</Typography></Link> */}
            </div>
            <Divider />
            {
                data.length > 0 ? //if noti not null
                    data.map(({ profile, message, timestamp }, key) => (
                        <div key={key} >
                            <div className='d-flex align-items-center' id='menu-item'>
                                <div className='flex-1' style={{ width: 20, height: 20, backgroundColor: 'black', borderRadius: 20, marginRight: 10 }} />
                                <div className='flex-grow-1' >
                                    <Typography variant='subtitle2'>{message}</Typography>
                                </div><br />
                                <div style={{ marginLeft: 5 }} className='flex-1' >
                                    <Typography variant='caption'>{timestamp}</Typography>
                                </div>
                            </div>
                            <Divider />
                        </div>
                    ))
                    :
                    <div>
                        <MenuItem >No new notification</MenuItem>
                        <Divider />
                    </div>
            }
        </div>
    )
}

const NotificationIcon = () => {
    const [noti, setNoti] = useState(null)
    const [anchorEl, setAnchorEl] = useState()

    const fetchNotification = () => {
        //fetch to notification api
        setNoti([])
        // setNoti(dummy)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        fetchNotification()
    }, [])

    return (
        <div>
            <Nav.Link onClick={(e) => setAnchorEl(e.currentTarget)}  >
                <Badge invisible={noti ? noti.length > 0 ? false : true : true} badgeContent={noti ? noti.length : ""} color="secondary" overlap='circle' >
                    <NavBarIcon icon={Notification} label={"Notification"} />
                </Badge>
            </Nav.Link>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                elevation={1}
            >
                <NotificationItems data={noti} />
                <MenuItem className='d-flex justify-content-center' >
                    <Link onClick={handleClose} to='/notification' >See All</Link>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default NotificationIcon