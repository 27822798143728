import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { TextField, Button, Typography } from '@material-ui/core'
import { sendContactUs } from 'redux/api'
import { toast } from 'react-toastify'
import { Loader } from 'components'
// import '../ContentPages.scss'

const ContactUs = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        setLoading(true)
        const contactUsData = { firstName, lastName, email, message }
        try {
            await sendContactUs(contactUsData)
            toast.success(`Thank You ${firstName}! Your message has been delivered`)
            handleResetForm()
            setLoading(false)
        } catch (e){
            // console.log('errroror', e)
         }
    }

    const handleResetForm = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setMessage('')
    }

    return (
        <div>
            <Container className='main-container text-left text-dark' >
                <h1 className='text-dark' >Contact Us</h1>
            </Container>
            <Container className='d-flex main-container' >
                <Row className='w-100' >
                    <Col lg={6} md={12} className='text-left pb-5' >
                        <Container>
                            <p className='text-dark' ><b>Contact Number:</b></p>
                            <p>+603-8893 0488 / 5700</p>
                            <p className='text-dark' ><b>Email:</b></p>
                            <a className='text-dark' href="mailto:info@maastransitadvisor.com">info@maastransitadvisor.com</a>
                        </Container>
                    </Col>
                    <Col lg={6} md={12} >
                        <Container>
                            <Typography variant='h5' className='text-left' >DROP US A MESSAGE</Typography>
                            <form onSubmit={handleSubmitForm} >
                                <Row>
                                    <Col>
                                        <TextField value={firstName} onChange={(evt) => setFirstName(evt.target.value)} style={{ marginTop: 5 }} fullWidth label='First Name' required />
                                    </Col>
                                    <Col>
                                        <TextField value={lastName} onChange={(evt) => setLastName(evt.target.value)} style={{ marginTop: 5 }} fullWidth label='Last Name' required />
                                    </Col>
                                </Row>
                                <TextField value={email} type='email' onChange={(evt) => setEmail(evt.target.value)} style={{ marginTop: 5 }} fullWidth label='Email' required />
                                <TextField value={message} onChange={(evt) => setMessage(evt.target.value)} style={{ marginTop: 20 }} variant='outlined' multiline rows={3} fullWidth label='Message' required />
                                <Button type='submit' style={{ marginTop: 20 }} fullWidth variant='contained' color='primary' disableElevation >submit</Button>
                            </form>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Loader loading={loading} />
        </div>
    )
}

export default ContactUs