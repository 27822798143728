import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Typography, Button, Divider } from '@material-ui/core'
import { checkIsAdmin, getAllUser, downloadCSV, removeAdminPrivilege, giveAdminPrivilege, getOverviewReport, downloadFullReport } from 'utils'
import { TableList } from 'components'
import RemoveIcon from '@material-ui/icons/DeleteForeverRounded'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import { convertArrayToCSV } from 'convert-array-to-csv'
import moment from 'moment'
import { Bar, BarChart, XAxis, Tooltip, YAxis, ResponsiveContainer, Pie, PieChart, Cell, Legend } from 'recharts'

/**
 * get all user [x]
 * get all admin [x]
 * add user as new admin [x]
 * remove admin from admin role [x]
 * added filter to find user by email [x]
 * overview report - user count, unverified n verified count, total review count, review count by score, poll results[]
 * download full db report []
 * delete user []
 * add and remove poster popup []
 */

const adminActionComponent = [
    {
        title: 'Remove Admin Privilege',
        placement: 'top',
        color: 'secondary',
        icon: <RemoveIcon />
    }
]

const userActionComponent = [
    {
        title: 'Give Admin Privilege',
        placement: 'top',
        color: 'primary',
        icon: <AddIcon />
    }
]

export const AdminPage = ({ user }) => {
    const [isAdmin, setIsAdmin] = useState(false)
    const [allNonAdmin, setAllNonAdmin] = useState([])
    const [allAdmin, setAllAdmin] = useState([])
    const [refreshPage, setRefreshPage] = useState(false)
    const [filterEmail, setFilterEmail] = useState('')
    const [latestReport, setLatestReport] = useState(undefined)

    useEffect(() => {
        checkIsAdmin()
            .then(res => {
                if (res) return setIsAdmin(res)
                return window.location = './profile'
            })

        getAllUser()
            .then(({ data }) => {
                if (!data) return ''
                const admins = data.filter(({ role }) => (role && role.isAdmin === true))
                const nonAdmin = data.filter(({ role }) => (!role || (role && role.isAdmin === false)))
                setAllAdmin(admins)
                setAllNonAdmin(nonAdmin)
            })

        getOverviewReport()
            .then(res => setLatestReport(res.data))
    }, [refreshPage])

    const handleAddNewAdmin = (rowId) => {
        const newAdmin = allNonAdmin.find(({ _id }) => _id === rowId)
        if (!newAdmin) return alert('No such user found!')
        const allow = window.confirm('Are you sure want to give admin privilege to ' + newAdmin.name)
        if (!allow) return ''
        giveAdminPrivilege({ userId: newAdmin._id })
        setRefreshPage(!refreshPage)
    }

    const handleRemoveAdminPrivilege = (rowId) => {
        const removedUser = allAdmin.find(({ _id }) => _id === rowId)
        if (!removedUser) return alert('No such user found!')
        if (removedUser._id === user._id) return alert('Not Allowed!\nPlease ask another Admin to remove your admin privilege')
        const allow = window.confirm('Are you sure want to remove admin privilege from ' + removedUser.name)
        if (!allow) return ''
        removeAdminPrivilege({ userId: removedUser._id })
        setRefreshPage(!refreshPage)
    }

    const handleExportUsers = () => {
        const allUser = allNonAdmin.concat(allAdmin)
        allUser.forEach((usr, i) => {
            if (!usr.role) return allUser[i]['role'] = 'Not Admin'
            allUser[i].role = usr.role.isAdmin ? 'Admin' : 'Not Admin'
        })
        const test = convertArrayToCSV(allUser) + '\n\n\nGenerated On,' + moment().format('dddd DD/MM/YYYY hh:mm:ss') + '\nGenerated By,' + user.name
        downloadCSV(test, `All_MTA_User_${moment().format('YYYYMMDD')}`)
    }

    const handleExportOverviewReport = () => {
        // console.table(latestReport)
        const pollS = convertArrayToCSV(latestReport.poll)
        const reviewS = JSON.stringify(latestReport.review).slice(1, -1).replaceAll('"', "").replaceAll(',', "\n").replaceAll(':', ",")
        const userS = JSON.stringify(latestReport.user).slice(1, -1).replaceAll('"', "").replaceAll(',', "\n").replaceAll(':', ",")
        const end = '\n\n\nGenerated On,' + moment().format('dddd DD/MM/YYYY hh:mm:ss') + '\nGenerated By,' + user.name
        const csv = `\nUser Overview\n\n${userS}\n\nReview Overview\n\n${reviewS}\n\nPoll Overview\n\n${pollS}${end}`
        downloadCSV(csv, `MTA_Overview_Report_${moment().format('YYYYMMDD')}`)
    }

    const handleFindUserByEmail = (data) => {
        const regex = new RegExp(filterEmail, 'g')
        return data.filter(({ email }) => regex.test(email))
    }

    const handleCleanReviewData = (raw) => {
        const cleaned = []
        Object.entries(raw).forEach(([name, count]) => {
            if (name === 'total') return ''
            cleaned.push({ name, count })
        })
        return cleaned
    }

    const handleDownloadFullReport = (raw) => {
        downloadFullReport()
            .then(data => {
                const url = window.URL.createObjectURL(data)
                const link = document.createElement("a")
                link.href = url
                link.setAttribute(
                    "download",
                    `MTA_User_test_${moment().format('DDMMYYYY')}.xlsx`
                )
                document.body.appendChild(link)
                link.click()
            })
    }

    const COLORS = {
        terrible: '#FF0000aa',
        disappointing: '#FFC900aa',
        bad: '#F0FF00aa',
        neutral: '#275AC2aa',
        good: '#0084C2aa',
        pleasent: '#00C251aa',
        awesome: '#6EE34Caa'
    }
    return (
        <Container className='text-left main-container' >
            {
                isAdmin ?
                    <div>
                        <Typography variant='h4' >Admin Dashboard</Typography>
                        {
                            allAdmin &&
                            <div className='mt-5' >
                                <Typography variant='h5' >Administrator ({allAdmin.length})</Typography>
                                <TableList onClicked={handleRemoveAdminPrivilege} uniqId='_id' highlightUniqId={user._id} actions actionComponent={adminActionComponent} th='name' hideKey={['_id', 'role']} rows={allAdmin} objectKey={['role']} hideKeyChild={['isAdmin', 'removedBy', 'removedOn', 'addedOn', 'addedBy', 'super']} highlightKeyChild={['super']} />
                            </div>
                        }
                        {
                            allNonAdmin &&
                            <div className='mt-5' >
                                <Typography variant='h5' >User ({allNonAdmin.length})</Typography>
                                <div className='text-right m-1' >
                                    <input placeholder='find user by email' onChange={(evt) => setFilterEmail(evt.target.value)} />
                                </div>
                                <TableList onClicked={handleAddNewAdmin} uniqId='_id' actions actionComponent={userActionComponent} th='name' hideKey={['_id', 'role']} rows={handleFindUserByEmail(allNonAdmin)} />
                                <Button className='mt-3' color='primary' fullWidth variant='contained' onClick={handleExportUsers} >Download All User</Button>
                            </div>
                        }
                        <Divider className='my-5' />
                        {
                            latestReport &&
                            <div className='border border-primary rounded m-0 p-2'  >
                                <Typography variant='h5' className='mb-2' >Report Overview</Typography>
                                <Row className='align-items-center text-sm-center text-md-left' >
                                    <Col md={4} sm={12} >
                                        Total User Count : {latestReport.user.count}<br />
                                        Verified User Count : {latestReport.user.verified}<br />
                                        Unverified User Count : {latestReport.user.unverified}<br />
                                    </Col>
                                    <Col md={4} sm={12} >
                                        Total Review Count : {latestReport.review.total}<br />
                                        Awesome : {latestReport.review.awesome}<br />
                                        Pleasent : {latestReport.review.pleasent}<br />
                                        Good : {latestReport.review.good}<br />
                                        Neutral : {latestReport.review.neutral}<br />
                                        Bad : {latestReport.review.bad}<br />
                                        Disappointing : {latestReport.review.disappointing}<br />
                                        Terrible : {latestReport.review.terrible}<br />
                                    </Col>
                                    <Col md={4} sm={12} className='d-flex flex-column justify-content-center align-items-center' >
                                        <Typography variant="subtitle1" className='mb-2' >Reviews Category</Typography>
                                        <PieChart width={320} height={320}>
                                            <Tooltip />
                                            <Pie
                                                data={handleCleanReviewData(latestReport.review)}
                                                outerRadius={80}
                                                dataKey="count"
                                            >
                                                {
                                                    handleCleanReviewData(latestReport.review).map(({ name }, index) => <Cell key={`cell-${index}`} fill={COLORS[name]} />)
                                                }
                                            </Pie>
                                            <Legend />
                                        </PieChart>
                                    </Col>
                                </Row>
                                <div className='text-center my-3' >
                                    <Typography variant="subtitle1" className='mb-2' >Maas Poll Result</Typography>
                                    <ResponsiveContainer width="95%" height={100} className='my-2' >
                                        <BarChart data={latestReport.poll} maxBarSize={10} barSize={50} >
                                            <XAxis interval={0} angle={-10} dataKey='question' />
                                            <YAxis type='number' label='Votes' />
                                            <Bar dataKey="yes" fill="#00A029aa" />
                                            <Bar dataKey="no" fill="#ED154Eaa" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                    <Button className='mt-3' color='primary' fullWidth variant='contained' onClick={handleExportOverviewReport} >Download Overview Report</Button>
                                </div>
                            </div>
                        }
                        <Divider className='my-5' />
                        <Typography variant='h5' className='mb-2' >Download Data</Typography>
                        <Typography variant="caption" className='mb-2' >Download raw User and Review data for in-depth understanding</Typography>
                        <Button className='mt-3' color='primary' fullWidth variant='contained' onClick={handleDownloadFullReport} >Download MTA Data</Button>
                    </div>
                    :
                    <Typography>Checking if you are an Admin</Typography>
            }
        </Container>
    )
}