import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'config';
import { validateLogin, userLogin, userSignUp, postOnBoardQuestion, updateProfilePhoto, updateProfile, userLoginWithGoogle, userLoginWithFacebook } from '../api';
import {
  userLoginSuccess,
  userLoginFailure,
  userSignUpSuccess,
  userSignUpFailure,
  userLogoutSuccess,
  userLogoutFailure,
  validateLoginSuccess,
  validateLoginFailure
} from '../actions';
import { toast } from 'react-toastify';

export function* watchUserRequests() {
  yield takeEvery(ACTION_TYPES.VALIDATE_LOGIN_REQUESTED, requestValidateLogin);
  yield takeEvery(ACTION_TYPES.USER_LOGIN_REQUESTED, requestUserLogin);
  yield takeEvery(ACTION_TYPES.USER_LOGIN_WITH_GOOGLE_REQUESTED, requestUserLoginWithGoogle);
  yield takeEvery(ACTION_TYPES.USER_LOGIN_WITH_FACEBOOK_REQUESTED, requestUserLoginWithFacebook);
  yield takeEvery(ACTION_TYPES.USER_SIGN_UP_REQUESTED, requestUserSignUp);
  yield takeEvery(ACTION_TYPES.USER_LOGOUT_REQUESTED, requestUserLogout);
  yield takeEvery(ACTION_TYPES.USER_ANSWER_ONBOARDING, requestAnsweredOnboard);
  yield takeEvery(ACTION_TYPES.USER_UPDATE_PROFILE_PHOTO, requestUpdateProfilePhoto);
  yield takeEvery(ACTION_TYPES.USER_UPDATE_PROFILE, requestUpdateProfile);
}

function* requestValidateLogin(action) {
  try {
    const user = yield call(validateLogin, action.payload);
    yield put(validateLoginSuccess(user));
  } catch (e) {
    yield put(validateLoginFailure(e));
  }
}

function* requestUserLogin(action) {
  try {
    const user = yield call(userLogin, action.payload);
    yield put(userLoginSuccess(user));
  } catch (e) {
    yield put(userLoginFailure(e));
  }
}

function* requestUserLoginWithGoogle(action) {
  try {
    const user = yield call(userLoginWithGoogle, action.payload);
    yield put(userLoginSuccess(user));

  } catch (e) {
    yield put(userLoginFailure(e));
    window.location = '/signin';
  }
}

function* requestUserLoginWithFacebook(action) {
  try {
    const user = yield call(userLoginWithFacebook, action.payload);
    yield put(userLoginSuccess(user));

  } catch (e) {
    yield put(userLoginFailure(e));
    window.location = '/signin';
  }
}


function* requestUserSignUp(action) {
  try {
    const user = yield call(userSignUp, action.payload);
    // yield put(userSignUpSuccess(user));
    yield put(userSignUpSuccess());
    window.location = '/signin'; //user need to login after signup - did not get token if straightaway login (quick fix)
  } catch (e) {
    yield put(userSignUpFailure(e));
  }
}

function* requestUserLogout(action) {
  try {
    // do logout logic here
    yield put(userLogoutSuccess());
    if (action.payload && action.payload.requiredLogin) {
      window.location = '/signin';
    } else {
      window.location = '/';
    }
  } catch (e) {
    yield put(userLogoutFailure(e));
  }
}

function* requestAnsweredOnboard(action) {
  try {
    const user = yield call(postOnBoardQuestion, action.payload);
    //reuse this acttion since it will do the same thing
    yield put(userLoginSuccess(user));
  } catch (e) {
    yield put(userLoginFailure(e));
  }
}

function* requestUpdateProfilePhoto(action) {
  try {
    const user = yield call(updateProfilePhoto, action.payload);
    //reuse this acttion since it will do the same thing
    yield put(userLoginSuccess(user));
    toast.success('Profile Avatar Updated')
  } catch (e) {
    yield put(userLoginFailure(e));
  }
}

function* requestUpdateProfile(action) {
  try {
    const user = yield call(updateProfile, action.payload);
    //reuse this acttion since it will do the same thing
    yield put(userLoginSuccess(user));
    toast.success('Profile Updated')
  } catch (e) {
    yield put(userLoginFailure(e));
  }
}