import L from 'leaflet'

const BusStopMarker = new L.Icon({
    iconUrl: require('assets/images/pinIcon.png'),
    iconRetinaUrl: require('assets/images/pinIcon.png'),
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(20, 20),
    // className: 'leaflet-div-icon'
})

export { BusStopMarker }