import React, { useState } from 'react'
import './MySearchbar.scss'
import { IconButton, List, ListItem, ListItemAvatar, ListItemText, CircularProgress } from '@material-ui/core'
import SearchIcon from 'assets/images/search-icon.png'
import { searchItem } from 'redux/api'
import { MyAvatar } from 'components/FeedCard/Commenting'
import { Container, Col } from 'react-bootstrap'

const MySearchbar = (props) => {
    const [searchRes, setSearchRes] = useState([])
    const [loadingResult, setLoadingResult] = useState(false)

    const handleSearchbarOnChange = async (e) => {
        e.preventDefault()
        setLoadingResult(true)
        const search = e.target.value
        if (search.length < 1) {
            setSearchRes([])
            setLoadingResult(false)
            return null
        }
        try {
            const res = await searchItem({ search })
            if (res.data.length < 1) {
                setSearchRes([null])
                setLoadingResult(false)
                return null
            }
            setSearchRes(res.data)
        } catch{ }
        setLoadingResult(false)
    }

    const handleSelectResult = selectedID => {
        props.onSelectItem(selectedID)
        setSearchRes([])
        document.getElementById('searchbar').value = ''
    }

    return (
        <Container className='d-flex justify-content-center align-items-center flex-column custom-searchbar' >
            <Col md={8} sm={12} className='input-container' >
                <IconButton className='icon' >
                    <img width={25} height={25} src={SearchIcon} />
                    {
                        loadingResult &&
                        <CircularProgress size={25} className='loader-icon' />
                    }
                </IconButton>
                <input autoComplete="off" id='searchbar' onChange={handleSearchbarOnChange} className='input' placeholder={props.placeholder} />
                {
                    searchRes.length > 0 &&
                    <List className='search-result bg-light' >
                        {
                            searchRes.map((data, index) => {
                                if (!data) return <ListItem>No result found</ListItem>
                                const { _id, body, user } = data
                                return (
                                    <ListItem key={index} onClick={() => handleSelectResult(_id)} className='result-item' >
                                        <ListItemAvatar><MyAvatar user={user} /></ListItemAvatar>
                                        <ListItemText
                                            primary={user.name}
                                            secondary={`${body.experience} - ${body.operator_name} - ${body.route_name} - ${body.stop_name} - ${body.vehicle_plate}`}
                                        />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                }
            </Col>
        </Container>
    )
}

export default MySearchbar