import React, { useState, useEffect, useRef } from 'react'
import './PopularPage.scss'
import { Container, Dropdown, Row, Col } from 'react-bootstrap'
import { Button, Typography, CircularProgress, Collapse } from '@material-ui/core'
import CreateReviewIcon from 'assets/images/create_review.png'
import { FeedCard, SkeletonFeedCard, Commenting, MySearchbar, AdvanceSearch, Loader } from 'components'
import { Link, useHistory } from 'react-router-dom'
import { Map, TileLayer, Marker, Tooltip } from 'react-leaflet'
import { BusStopMarker } from './MapMarkers'
import * as ELG from "esri-leaflet-geocoder"
import L from 'leaflet'
import _ from 'lodash'

import 'leaflet/dist/leaflet.css'
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css"
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.css'
import { getReviewDetails, getReview } from 'redux/api/review.api'

L.GeoIP = L.extend({
    getPosition: function (ip) {
        var url = "https://freegeoip.app/json/";
        var result = L.latLng(0, 0);

        if (ip !== undefined) {
            url = url + ip;
        } else {
            //lookup our own ip address
        }

        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, false);
        xhr.onload = function () {
            var status = xhr.status;
            if (status == 200) {
                var geoip_response = JSON.parse(xhr.responseText);
                result.lat = geoip_response.latitude;
                result.lng = geoip_response.longitude;
            } else {
                console.log("Leaflet.GeoIP.getPosition failed because its XMLHttpRequest got this response: " + xhr.status);
            }
        };
        xhr.send();
        return result;
    },

    // centerMapOnPosition: function (map, zoom, ip) {
    //     var position = L.GeoIP.getPosition(ip);
    //     map.setView(position, zoom);
    // }
})

const sortingOptions = [
    'Latest',
    'Earliest',
    'Positive',
    'Critical',
    'Most Discussed',
    'Most Upvotes',
    'Most Downvotes'
]

const PopularPage = ({ getReviewStatus, requestReviewGet }) => {
    const [feed, setFeed] = useState(null)
    const [sortedFeed, setSortedFeed] = useState(null)
    const [sortOption, setSortOption] = useState(null)
    const [allPjStops, setAllPjStops] = useState(null)
    const [openComment, setOpenComment] = useState(false)
    const [loadingAS, setLoadingAS] = useState(false)
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false)
    const [commentDetails, setCommentDetails] = useState(undefined)
    const [viewBy, setViewBy] = useState('Map')
    const [mapViewport, setMapViewport] = useState({
        latitude: L.GeoIP.getPosition().lat,
        longitude: L.GeoIP.getPosition().lng,
        zoom: 13
    })

    let reactMap = useRef()
    const history = useHistory()

    const handleFetchFeed = async () => {
        const query = {
            filter: {},
            options: { sort: { 'createdAt': -1 } }
        }
        try {
            const res = await getReview(query)
            setFeed(res.data)
            setSortedFeed(res.data)
        } catch (e) {
            console.log('error with fetching reviews', e)
        }
    }

    const searchAndCurrentLocationControl = () => {
        const map = reactMap.leafletElement
        L.control.locate({
            locateOptions: {
                enableHighAccuracy: true
            },
        }).addTo(map)
        new ELG.Geosearch().addTo(map)
        new L.LayerGroup().addTo(map)
    }

    useEffect(() => {
        handleFetchFeed()
        const allpj = require('assets/dummy_data_images/all_pj_Route.json')
        const uniqStops = _.uniqBy(allpj, 'stop_id')
        setAllPjStops(uniqStops)
    }, [])

    useEffect(() => {
        if (viewBy === 'Map') searchAndCurrentLocationControl()
    }, [viewBy])

    const handleOpenComment = async (_id) => {
        setOpenComment(true)
        try {
            const res = await getReviewDetails(_id)
            setCommentDetails(res.data)
        } catch (e) {
            console.log('error on get review detail', e)
        }
    }

    const handleCloseComment = () => {
        setOpenComment(false)
        setCommentDetails(undefined)
    }

    const handleRefresh = async (_id) => {
        try {
            const res = await getReviewDetails(_id)
            setCommentDetails(res.data)
        } catch (e) {
            console.log('error on get review detail', e)
        }
    }

    const handleSortFeed = (sortBy) => {
        let sorted = feed
        const currUserId = global.store.getState().user._id
        setSortOption(sortBy)
        switch (sortBy) {
            case 'Latest':
                sorted = _.sortBy(feed, 'createdAt').reverse()
                break;
            case 'Earliest':
                sorted = _.sortBy(feed, 'createdAt')
                break;
            case 'Positive':
                sorted = _.sortBy(_.filter(feed, ({ feeling }) => (feeling.score >= 0)), 'feeling.score').reverse()
                break;
            case 'Critical':
                sorted = _.sortBy(_.filter(feed, ({ feeling }) => (feeling.score < 0)), 'feeling.score')
                break;
            case 'Most Discussed':
                sorted = _.orderBy(feed, [o => (o.comments.length)]).reverse()
                break;
            case 'Most Upvotes':
                sorted = _.orderBy(feed, [o => (o.likes.length)]).reverse()
                break;
            case 'Most Downvotes':
                sorted = _.orderBy(feed, [o => (o.likes.length)]) //need to implement downvote function first - but for now just show least liked reviews
                break;
            // case 'Posted by me':
            //     sorted = _.filter(feed, function (o) {
            //         return o.user._id === currUserId;
            //     })
            //     break;
            default:
                break;
        }
        // console.log('sorted', sorted)
        setSortedFeed(sorted)
    }

    const handleDoneSearch = (newReview) => {
        setOpenAdvancedSearch(false)
        setFeed(newReview)
        setSortedFeed(newReview)
    }

    const handleOpenAS = () => {
        setOpenAdvancedSearch(!openAdvancedSearch)
    }

    const handleASStart = () => {
        setFeed(null)
        setSortedFeed(null)
    }

    return (
        <Container fluid >
            <Loader loading={loadingAS} />
            <Commenting refresh={handleRefresh} data={commentDetails} onClose={handleCloseComment} open={openComment} />
            <div className='upper-container' >
                <MySearchbar onSelectItem={handleOpenComment} placeholder='Everyone is searching "PJ Bus"' />
                <p onClick={handleOpenAS} className='mt-2 text-primary btn' >Advanced Search</p>
                <Collapse in={openAdvancedSearch}>
                    <AdvanceSearch onStart={handleASStart} onClose={() => setOpenAdvancedSearch(false)} onDoneSearch={handleDoneSearch} />
                </Collapse>
            </div>
            <div>
                <Container>
                    <Row className="d-flex align-items-center" style={{ paddingTop: 20 }} >
                        <Col md={4} sm={12}>
                            {
                                viewBy === 'Card' &&
                                <Dropdown onSelect={handleSortFeed}  >
                                    <Dropdown.Toggle className='view-by-button' id="dropdown-basic">{sortOption ? sortOption : 'Sort by'}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            sortingOptions.map((val, key) => (
                                                <Dropdown.Item active={sortOption === val ? true : false} key={key} eventKey={val} >{val}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </Col>
                        <Col md={4} sm={12}>
                            <Button className="flex-grow-1" style={{ borderRadius: 20 }} >
                                <img src={CreateReviewIcon} width={30} height={30} />
                                <Link to='/review' style={{ paddingLeft: 10 }} >Create A Review</Link>
                            </Button>
                        </Col>
                        <Col md={4} sm={12} >
                            <Dropdown >
                                <Dropdown.Toggle className='view-by-button' id="dropdown-basic">{viewBy ? viewBy : 'Sort by'}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item active={viewBy === 'Map'} onClick={() => setViewBy('Map')} >Map</Dropdown.Item>
                                    <Dropdown.Item active={viewBy === 'Card'} onClick={() => setViewBy('Card')} >Card</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Container>
                <div className='body-container' >
                    {
                        viewBy === 'Card' ?
                            <Container >
                                <Row className="justify-content-start" >
                                    {
                                        sortedFeed ?
                                            sortedFeed.map((data, key) => (
                                                <FeedCard onClick={() => handleOpenComment(data._id)} data={data} key={key} xs='12' md='6' lg='4' />
                                            ))
                                            :
                                            [1, 2, 3].map((x, key) => <SkeletonFeedCard key={key} />)
                                    }
                                </Row>
                            </Container>
                            :
                            <Container>
                                <Map
                                    ref={m => { if (m) reactMap = m }}
                                    style={{ width: 'inherit', height: '50vh' }}
                                    center={[mapViewport.latitude, mapViewport.longitude]} zoom={mapViewport.zoom}>
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {
                                        feed &&
                                        feed.map(({ body, user, _id }, key) => {
                                            const { latitude, longitude } = body.location
                                            return (<Marker
                                                key={key}
                                                position={[latitude, longitude]}
                                                onClick={() => handleOpenComment(_id)}
                                            >
                                                <Tooltip>
                                                    <Typography>{user.name}</Typography>
                                                </Tooltip>
                                            </Marker>)
                                        })
                                    }
                                    {
                                        allPjStops &&
                                        allPjStops.map(({ stop_name, latitude, longitude, route_name }, key) => (
                                            <Marker
                                                key={key}
                                                position={[latitude, longitude]}
                                                icon={BusStopMarker}
                                                onclick={() => history.push('/review')}
                                            >
                                                <Tooltip >
                                                    <Typography variant='subtitle2' >{route_name}</Typography>
                                                    <Typography variant='subtitle2' >{stop_name}</Typography>
                                                    <Typography variant='caption' >Click to review this stop</Typography>
                                                </Tooltip>
                                            </Marker>
                                        ))
                                    }
                                </Map>
                            </Container>
                    }
                </div>

            </div>
        </Container>
    )
}

export default PopularPage