import React from 'react'
import { Button, TextField } from '@material-ui/core'
import './Aspect.scss'

const AspectItem = (props) => (
    <Button
        className="aspect-item"
        {...props}
        onClick={() => props.onClick({ id: props._id, title: props.title })}
    >
        {props.title}
    </Button>
);

const Aspect = (props) => {
    const { data = [] } = props;
    const aspects = data.filter(item => item.category === 'aspect') || [];
    const services = data.filter(item => item.category === 'service') || [];
    const OTHER = aspects.find(item => item.title === 'Other') || {};
    const STAGEBUS = services.find(item => item.title === 'Stage Bus') || {};
    const EXPRESSBUS = services.find(item => item.title === 'Express Bus') || {};
    const TAXI = services.find(item => item.title === 'Taxi') || {};
    const EHAILING = services.find(item => item.title === 'E-hailing') || {};
    const CARSHARING = services.find(item => item.title === 'Car-sharing') || {};
    const OTHERSERVICE = services.find(item => item.title === 'Other') || {};

    return (
        <div className='review-aspect' >
            <div className='divider'></div>
            <h3 className='title'>Which aspect?</h3>
            {/* <p className='description' >May select more than one</p> */}
            <div className='aspect-list'>
                {
                    aspects.map((aspect, index) => {
                        if (aspect.title === 'Driver') {
                            return (
                                <AspectItem
                                    key={`aspect-item=${index}`}
                                    {...aspect}
                                    onClick={props.onChange}
                                    color={props.value === aspect._id ? 'primary' : 'dark'}
                                    variant={props.value === aspect._id ? 'contained' : 'outlined'}
                                    //disabled when transport doesnt really has driver like train
                                    disabled={(props.service === STAGEBUS._id || props.service === EXPRESSBUS._id || props.service === TAXI._id || props.service === EHAILING._id || props.service === CARSHARING._id || props.service === OTHERSERVICE._id ) ? false : true}
                                />
                            )
                        }

                        return (
                            <AspectItem
                                key={`aspect-item=${index}`}
                                {...aspect}
                                onClick={props.onChange}
                                color={props.value === aspect._id ? 'primary' : 'dark'}
                                variant={props.value === aspect._id ? 'contained' : 'outlined'}
                            />
                        )
                    })
                }
            </div>
            {
                props.value === OTHER._id
                    ?
                    <TextField
                        className='other-text'
                        multiline
                        fullWidth
                        placeholder='Please specify'
                        InputProps={{
                            classes: {
                                input: 'other-text-input'
                            },
                            disableUnderline: true
                        }}
                        onChange={(e) => props.onChangeText(e.target.value)}
                    />
                    :
                    null
            }
        </div>
    )
}

export default Aspect