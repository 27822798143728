import React, { useState, useEffect } from 'react'
import "./SignUpPage.scss"
import { Loader } from 'components';
import { Card, Typography, CardHeader, TextField, Button } from '@material-ui/core'
import { Link, Redirect } from "react-router-dom";

import { isMobilePhone, isEmail, isAlpha } from 'validator'
import { API_END_POINTS } from '../../config';
import axios from 'axios';

const isValidPassword = (password) => {
  // const regExp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  // return regExp.test(password);

  const gotNumericalRegex = /(?=.*[0-9])/
  const gotSpecialCharRegex = /(?=.*[!@#$%^&*])/
  const gotAlphaRegex = /[a-zA-Z0-9!@#$%^&*]/
  const gotRequiredLengthRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

  const gotNum = gotNumericalRegex.test(password)
  const gotSpecialChar = gotSpecialCharRegex.test(password)
  const gotAlpha = gotAlphaRegex.test(password)
  const gotRequiredLength = gotRequiredLengthRegex.test(password)

  if (!gotNum) return [false, 'Please includes number']
  if (!gotSpecialChar) return [false, 'Please includes any special character']
  if (!gotAlpha) return [false, 'Please includes alphabetical character']
  if (!gotRequiredLength) return [false, 'Please provide 6 - 16 characters']

  return [true, '']
}

const noSpacingValidator = (name) => {
  const regExp = /^\S*$/
  return !(regExp.test(name))
}

const isAlphaWithSpacing = (name) => {
  const regExp = /^[\sA-Z]+$/i
  return !regExp.test(name)
}

const SignInPage = (props) => {
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)

  const [username, setUsername] = useState('')
  const [usernameError, setUsernameError] = useState(false)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)

  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)

  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)

  const [loading, setLoading] = useState(false);


  if (props.user._id) {
    return <Redirect to='/' />
  }

  const handleSubmitSignIn = (e) => {

    e.preventDefault()
    if (nameError || usernameError || emailError || phoneError || passwordError || confirmPasswordError) {
      return alert('Please make sure the details are valid')
    }
    // alert('registered')
    const data = { name, username, email, phone, password }

    props.requestUserSignUp(data);
  }

  const handleValidName = (e) => {
    const nName = e.target.value
    setName(nName)
    if (nName.length < 2) return setNameError('Need at least 2 characters')
    // if (!isAlpha(nName)) return setNameError('Name contains non-alphabetical character')
    if (isAlphaWithSpacing(nName)) return setNameError('Name contains non-alphabetical character')
    setNameError(false)
  }

  const handleValidUserName = (e) => {
    const nUserName = e.target.value
    setUsername(nUserName)
    // if (!isAlpha(nUserName) || nUserName.length < 5) return setUsernameError(true)
    if (nUserName.length < 5) return setUsernameError('Need at least 5 characters')
    if (noSpacingValidator(nUserName)) return setUsernameError('No spacing allowed')
    setUsernameError(false)
  }

  const onCheckUserName = async (e) => {
    const uUsername = e.target.value
    // if (!isAlpha(uUsername) || uUsername.length < 5) return;
    if (noSpacingValidator(uUsername) || uUsername.length < 5) return;

    try {
      setLoading(true);
      const response = await axios({
        method: 'post',
        url: API_END_POINTS.CHECK_USERNAME,
        data: { username: uUsername }
      })
      setLoading(false);
      setUsernameError(response.data.error ? response.data.message : false);
    } catch (e) {
      setLoading(false);
      setUsernameError(`Please try again - ERROR:${e.message}`);
      // console.log('ERROR: ', e.message);
    }
  }

  const handleValidEmail = (e) => {
    const nEmail = e.target.value
    setEmail(nEmail)
    if (!isEmail(nEmail)) return setEmailError(true)
    setEmailError(false)
  }

  const handleValidPhone = (e) => {
    const nPhone = e.target.value
    setPhone(nPhone)
    if (!isMobilePhone(nPhone, ['ms-MY'])) return setPhoneError(true)
    setPhoneError(false)
  }

  const handlePasswordStrength = (e) => {
    const nPassword = e.target.value
    setPassword(nPassword)
    // if (!isValidPassword(nPassword)) return setPasswordError(true)
    const valid = isValidPassword(nPassword)
    if (valid[0] || nPassword.length === 0) return setPasswordError(false)
    setPasswordError(valid[1])
  }

  const handleConfirmPassword = (e) => {
    const nconfirm = e.target.value
    setConfirmPassword(nconfirm)
    if (password !== nconfirm) return setConfirmPasswordError('Password does not match')
    setConfirmPasswordError(false)
  }

  return (
    <div className="sign-up-page">
      <Card style={{ padding: 30, borderRadius: 10, maxWidth: 400 }} elevation={0} >
        <CardHeader title="Create Account" />
        <form onSubmit={handleSubmitSignIn} >
          <TextField helperText={nameError} value={name} error={nameError} onInput={handleValidName} required style={{ marginTop: 10 }} fullWidth label="YOUR NAME" />
          <TextField value={username} error={usernameError ? true : false} helperText={usernameError} onInput={handleValidUserName} onBlur={onCheckUserName} required style={{ marginTop: 10 }} fullWidth label="YOUR USERNAME" />
          <TextField value={email} error={emailError} onInput={handleValidEmail} required type='email' style={{ marginTop: 10 }} fullWidth label="EMAIL" />
          {/* <TextField value={phone} error={phoneError} onInput={handleValidPhone} style={{ marginTop: 10 }} fullWidth label="MOBILE NO." /> */}
          <TextField helperText={passwordError} value={password} error={passwordError} onInput={handlePasswordStrength} required style={{ marginTop: 10 }} fullWidth label="PASSWORD" type='password' />
          <TextField helperText={confirmPasswordError} value={confirmPassword} error={confirmPasswordError} onInput={handleConfirmPassword} required style={{ marginTop: 10 }} fullWidth label="CONFIRM PASSWORD" type='password' />

          <Button type='submit' style={{ borderRadius: 30, margin: "20px 0px 10px 0px", fontWeight: 'bold' }} color='primary' variant="outlined" >Sign Up</Button><br />
          <Typography style={{ fontSize: 10 }} variant="caption" >By clicking Sign Up you agree to Terms {"&"} Privacy Policy</Typography>
          <Typography style={{ marginTop: 20 }} color='primary' >
            {" Already have an account? "}
            <Link to="/signin" style={{ fontWeight: 'bold' }} >Sign In here.</Link>
          </Typography>
        </form>
        <Loader loading={loading || props.user.loading} />
      </Card>
    </div>
  )
}

export default SignInPage