import ReviewPage from './ReviewPage'
import { connect } from 'react-redux';
import { requestReviewPost, requestUploadFile, requestReviewItems } from 'redux/actions';

const mapStateToProps = (state) => {
  return {    
    reviewItems: state.reviewItems,
    user: state.user
  }
}

export default connect(mapStateToProps, {
  requestReviewPost,
  requestUploadFile,
  requestReviewItems
})(ReviewPage);