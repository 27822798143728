import React, { Component } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import LOGO from 'assets/images/logo.png';
import Survey from 'assets/images/icons-survey.png';
import Review from 'assets/images/icons-create.png';
import Popular from 'assets/images/icons-popular.png';
import DashBoard from 'assets/images/Dashboard.png';
import { Button, IconButton, Menu, MenuItem, Divider } from '@material-ui/core'
import "./NavBar.scss";
import NavBarIcon from './NavBarIcon.js'
import { requestUserLogout } from 'redux/actions';
import NotificationIcon from './NotificationIcon';
import { MyAvatar } from 'components/FeedCard/Commenting';
import NotificationIconPublic from './NotificationIconPublic';

const navBarButtons = [
  {/*
    icon: DashBoard,
    label: 'Dashboard',
    to: '/dashboard'
  */},
  {
    icon: Survey,
    label: 'Survey',
    to: '/survey'
  },
  {
    icon: Review,
    label: 'Review',
    to: '/review'
  },
  {
    icon: Popular,
    label: 'Popular',
    to: '/popular'
  },

]

class NavBar extends Component {
  state = {
    anchorEl: null,
    redirect: null
  }

  handleClick = (evt) => {
    this.setState({ anchorEl: evt.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleRedirectTo = (path) => {
    this.setState({ redirect: path, anchorEl: null })
  }

  _onLogout = () => {
    global.store.dispatch(requestUserLogout());
    window.localStorage.clear()
  }

  render() {
    const { user } = global.store.getState();

    return (
      <div className="nav-bar">
        {
          this.state.redirect &&
          <Redirect to={this.state.redirect} />
        }
        {
          // reset redirect to null
          this.state.redirect &&
          this.setState({ redirect: null })
        }
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand as={Link} to="/">
              <img
                src={LOGO}
                height="50"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                {
                  navBarButtons.map(({ icon, label, to }, key) => (
                    <Nav.Link key={key} as={Link} to={to}>
                      <NavBarIcon icon={icon} label={label} />
                    </Nav.Link>
                  ))
                }
                {
                  user._id ?
                    <NotificationIcon />
                    :
                    <NotificationIconPublic />
                }
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "0px 10px 0px 10px" }} >
                  {
                    (!user._id) ?
                      <Nav.Link style={{ borderRadius: 30, padding: "0px 10px 0px 10px" }} as={Link} className="btn btn-primary signin" to="/signin">Sign In</Nav.Link>
                      :
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                          <IconButton className='bg-light' onClick={this.handleClick} ><MyAvatar redirect={false} size={45} user={user}/></IconButton>
                        </div>
                        <Menu
                          anchorEl={this.state.anchorEl}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleClose}
                          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                          transformOrigin={{ vertical: "top", horizontal: "center" }}
                          style={{
                            marginTop: 50
                          }}
                          elevation={1}
                        >
                          <MenuItem onClick={() => this.handleRedirectTo('/profile')}>Profile</MenuItem>
                          <Divider />
                          {/* <MenuItem onClick={() => this.handleRedirectTo('/setting')}>Setting</MenuItem>
                          <Divider /> */}
                          <MenuItem onClick={() => this.handleRedirectTo('/contact-us')}>Contact Us</MenuItem>
                          <Divider />
                          <MenuItem className='text-danger' onClick={this._onLogout}>Logout</MenuItem>
                        </Menu>
                      </div>
                  }
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
};

export { NavBar };
