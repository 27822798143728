import React, { useState } from 'react'
import { Dialog, Typography, Divider, Input, Modal, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Chip, Avatar } from '@material-ui/core'
import { Row, Col, Container, Dropdown, Carousel } from 'react-bootstrap'
import LikeAfter from 'assets/images/like-after.png'
import LikeBefore from 'assets/images/like-before.png'
import UnlikeAfter from 'assets/images/unlike-after.png'
import UnlikeBefore from 'assets/images/unlike-before.png'
import './Commenting.scss'
import { Loader } from 'components/Loader'
import { postReviewComment, deleteComment, upvoteReview, postReviewReport, deleteReview, upvoteComment, replyComment, deleteReply, upvoteReply, downvoteReview, downvoteComment, downvoteReply } from 'redux/api'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { toast } from 'react-toastify'
import moment from 'moment'
import _ from 'lodash'
import ReviewPhotoview from 'components/ReviewPhotoview'
import { ReportDialog } from 'components/ReportDialog'

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

export const MyAvatar = ({ user, size, redirect = true }) => {

    const handleViewUserProfile = (userId) => {
        if(!redirect) return false
        if (!userId) return false
        return window.location = `/public-profile/${userId}`
    }

    if (user.avatar) {
        return (
            <Avatar className='my-avatar' onClick={() => handleViewUserProfile(user._id)} style={{ width: size || 30, height: size || 30 }} src={user.avatar} />
        )
    } else {
        return (
            <Avatar className='my-avatar' onClick={() => handleViewUserProfile(user._id)} style={{ width: 30, height: 30, backgroundColor: '#555555' }} >
                {user.name.charAt(0).toUpperCase()}
            </Avatar>
        )
    }
}

const InputText = ({ replyToId, replyTo, refresh, style, placeholder, backgroundColor, doneSubmit }) => {
    const [text, setText] = useState('')

    const submitComment = async (e) => {
        e.preventDefault()
        if (replyTo === "review") {
            const data = { comment: text }
            try {
                await postReviewComment(replyToId, data)
                refresh()
            } catch (e) {
                console.log('error reply review', e)
            }
        } else {
            const data = { reply: text }
            try {
                await replyComment(replyToId, data)
                refresh()
            } catch (e) {
                console.log('error reply comment', e)
            }
            doneSubmit()
        }
        setText('')
    }

    const currUser = global.store.getState().user

    return (
        <div className='d-flex justify-content-around align-items-center' style={style} >
            <MyAvatar user={currUser} />
            <form style={{ width: '90%', padding: 5 }} onSubmit={submitComment} >
                <Input
                    fullWidth
                    // autoFocus
                    placeholder={placeholder}
                    required
                    value={text} onInput={(evt) => setText(evt.target.value)}
                    disableUnderline
                    style={{
                        backgroundColor,
                        borderRadius: 20,
                        border: 'none',
                        padding: 10,
                        fontSize: 12,
                    }}
                />
            </form>
        </div>
    )
}

const ReplyComponent = ({ refresh, commentId, data, style }) => {
    const { _id: replyId, likesV2: upvote, unlikes: downvote, reply, user, createdAt } = data

    const handleDeleteComment = async () => {
        const r = window.confirm('Are you sure want to delete this post?')
        if (r) {
            try {
                await deleteReply(replyId)
                toast.success('Your reply deleted succesfully')
                refresh()
            } catch (e) {
                console.log('delete comment', e)
            }
        }
    }

    const deleteBtnVisibility = () => {
        const currUser = global.store.getState().user._id
        if (currUser === user._id) return 'inline'
        return 'none'
    }

    const handleUpvoteReply = async () => {
        try {
            await upvoteReply(replyId)
            refresh()
        } catch (e) {
            console.log('like comment', e)
        }
    }

    const handleDownvoteReply = async () => {
        try {
            await downvoteReply(replyId)
            refresh()
        } catch (e) {
            console.log('unlike comment', e)
        }
    }

    const haveUpvoted = () => {
        const currUser = global.store.getState().user._id
        const hasLiked = _.filter(upvote, (id) => (currUser === id))
        if (hasLiked.length > 0) return LikeAfter
        return LikeBefore
    }

    const haveDownvoted = () => {
        const currUser = global.store.getState().user._id
        const hasLiked = _.filter(downvote, (id) => (currUser === id))
        if (hasLiked.length > 0) return UnlikeAfter
        return UnlikeBefore
    }

    return (
        <div style={{ ...style, borderLeft: 'solid #55555522 1px', borderBottom: 'solid #55555522 1px' }} >
            <div className='d-flex justify-content-start align-items-center' >
                <MyAvatar user={user} />
                <div style={{ paddingLeft: 5, flexGrow: 1 }} >
                    <div className="d-flex">
                        <Typography className='font-weight-bold flex-grow-1' variant='caption' >{user.name}</Typography>
                        <Typography className='pl-1 text-secondary' variant='caption' >{timeAgo.format(new Date(createdAt), 'twitter') || 'just now'}</Typography>
                    </div>
                    <Typography className='flex-grow-1' style={{ fontSize: 12 }} >{reply}</Typography>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end pb-1' >
                <Typography onClick={handleDeleteComment} className='btn text-danger' style={{ fontSize: 10, paddingLeft: 5, display: deleteBtnVisibility() }} >Delete</Typography>
                <Typography style={{ fontSize: 10, paddingLeft: 5 }} ><img className='like-icon' onClick={handleUpvoteReply} src={haveUpvoted()} /> {upvote ? upvote.length : 0}</Typography>
                <Typography style={{ fontSize: 10, paddingLeft: 5 }} ><img className='like-icon' onClick={handleDownvoteReply} src={haveDownvoted()} /> {downvote ? downvote.length : 0}</Typography>
            </div>
        </div>
    )
}

const CommentComponent = ({ refresh, reviewId, data }) => {
    const { _id: commentId, likesV2: upvote, unlikes: downvote, comment, replies, user, createdAt } = data
    const [replyLimit, setReplyLimit] = useState(2)
    const [openReply, setOpenReply] = useState(false)
    const [openReportAs, setopenReportAs] = useState(false)

    const handleDeleteComment = async () => {
        const r = window.confirm('Are you sure want to delete this post?')
        // console.log('confirm', r)
        if (r) {
            try {
                await deleteComment(commentId)
                toast.success('Comment deleted succesfully')
                refresh()
            } catch (e) {
                console.log('delete comment', e)
            }
        }
    }

    const deleteBtnVisibility = () => {
        const currUser = global.store.getState().user._id
        if (currUser === user._id) return 'inline'
        return 'none'
    }

    const handleUpvoteComment = async () => {
        try {
            await upvoteComment(commentId)
            refresh()
        } catch (e) { }
    }

    const handleDownvoteComment = async () => {
        try {
            await downvoteComment(commentId)
            refresh()
        } catch (e) { }
    }

    const handleReplyComment = () => {
        setOpenReply(!openReply)
    }

    const handleCloseReply = () => {
        setOpenReply(false)
    }

    const haveUpvoted = () => {
        const currUser = global.store.getState().user._id
        const hasLiked = _.filter(upvote, (id) => (currUser === id))
        if (hasLiked.length > 0) return LikeAfter
        return LikeBefore
    }

    const haveDownvoted = () => {
        const currUser = global.store.getState().user._id
        const hasLiked = _.filter(downvote, (id) => (currUser === id))
        if (hasLiked.length > 0) return UnlikeAfter
        return UnlikeBefore
    }

    return (
        <div>
            <ReportDialog contentType='Comment' contentId={commentId} open={openReportAs} onClose={() => setopenReportAs(false)} />
            <Divider />
            <div className='d-flex justify-content-start align-items-center' style={{ padding: "0px 10px 0px 10px" }}  >
                <MyAvatar user={user} />
                <div className='flex-grow-1' style={{ paddingLeft: 5 }} >
                    <div className="d-flex">
                        <Typography className='font-weight-bold flex-grow-1' variant='caption' >{user.name}</Typography>
                        <Typography className='pl-1 text-secondary' variant='caption' >{timeAgo.format(new Date(createdAt), 'twitter') || 'just now'}</Typography>
                    </div>
                    <Typography variant='subtitle2' >{comment}</Typography>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end pr-2' >
                <Typography onClick={handleReplyComment} className='btn text-primary' style={{ fontSize: 10, paddingLeft: 5 }} >Reply</Typography>
                <Typography onClick={handleDeleteComment} className='btn text-danger' style={{ fontSize: 10, paddingLeft: 5, display: deleteBtnVisibility() }} >Delete</Typography>
                <Typography onClick={() => setopenReportAs(true)} className='btn text-danger' style={{ fontSize: 10, paddingLeft: 5 }} >Report</Typography>
                <Typography style={{ fontSize: 10, paddingLeft: 5 }} ><img className='like-icon' onClick={handleUpvoteComment} src={haveUpvoted()} /> {upvote ? upvote.length : 0}</Typography>
                <Typography style={{ fontSize: 10, paddingLeft: 5 }} ><img className='like-icon' onClick={handleDownvoteComment} src={haveDownvoted()} /> {downvote ? downvote.length : 0}</Typography>
            </div>
            <Divider />
            <div >
                {
                    replies.length > 0 &&
                    replies.slice(0, replyLimit).map((dataR, key) => (
                        <div key={key} style={{ width: '100%', display: 'flex' }}  >
                            <div style={{ width: '10%' }} />
                            <ReplyComponent style={{ padding: "0px 10px 0px 10px", width: '90%' }} key={key} data={dataR} refresh={refresh} />
                        </div>
                    ))
                }
            </div>
            <div style={{ width: '100%', textAlign: 'center' }} >
                {
                    replyLimit < replies.length &&
                    <small style={{ textAlign: 'center', fontSize: 10 }} className='btn text-primary' onClick={() => setReplyLimit(replyLimit + 2)} >show more replies{` (${Math.abs(replyLimit - replies.length)})`}</small>
                }
            </div>
            <div>
                {
                    openReply &&
                    <div style={{ width: '100%', display: 'flex', backgroundColor: '#55555522' }} >
                        <div style={{ width: '10%' }} />
                        <InputText doneSubmit={handleCloseReply} backgroundColor='white' placeholder='Add a reply...' style={{ padding: "0px 10px 0px 10px", width: '90%' }} replyToId={commentId} replyTo="comment" refresh={refresh} />
                    </div>
                }
            </div>
        </div>
    )
}

const Commenting = ({ open, onClose, data, refresh }) => {
    const [commentLimit, setCommentLimit] = useState(5)
    const [openReportAs, setopenReportAs] = useState(false)

    if (data) {
        let { body, user, _id: reviewId, createdAt, likes: upvote, unlikes: downvote, comments, attachments, incidentDateTime, aspect, feeling, service } = data
        const { operator_name, route_name, experience, stop_name, vehicle_plate, comment, location } = body
        const { latitude, longitude, address } = location
        if (!service) {
            service = {}
            service['title'] = ''
        }

        if (!aspect) {
            aspect = {}
            aspect['title'] = ''
        }
        const { icon, title: feelingTitle } = feeling

        const handleRefreshThisPost = () => {
            refresh(reviewId)
        }

        const handleUpvoteReview = async () => {
            const overlay = document.getElementById('like-overlay')
            overlay.style.zIndex = 10
            const clearOverlay = setTimeout(() => {
                overlay.style.zIndex = -99
                clearTimeout(clearOverlay)
            }, 1000)
            try {
                await upvoteReview(reviewId)
                handleRefreshThisPost()
            } catch (e) {
                console.log('error when like', e)
            }
        }

        const handleDownvoteReview = async () => {
            const overlay = document.getElementById('unlike-overlay')
            overlay.style.zIndex = 10
            const clearOverlay = setTimeout(() => {
                overlay.style.zIndex = -99
                clearTimeout(clearOverlay)
            }, 1000)
            try {
                await downvoteReview(reviewId)
                handleRefreshThisPost()
            } catch (e) {
                console.log('error when like', e)
            }
        }

        const clearStateWhenClose = () => {
            setCommentLimit(5)
            onClose()
        }

        const handleDeleteReview = async () => {
            const r = window.confirm('Are you sure want to delete this post?')
            if (r) {
                try {
                    await deleteReview(reviewId)
                    toast.success('Your review is deleted successfully')
                    clearStateWhenClose()
                } catch (e) {
                    console.log('error when delete review', e)
                }
            }
        }

        const deleteBtnVisibility = () => {
            const currUser = global.store.getState().user._id
            if (currUser === user._id) return 'inline'
            return 'none'
        }

        const haveUpvoted = () => {
            const currUser = global.store.getState().user._id
            const hasLiked = _.filter(upvote, ({ user }) => (currUser === user._id))
            if (hasLiked.length > 0) return LikeAfter
            return LikeBefore
        }

        const haveDownvoted = () => {
            const currUser = global.store.getState().user._id
            const hasLiked = _.filter(downvote, ({ user }) => (currUser === user._id))
            if (hasLiked.length > 0) return UnlikeAfter
            return UnlikeBefore
        }

        return (
            <Dialog maxWidth='lg' fullWidth open={open} onClose={clearStateWhenClose} >
                <p onClick={clearStateWhenClose} className='close-button btn' >X</p>
                <ReportDialog contentType='Review' contentId={reviewId} open={openReportAs} onClose={() => setopenReportAs(false)} />
                {
                    data &&
                    <Container fluid >
                        <Row >
                            <Col className='pl-0 pr-0 d-flex align-items-center justify-content-center' md={8} style={{ backgroundColor: 'black', height: '90vh', overflow: 'hidden' }} >
                                <div className='image-container' >
                                    {
                                        attachments.length > 1 ?
                                            <Carousel className='carousel-container' onDoubleClick={handleUpvoteReview} >
                                                {
                                                    attachments.map((src, key) => (
                                                        <Carousel.Item key={key} >
                                                            <ReviewPhotoview src={src} />
                                                        </Carousel.Item>
                                                    ))
                                                }
                                            </Carousel>
                                            :
                                            <div className='single-image-container' onDoubleClick={handleUpvoteReview} >
                                                <ReviewPhotoview src={attachments[0]} />
                                            </div>
                                    }
                                </div>
                                <div id='like-overlay' className='like-overlay' ><img width={100} height={100} src={LikeBefore} /></div>
                                <div id='unlike-overlay' className='like-overlay' ><img width={100} height={100} src={UnlikeBefore} /></div>
                            </Col>
                            <Col style={{ height: '90vh', overflowY: 'scroll', overflowX: 'hidden' }} className='pl-0 pr-0' md={4} >
                                <div>
                                    <div className='d-flex align-items-center' style={{ padding: '20px 10px 20px 10px' }} >
                                        <div className='flex-grow-1 d-flex align-items-center' >
                                            <MyAvatar size={60} user={user} />
                                            <div className='ml-2' >
                                                <div className='d-flex flex-wrap' >
                                                    <Typography variant='caption' className='m-0 p-0' >
                                                        <b style={{ fontSize: 15 }} >{user.name}</b>
                                                        {` says ${feelingTitle}`}
                                                        <img className='mr-1 ml-1' src={icon} width={20} height={20} />
                                                        {`with the ${aspect.title} of ${service.title}
                                                        happened in ${location.address === 'Drag marker to select your location' ? 'this location' : location.address}`}
                                                    </Typography>
                                                </div>
                                                <Typography style={{ fontSize: 10 }} >{moment(new Date(createdAt).valueOf()).format('dddd, MMMM Do YYYY HH:mm:ss')}</Typography>
                                            </div>
                                        </div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='link' id="dropdown-basic" />
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setopenReportAs(true)} >Report...</Dropdown.Item>
                                                <Dropdown.Item style={{ display: deleteBtnVisibility() }} onClick={handleDeleteReview} className='text-danger' >Delete post</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div style={{ padding: 15 }} >
                                        <p>{experience}</p>
                                        <small className='m-0 p-0' >Incident Date: {moment(new Date(incidentDateTime).valueOf()).format('MMMM Do YYYY')} </small><br />
                                        <small className='m-0 p-0' >Incident Time: {moment(new Date(incidentDateTime).valueOf()).format('HH:mm:ss')} </small>
                                    </div>
                                    <ExpansionPanel style={{ backgroundColor: '#55555511' }} >
                                        <ExpansionPanelSummary
                                        >
                                            <small ><b>See more</b></small>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails >
                                            <small>
                                                <p>INCIDENT DATE: {moment(new Date(incidentDateTime).valueOf()).format('dddd, MMMM Do YYYY HH:mm:ss')} </p>
                                                <p>ASPECT: {aspect.title} </p>
                                                <p>LINE / ROUTE: {route_name} </p>
                                                <p>OPERATOR / COMPANY: {operator_name} </p>
                                                <p>STATION / STOP / TERMINAL: {stop_name} </p>
                                                <p>PLATE NUMBER: {vehicle_plate} </p>
                                                <p>ADDRESS: {address} </p>
                                            </small>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <Divider />
                                    <div className='d-flex align-items-center' style={{ padding: 10 }} >
                                        <Typography className='flex-grow-1' variant='caption' >{comments ? comments.length : 0} comments</Typography>
                                        <Typography style={{ fontSize: 10, paddingLeft: 5 }} variant='caption' ><img className='like-icon' onClick={handleUpvoteReview} src={haveUpvoted()} /> {upvote ? upvote.length : 0}</Typography>
                                        <Typography style={{ fontSize: 10, paddingLeft: 5 }} variant='caption' ><img className='like-icon' onClick={handleDownvoteReview} src={haveDownvoted()} /> {downvote ? downvote.length : 0}</Typography>
                                    </div>
                                    <Divider />
                                </div>
                                <div>
                                    <Divider />
                                    <InputText backgroundColor='#55555522' placeholder='Add a comment...' style={{ padding: "5px 10px 5px 10px" }} refresh={handleRefreshThisPost} replyTo={"review"} replyToId={reviewId} />
                                </div>
                                <div  >
                                    {
                                        comments.length > 0 &&
                                        comments.slice(0, commentLimit).map((data, key) => (
                                            <CommentComponent refresh={handleRefreshThisPost} reviewId={reviewId} key={key} data={data} />
                                        ))
                                    }
                                    <div style={{ width: '100%', textAlign: 'center' }} >
                                        {
                                            commentLimit < comments.length &&
                                            <small style={{ textAlign: 'center', fontSize: 12 }} className='btn text-primary' onClick={() => setCommentLimit(commentLimit + 5)} >show more comments{` (${Math.abs(commentLimit - comments.length)})`}</small>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }
            </Dialog>
        )
    }

    return (
        <Modal open={open} >
            <Loader loading={true} />
        </Modal>
    )


}

export default Commenting