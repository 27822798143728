import React from 'react'
import { Container } from 'react-bootstrap'

const PrivacyCookies = () => (
    <Container className='text-left main-container' >
        <h3>PRIVACY POLICY</h3>
        <br />
        <h5>Effective on July 21, 2020.</h5>
        <br />

        <p>This Privacy Policy document describes what information we hold, why we hold it and how we use it. This policy applies to anyone who uses our website, www.MaaSTransitAdvisor.com (the “<b><b>MTA</b></b> Site”), a platform operated by Atur Trafik Sdn Bhd, a company registered in Malaysia, with the company number 200801032791 and its operating address at Unit 3, Level 3, Bangunan Komersial Kejiranan NC2, Jalan P16/1, Presint 16, 62150 Putrajaya, Malaysia (“<b><b>MTA</b></b>”, or “we,” or “us” or “our”). We are the data controller as defined by <b>Malaysian Personal Data Protection Act 2010 (PDPA)</b>. If you have any questions about this policy or want to contact our Data Protection Officer, please send an email to <a href="mailto:policy@maastransitadvisor.com">policy@maastransitadvisor.com</a>.</p>

        <br />
        <h5><b>WHAT INFORMATION DO WE HOLD AND WHY?</b></h5>
        <p>There are three types of information we process: (1) information you provide, (2) information created when you use features in the <b>MTA</b> Site, and (3) information from other sources.</p>

        <br />
        <p><b>1. Information you provide</b></p>
        <p>When you use the Site, you might provide us with (a) personal information; (b) places and preferences; and (c) direct communications.</p>
        <ol type="a">
            <b><li>Personal Information</li></b>
            <p>If you create a MaasTransitAdvisor account, you provide us with your name and email address, which we use to ensure your saved places and other personalisation features are synced across your multiple devices. When you log in using Facebook or Google, we receive your name and email address from them.</p>

            <b><li>Places and Preferences</li></b>
            <br />
            <b><li>Direct Communications</li></b>
            <p>When you communicate with us (for example when you respond to on-site survey questions, report problems, raise queries etc.) we may receive information about the type of device you are using, your service provider and, if you have enabled the location permissions in your device, the latitude and longitude of your location when you contact us. We only use this information to fix problems of the <b>MTA</b> Site.</p>

            <br />
            <p><b>Information created when you use the <b>MTA</b> Site</b></p>
            <p>The following are the types of information created when you use the <b>MTA</b> Site.</p>

            <b><li>Location Information</li></b>
            <p>We use the location data described in this “Location Information” section to improve results on the <b>MTA</b> Site and for the purposes identified below.</p>

            <p><b>Users of the <b>MTA</b> Site: </b>if you have enabled your device's location services we may collect location information when you access the <b>MTA</b> Site.</p>

            <b><li>Device Information</li></b>
            <p>We may collect information about the devices you use to access the <b>MTA</b> Site, including the type of computer or device you use, the hardware model, operating system and versions, software, file names and versions, the preferred language, and time zone settings. This information is necessary for us to diagnose bugs and improve the <b>MTA</b> Site. We do not collect any permanent device ID numbers.</p>

            <b><li>Log and Usage Information</li></b>
            <p>Each time you use the <b>MTA</b> Site, we collect information about how and when you use it (such as the time and date, searches on the <b>MTA</b> Site and results you select, pages visited, site crashes and other system activities). We use this information to improve our <b>MTA</b> Site in multiple ways. For example, fixing bugs preventing future crashes and working out which features our users find most helpful.</p>

            <b><li>Cookies</li></b>
            <p>The <b>MTA</b> Site may use cookies to store certain information. Cookies are pieces of information that a website or app transfers to your hard drive or mobile device to store and sometimes track information about you. Although they identify a user's device, cookies do not reveal the name, email address, or other obvious identifiers of users.</p>
            <p>We try to make your experience of the <b>MTA</b> Site simple and meaningful. When you visit our <b>MTA</b> Site, our server sends a cookie to your computer or mobile device (depending on how you access the <b>MTA</b> site). A number of cookies we use last only for the duration of your web session and expire when you close the <b>MTA</b> Site. These are known as “session cookies”. Other cookies are used to remember you when you return to the <b>MTA</b> Site, and will last longer. These are known as “persistent cookies”.</p>

            We use cookies to:
            <ul>
                <li>remember that you have visited us before, which allows us to identify the number of unique visitors we receive. This allows us to make sure we have enough capacity for the number of users that we get and to; customise elements of the promotional layout and/or content of the pages of the Site; and</li>
                <li>collect information about how you use the <b>MTA</b> Site so that we can improve the <b>MTA</b> Site and learn which parts of the <b>MTA</b> Site are most useful.</li>
            </ul>

            <p>Some of the cookies used by the <b>MTA</b> Site are set by us and some are set by third-party analytics and crash/error-reporting companies as described in this policy.</p>
            <p>Most browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set. You can also learn more about cookies by visiting <a target="_blank" href='www.allaboutcookies.org' >www.allaboutcookies.org</a> which includes additional useful information on cookies and how to block cookies using different types of browsers. By blocking or deleting cookies you may not be able to take full advantage of the <b>MTA</b> Site's features. </p>

            <br />
            <p><b>2. Information from Other Sources</b></p>
            <p>The <b>MTA</b> Site collects the following types of information from other sources.</p>
            <ol type='a' >
                <li><p>Log-in Information</p>
                    <p>If you log into your <b>MTA</b> Site account using Facebook or Google, we receive your name and email address. We do not collect or use any other information received from those two companies.</p></li>
            </ol>

            <br />
            <p><b>HOW WE USE YOUR INFORMATION, SUMMARISED</b></p>
            <p>In summary, we use the information we collect to:</p>
            <ul>
                <li>Provide and improve our <b>MTA</b> Site;</li>
                <li>Build a profile of your preferences so we can personalise the <b>MTA</b> Site, and provide you with relevant feedback information;</li>
                <li>Identify issues with the operation of the <b>MTA</b> Site, and provide crash reports in order to find resolution for performance issues;</li>
                <li>Improve the design, functionality and/or content of the pages of the <b>MTA</b> Site and customise them for you;</li>
                <li>Carry out research on users' transport choices to improve the <b>MTA</b> Site</li>
                <li>Disclose to lawful authorities when required to do so by law, or when appropriate, in our opinion, to respond to their request.</li>
            </ul>

            <p>We work hard to improve the <b>MTA</b> Site and add functionality, which we think will make it safer, more fun and more useful. New functionality may involve similar or incidental uses of your data to those set out above. We regularly review the way we use data and will update our privacy policy if anything changes.</p>

            <br />
            <p><b>WHAT THIRD PARTIES RECEIVE YOUR INFORMATION?</b></p>
            <p>From time to time, we may share information with third parties to improve the <b>MTA</b> Site and enhance user experience. To the extent possible we pseudonymise (remove information that identifies you) and minimize the data we send to them.</p>
            <ul>
                <li>Search Partners: We have search partners like Google, OpenStreetMaps, Apple, and Foursquare so you can find the places you want to go in the <b>MTA</b> Site. They receive the place name you have searched for and to make your search results as relevant as possible, they receive your current location (if you have enabled location services in your device).</li>
                <li>Analytics Vendors: We use third party vendors like Mixpanel, Flurry and Google Analytics to understand what you have tapped or clicked on the <b>MTA</b> Site. They receive information showing only that someone has tapped or used certain features in the <b>MTA</b> Site. They organise that information and give it back to us so that we can make improvements to the <b>MTA</b> Site.</li>
                <li>Communication Platforms: To send you all other emails and update about our services, we use communication platforms such as Sendgrid and Mailchimp.</li>
            </ul>

            <br />
            <p><b>WHAT IS OUR LEGAL BASIS FOR PROCESSING YOUR INFORMATION?</b></p>
            <p>We will only process your personal information where we have a legal basis for doing so. Our legal basis to process your personal data includes processing that is:</p>
            <ul>
                <li>
                    necessary for our legitimate interests, which are:
                    <ul>
                        <li>to research, develop, fix and provide new and tailored features for the <b>MTA</b> Site users;</li>
                        <li>to provide on-trip support to users and to ensure that the <b>MTA</b> Site is secured for users;</li>
                        <li>to verify users' identity to prevent fraudulent or unauthorised use of our services; and</li>
                        <li>to prevent breaches of our terms and policies or to prevent harmful or illegal activity;</li>
                    </ul>
                </li>
                <li>necessary for the performance of our contract with you;</li>
                <li>necessary to comply with relevant legal and regulatory requirements; and</li>
                <li>based on your consent (for example, where you provide us with marketing consents), which may be withdrawn at any time by contacting us at the email address specified below.</li>
            </ul>

            <br />
            <p><b>WHAT ARE YOUR RIGHTS IN RELATION TO PERSONAL DATA WE HOLD ABOUT YOU?</b></p>

            <br />
            <p><b>Access</b></p>
            <p>You have a legal right to request access to a copy of the personal information we hold about you. In order to do this, contact us at <a href="mailto:policy@maastransitadvisor.com">policy@maastransitadvisor.com</a>. You will need to have created an account in order for us to provide you with your information. You must have access to the email address that you used when you created the account so that we can verify your identity.</p>

            <br />
            <p><b>Deletion</b></p>
            <p>We store your personal information for as long as is necessary to provide our services. You have the right to request we delete your personal information if you believe that we no longer need it for the purposes for which it was provided, you wish to withdraw your consent (and we have no other lawful basis to use the data), or we are not using your information in a lawful matter. For legal reasons, we may not always be able to delete your information.</p>
            <p>Please use the subject line “Account Deletion” when you ask us to delete your personal information by emailing us at <a href="mailto:policy@maastransitadvisor.com">policy@maastransitadvisor.com</a>.</p>
            <p>Note that residual copies may take longer to be removed from our backup systems which cannot be immediately overwritten. Where residual copies are still kept on our back-up systems, they are merely held on the system and are not used for any other purpose. We will keep a record of your request in order to ensure compliance with legal obligations. We will keep a record of your request in order to ensure compliance with legal obligations.</p>

            <br />
            <p><b>Marketing opt-out</b></p>
            <p>You have the right at any time to prevent the use of your personal information for direct marketing purposes. If you no longer want to receive marketing messages from us, please let us know by writing to us at <a href="mailto:policy@maastransitadvisor.com">policy@maastransitadvisor.com</a> or clicking on the unsubscribe button at the bottom of marketing emails and you will be unsubscribed accordingly. Please note that sometimes it can take a short amount of time to refresh our records for these purposes. Also, we may still send you non-promotional, transactional messages, or information about your account.</p>

            <br />
            <p><b>Notifications</b></p>
            <p>We may send you information we think you may find useful (e.g. public transport services disruptions and alerts relevant to you) or which you have requested from us by push notification and/or by email (if provided). You can opt-out of receiving further notifications or emails if you wish.</p>

            <br />
            <p><b>HOW WE KEEP YOUR INFORMATION SECURE</b></p>
            <p>We place great importance on the security of all information associated with our users. We use measures such as encryption, pseudonymisation, information access controls, and firewalls. Keep in mind that submission of information over the internet and mobile networks is never entirely secure. We cannot guarantee the security of information you submit via the <b>MTA</b> Site whilst it is in transit over the internet or mobile networks and any such submission is at your own risk.</p>

            <br />
            <p><b>HOW LONG WE KEEP YOUR INFORMATION</b></p>
            <p>By providing you with our services, we create records that contain your information, such as customer account records and activity records. We manage our records to help us to serve our users well and to comply with legal and regulatory requirements. Records help us demonstrate that we are meeting our responsibilities and are evidence of our business activities.</p>
            <p>How long we keep records depends on the type of record, the nature of the activity, service and the applicable legal or regulatory requirements. How long we retain your information may change based on business or legal and regulatory requirements.</p>

            <br />
            <p><b>WHAT COUNTRIES WILL YOUR PERSONAL INFORMATION BE SENT TO?</b></p>
            <p>Information that you submit via the <b>MTA</b> Site is sent to and stored on secure servers located in Singapore. Information submitted by you may be transferred by us to our other offices and/or to the third parties mentioned in the circumstances described above, which may be situated outside Malaysia and Singapore.</p>

            <br />
            <p><b>DO YOU COLLECT CHILDREN'S INFORMATION?</b></p>
            <p>We do not knowingly collect personal information from persons under the age of 18. If you are under the age of 18, please do not submit any personal information through the <b>MTA</b> Site or email. We encourage parents and legal guardians to monitor their children's internet use and to help enforce our privacy policy by instructing their children never to provide personal information on the <b>MTA</b> Site without their permission. If you have reason to believe that a person under the age of 18 has provided personal information to us, please contact us as specified below, and we will work to delete that information from our databases.</p>

            <br />
            <p><b>HOW WILL YOU KNOW ABOUT CHANGES TO OUR PRIVACY POLICY?</b></p>
            <p>We may revise this privacy policy from time to time. Any changes and additions to this privacy policy will be posted on the <b>MTA</b> Site and are effective from the date on which they are posted. Please review this privacy notice from time to time to check whether we have made any changes to the way in which we use your personal data. This privacy policy was last updated on 21 July 2020.</p>

            <br />
            <p><b>WHAT IF YOU HAVE A COMPLAINT?</b></p>
            <p>We work hard to handle your information responsibly. If you are unhappy about the way we do this, please contact our officer by email to <a href="mailto:policy@maastransitadvisor.com">policy@maastransitadvisor.com</a>.</p>

            <br />
            <p><b>HOW TO GET IN TOUCH WITH US</b></p>
            <p>Please submit any questions, concerns or comments you have about this privacy policy or any requests concerning your personal data by email to <a href="mailto:policy@maastransitadvisor.com">policy@maastransitadvisor.com</a>. You can also reach us at Unit 3, Level 3, Bangunan Komersial Kejiranan NC2, Jalan P16/1, Presint 16, 62150 Putrajaya, Malaysia.</p>
        </ol>
        <p>Updated: 27 July 2020</p>
    </Container>
)
export default PrivacyCookies