import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Table, TableHead, TableCell, TableBody, TableContainer, Card } from '@material-ui/core'
// import '../ContentPages.scss'

const aspectMeaning = [
    { aspect: 'Information', des: { en: 'completeness and accuracy of information, including information on service schedules, real-time arrival and departure information, fare information, announcements of latest information and others', bm: 'Berkaitan dengan kelengkapan dan ketepatan maklumat, termasuk maklumat tentang jadual servis, waktu sebenar ketibaan dan perlepasan, maklumat tambang, pengumuman maklumat terkini dan sebagainya' } },
    { aspect: 'Trip Experience', des: { en: 'Includes route / travel conditions, level of service, frequency of routes / trips, adequacy of trips, conditions of connection or exchange of services and travel time', bm: 'Merangkumi keadaan laluan/ perjalanan, tahap perkhidmatan, kekerapan dan kelengkapan laluan/ perjalanan, kecukupan trip, keadaan sambungan atau pertukaran servis, masa perjalanan dan sebagainya' } },
    { aspect: 'Driver', des: { en: 'One who operates and driving the vehicle', bm: 'Pemandu yang mengendali kenderaan awam tersebut' } },
    { aspect: 'Customer Service', des: { en: 'Direct or indirect customer services for example; inquiry counters, ticket counters, hotline services and more.', bm: 'Khidmat pelanggan secara langsung atau tidak langsung sebagai contoh; kaunter pertanyaan, kaunter tiket, perkhidmatan talian hotline dan sebagainya.' } },
    { aspect: 'Journey Planning', des: { en: 'Journey planning before starting the trip as transportation mode options, departure time, destination time and so on.', bm: 'Perancang perjalanan sebelum memulakan perjalanan.Pilihan mod pengangkutan, masa bertolak, masa sampai destinasi dan sebagainya.' } },
    { aspect: 'Fare, Payment & Ticketing', des: { en: 'With respect to fares, tickets, travel pass and tokens, payment methods, ticket systems and more', bm: 'Berkaitan dengan tambang, tiket, token pas perjalanan, cara bayaran, sistem tiket dan sebagainya' } },
    { aspect: 'Health, Safety & Security', des: { en: 'In relation to safety and health issues in public transport, or at the terminal / station. Includes personal aspects such of driver hygiene practices and others.', bm: 'Berkait dengan isu keselamatan dan kesihatan di dalam pengangkutan awam, atau di terminal/ station. Merangkumi aspek peribadi seperti contoh amalan kebersihan pemandu dan lain- lain.' } },
    { aspect: 'Vehicle Condition', des: { en: 'Includes all aspects of the condition inside the vehicle including comfort, cleanliness, and safety.', bm: 'Merangkumi seluruh aspek keadaan atau kondisi di dalam kenderaan iaitu keselesaan, kebersihan, dan keselamatan.' } },
    { aspect: 'Interchange between travel modes', des: { en: 'Related to interchange when changing mode of transportation. Whether to get out of the terminal / station or not.', bm: 'Berkaitan pertukaran station apabila bertukar mod pengangkutan. Sama ada perlu keluar dari terminal/ stesen atau tidak.' } },
    { aspect: 'Station / Stop / Terminal Condition', des: { en: 'Covers all aspects of the conditions in and around the station / stop / terminal; comfort, cleanliness, and safety.', bm: 'Merangkumi seluruh aspek keadaan atau kondisi di dalam dan sekitar stesen/ perhentian/ terminal iaitu keselesaan, kebersihan, dan keselamatan.' } },
    { aspect: 'Coverage and Accessibility', des: { en: 'Related to coverage of public transport networks, connectivity and integration with other modes of transport with places of interest', bm: 'Berkait dengan hubungan dann liputan rangkaian pengangkutan awam, penyambungan dan penyatuan dengan mod pengangkutan lain dengan tempat menarik' } },
    { aspect: 'New Route / Stop Suggestion', des: { en: 'Proposed route or stop depends on the suitability of the origin and destination', bm: 'Cadangan laluan atau perhentian menigkut kesesuaian origin dan destinasi' } },
    { aspect: 'Other', des: { en: 'Other aspects that are not included in the listed', bm: 'Lain- lain aspek yang tidak termasuk di dalam aspek yang telah disenaraikan' } },
]

const AspectTable = ({ lang }) => {
    return (
        <TableContainer className='mb-5 mt-3' component={Card} >
            <Table >
                <TableHead>
                    <TableCell>Which Aspect</TableCell>
                    <TableCell>{lang === 'en' ? 'Explaination' : 'Penerangan'}</TableCell>
                </TableHead>
                {
                    aspectMeaning.map(({ aspect, des }, i) => {
                        return (
                            <TableBody key={i} >
                                <TableCell>{aspect}</TableCell>
                                <TableCell>{des[lang]}</TableCell>
                            </TableBody>
                        )
                    })
                }
            </Table>
        </TableContainer>
    )
}


const Faq = () => {
    return (
        <Container className='text-left main-container' >
            <h3>Frequently Asked Questions (FAQs)</h3>
            <br />
            <ExpansionPanel>
                <ExpansionPanelSummary>English</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ol>
                        <b><li>What is the function or usage of MTA?</li></b>
                        <p>MaaS Transit Advisor (MTA) is a single platform for all modes of public transportation (currently focusing on Klang Valley only), allowing information sharing and providing opportunity to the users to give comments and suggestions on public transportation matters to improve the quality of current transportation service.</p>

                        <b><li>What is the meaning of MaaS?</li></b>
                        <p>MaaS stands for Mobility-as-a-Service, giving the meaning of the integration of multiple modes of transportation service into a mobility service on its own, which can be achieved based on user’s demand. For further information, please visit <Link to='/maas' >https://www.maastransitadvisor.com/maas</Link>.</p>

                        <b><li>How to access the MTA site?</li></b>
                        <p>Currently, the MTA is only available on web version:<Link to='/' >https://www.maastransitadvisor.com</Link>. It can be accessed via the internet browser on a mobile phone.</p>

                        <b><li>How to use MTA?</li></b>
                        <p>Users can just visit <Link to='/' >https://www.maastransitadvisor.com</Link>, sign in and leave comments.</p>

                        <b><li>What information is needed to use MTA?</li></b>
                        <p>Users just need a verified email address for registration on the MTA website. However, users are not required to register if they do not want to use the commenting service.</p>

                        <b><li>What information can I obtain here?</li></b>
                        <p>Users can read reviews related to public transportation service commented by other users, access to information on public transportation and many more.</p>

                        <b><li>Is the information that I shared on MTA can be viewed by other users?</li></b>
                        <p>Personal information such as phone number and email address cannot be viewed by other users, except the written comments by users.</p>

                        <b><li>Will MTA inform the passenger if road closure or adjustment on the public transport schedule happened?</li></b>
                        <p>MTA will not inform the passenger of any road closure or adjustment on the public transport schedule. Users will need to visit the official information portal for more information.</p>

                        <b><li>What are the explanations of each item in <i>Which Aspect</i> part in the <i>Review section</i>?</li></b>
                        <AspectTable lang='en' />

                        <b><li>What is meant by <i>Others</i> in <i>Which Service</i> in the Review section?</li></b>
                        <p>Others mean besides all services listed, including cycling and walking.</p>

                        <b><li>What should I do if I forget my email/username or password?</li></b>
                        <p>Users can click on the ‘Forget Username’ or ‘Forget Password’ to reset username and password through the notification email.</p>

                        <b><li>Who should I ask if I have any problem or question on MTA?</li></b>
                        <p>Any problem or question regarding the MTA can report to <b>info@maastransitadvisor.com</b> or the service phone number: +603-8893 0488 / 5700.</p>
                    </ol>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <br />

            <ExpansionPanel>
                <ExpansionPanelSummary>Bahasa Malaysia</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ol>
                        <b><li>Apakah fungsi atau kegunaan MTA?</li></b>
                        <p>MTA adalah platform tunggal bagi semua mod pengangkutan awam di Lembah Klang yang berfungsi sebagai penyalur maklumat dan pemberi ruang untuk pengguna memberikan ulasan dan cadangan mengenai pengangkutan awam bagi meningkatkan mutu servis perkhidmatan sedia ada.</p>

                        <b><li>Apakah yang dimaksudkan dengan MaaS?</li></b>
                        <p>MaaS adalah Mobility as a Service yang memberi maksud gabungan pelbagai mod perkhidmatan pengangkutan ke dalam perkhidmatan mobiliti tunggal yang dapat dicapai berdasarkan permintaan. Untuk maklumat lanjut, sila layari  <Link to='/maas' >https://www.maastransitadvisor.com/maas</Link>.</p>

                        <b><li>Di manakah saya boleh mendapatkan aplikasi MTA?</li></b>
                        <p>Buat masa ini, MTA hanya terdapat di dalam versi laman sesawang <Link to='/' >https://www.maastransitadvisor.com</Link>. Ia juga boleh diakses menggunakan pelayar internet di telefon bimbit.</p>

                        <b><li>Bagaimanakah cara untuk menggunakan MTA?</li></b>
                        <p>Pengguna hanya perlu ke alamat laman sesawang <Link to='/' >https://www.maastransitadvisor.com</Link>, daftar masuk dan tinggalkan ulasan anda.</p>

                        <b><li>Apakah maklumat yang diperlukan jika saya mahu menggunakan MTA?</li></b>
                        <p>Pengguna hanya memerlukan alamat emel yang sah untuk pendaftaran di laman sesawang MTA. Walaubagaimana pun, pengguna tidak perlu mendaftar jika tidak mahu menggunakan perkhidmatan memberikan ulasan.</p>

                        <b><li>Adakah maklumat yang boleh saya perolehi melalui MTA?</li></b>
                        <p>Pengguna dapat membaca ulasan-ulasan yang ditinggalkan oleh pengguna lain yang berkaitan dengan perkhidmatan pengangkutan awam, mendapat akses maklumat perkhidmatan pengangkutan awam dan banyak lagi.</p>

                        <b><li>Adakah maklumat yang saya kongsikan di MTA boleh dilihat oleh pengguna lain?</li></b>
                        <p>Maklumat peribadi seperti nombor telefon dan alamat emel tidak akan dapat dilihat oleh pengguna lain, kecuali ulasan yang ditinggalkan oleh pengguna..</p>

                        <b><li>Jika terdapat penutupan jalan atau perubahan jadual pengangkutan awam, adakah MTA akan maklumkan kepada penumpang?</li></b>
                        <p>MTA tidak akan memaklumkan sekiranya berlaku penutupan jalan atau perubahan jadual pengangkutan awam. Pengguna perlu mengunjungi portal maklumat rasmi untuk maklumat lebih lanjut.</p>

                        <b><li>Apakah yang dimaksudkan dengan perkara di dalam <i>Which Aspect</i> di dalam bahagian <i>Review</i>?</li></b>
                        <AspectTable lang='bm' />

                        <b><li>Apakah yang dimaksudkan dengan perkara <i>Others</i> di dalam bahagian <i>Which Service</i>? pada seksyen Review?</li></b>
                        <p><i>Others</i> merujuk kepada servis lain yang tidak disenaraikan termasuklah berjalan kaki dan berbasikal.</p>

                        <b><li>Jika saya terlupa email/ username atau kata laluan, apakah yang boleh saya lakukan?</li></b>
                        <p>Pengguna boleh klik pada ‘Forgot Username’ atau ‘Forgot Password’ untuk set semula username atau kata laluan yang baharu melalui notifikasi email.</p>

                        <b><li>Jika terdapat sebarang masalah atau pertanyaan tentang MTA, kepada siapakah saya perlu bertanya?</li></b>
                        <p>Sebarang masalah atau pertanyaan tentang MTA boleh dilapor kepada <b>info@maastransitadvisor.com</b> atau telefon nombor perkhidmatan ini: 03-8893 0488/ 5700.</p>
                    </ol>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Container>
    )
}

export default Faq