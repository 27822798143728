import React, { useState } from 'react'
import { Card, CardContent, Button, CardActions, Typography } from '@material-ui/core'
import './MaasPollCard.scss'
import { Image, Container } from 'react-bootstrap'
import { BarChart, Bar, Legend, Tooltip } from 'recharts'
import { vote, getPollResult } from 'redux/api'
import _ from 'lodash'

const MaasPollCard = ({ title, description, imgSources, linkSrc, linkName }) => {

    const [answered, setAnswered] = useState(false)
    const [pollRes, setPollRes] = useState([{ yes: 0, no: 0 }])

    const handleCleanResult = (rawdata) => {
        const yes = _.filter(rawdata, o => o.answer === 'yes').length
        const no = _.filter(rawdata, o => o.answer === 'no').length

        setPollRes([{ yes, no }])
    }

    const handleVote = async (answer) => {
        const voteData = {
            question: title,
            answer,
            clientIP: localStorage.getItem('clientIP')
        }
        let voteResult
        try {
            voteResult = await vote(voteData)
        } catch (e) {
            voteResult = await getPollResult(title)
        }
        handleCleanResult(voteResult.data)
        setAnswered(true)
    }


    return (
        <Card className='maas-main' >
            <CardContent>
                <Typography variant='h5' >{title || ''}</Typography>
                <Typography >{description || ''}</Typography>
                <a target="_blank" href={linkSrc || ''}>{linkName || ''}</a>
                <div className='w-100 d-flex justify-content-center align-items-center maas-image' >
                    {
                        imgSources &&
                        imgSources.map((src, i) =>
                            <Image key={i} fluid width={`${50 / imgSources.length}%`} src={src} />
                        )
                    }
                </div>
            </CardContent>
            <CardActions className='card-action-area' >
                {
                    answered ?
                        <Container className='d-flex justify-content-end' >
                            <b>Result :</b>
                            <BarChart width={150} height={100} data={pollRes}>
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="yes" fill="#00A029aa" />
                                <Bar dataKey="no" fill="#ED154Eaa" />
                            </BarChart>
                        </Container>
                        :
                        <Container className='d-flex justify-content-end align-items-center' >
                            <div ><b>Is this useful for you?</b></div>
                            <Button onClick={() => handleVote('yes')} className='text-success' >Yes</Button>
                            <Button onClick={() => handleVote('no')} className='text-danger' >No</Button>
                        </Container>
                }
            </CardActions>
        </Card>
    )
}

export default MaasPollCard