import { connect } from 'react-redux';
import { requestUserSignUp } from 'redux/actions';
import VerifyEmailPage from './VerifyEmailPage';

const mapStateToProps = (state) => {
  return {    
    user: state.user    
  }
}

export default connect(mapStateToProps, {
  requestUserSignUp
})(VerifyEmailPage);