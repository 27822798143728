import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { UserReducer } from './user.reducer';
import { postReviewReducer, getReviewReducer } from './review.reducer';
import { reviewItemsReducer } from './reviewItems.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
}

const rootReducer = persistCombineReducers(persistConfig, {
  user: UserReducer,
  postReviewStatus: postReviewReducer,
  getReviewStatus: getReviewReducer,
  reviewItems: reviewItemsReducer
});

export default rootReducer;