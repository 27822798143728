import React from "react"
import AngryIcon from 'assets/images/feelings/unacceptable.png'
import LikeAfter from 'assets/images/like-after.png'
import UnlikeAfter from 'assets/images/unlike-after.png'
import { Col, Row } from "react-bootstrap"
import { Typography } from "@material-ui/core"
import './FeedCard.scss'
import Skeleton from "@material-ui/lab/Skeleton"
import Commenting, { MyAvatar } from './Commenting'
import TimeAgo from "javascript-time-ago"
import en from 'javascript-time-ago/locale/en'
import ReviewPhotoview from "components/ReviewPhotoview"

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

const FeedCard = ({ url, lg, md, xs, onClick, data }) => {
  if (data) {
    let { user, createdAt, likes, unlikes, comments, attachments, aspect, feeling, service } = data
    if (!service) {
      service = {}
      service['title'] = ''
    }

    if (!aspect) {
      aspect = {}
      aspect['title'] = ''
    }

    const { icon } = feeling

    return (
      < Col onClick={onClick} className='feed-container' lg={lg} md={md} xs={xs} >
        <div className='feed-container-main' >
          <div className='d-flex justify-content-around align-items-center user-header' >
            <div className='flex-grow-1 d-flex justify-content-left align-items-center' >
              <MyAvatar user={user} />
              <Typography className='white-font' variant='subtitle2' >{user.name}</Typography>
            </div>
            <Typography className='white-font' variant='subtitle2' >{timeAgo.format(new Date(createdAt), 'twitter') || 'just now'}</Typography>
          </div>
          <div className='image-container' >
            <ReviewPhotoview src={attachments[0]} />
          </div>
          <div className='detail-card'>
            <div className="d-flex justify-content-between align-items-baseline" >
              <Typography variant='h6' className="flex-grow-1" ><img style={{ paddingBottom: 2 }} src={icon} width={20} height={20} /> {aspect.title} / {service.title}</Typography>
              <Typography variant='caption' className="flex-shrink-1 text-right" >{comments.length} comments</Typography>
            </div>
            <div className="d-flex justify-content-between align-items-baseline" >
              <Typography variant='caption' ><img className='like-icon' src={LikeAfter} /> {likes ? likes.length : 0}</Typography>
              <Typography variant='caption' ><img className='like-icon' src={UnlikeAfter} /> {unlikes ? unlikes.length : 0}</Typography>
            </div>
          </div>
        </div>
      </Col>
    )
  }
  return (
    < Col onClick={onClick} className='feed-container' lg={lg} md={md} xs={xs} >
      <div className='feed-container-main' >
        <div className='d-flex justify-content-around align-items-center user-header' >
          <div className='flex-grow-1 d-flex justify-content-left align-items-center' >
            <MyAvatar user={{name:'username'}} />
            <Typography className='white-font' variant='subtitle2' >Username</Typography>
          </div>
          <Typography className='white-font' variant='subtitle2' >{timeAgo.format(new Date("2020-06-20T07:48:20.029Z"), 'twitter') || 'just now'}</Typography>
        </div>
        <div className='image-container' >
          <img className='image-image' src={url || 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/480px-No_image_available.svg.png'} />
        </div>
        <div className='detail-card'>
          <div className="d-flex justify-content-between align-items-baseline" >
            <Typography variant='h6' ><img style={{ paddingBottom: 2 }} src={AngryIcon} width={20} height={20} /> Bus / Driver</Typography>
            <Typography variant='caption' >30 comments</Typography>
          </div>
          <div className="d-flex justify-content-between align-items-baseline" >
            <Typography variant='subtitle2' >Driving Behaviour</Typography>
            <Typography variant='caption' >💜 1.3k</Typography>
          </div>
        </div>
      </div>
    </Col>
  )
}

const TrendingCard = ({ data }) => {

  let { user, createdAt, likes, unlikes, comments, attachments, aspect, feeling, service } = data
  if (!service) {
    service = {}
    service['title'] = ''
  }

  if (!aspect) {
    aspect = {}
    aspect['title'] = ''
  }

  const { icon } = feeling

  return (
    <div className='feed-container-main-trending' >
      <div className='d-flex justify-content-around align-items-center user-header' >
        <div className='flex-grow-1 d-flex justify-content-left align-items-center' >
          <MyAvatar user={user}/>
          <Typography className='white-font' variant='subtitle2' >{user.name}</Typography>
        </div>
        <Typography className='white-font' variant='subtitle2' >{timeAgo.format(new Date(createdAt), 'twitter') || 'just now'}</Typography>
      </div>
      <div className='image-container' >
        <ReviewPhotoview src={attachments[0]} />
      </div>
      <div className='detail-card'>
        <div className="d-flex text-left align-items-baseline" >
          <Typography variant='subtitle1' className="flex-grow-1" ><img src={icon} width={20} height={20} /> {aspect.title} - {service.title}</Typography>
          <Typography variant='caption' className="flex-shrink-1 text-right" >{comments.length} comments</Typography>
        </div>
        <div className="d-flex justify-content-between align-items-baseline" >
          <Typography variant='caption' ><img className='like-icon' src={LikeAfter} /> {likes ? likes.length : 0}</Typography>
          <Typography variant='caption' ><img className='like-icon' src={UnlikeAfter} /> {unlikes ? unlikes.length : 0}</Typography>
        </div>
      </div>
    </div>
  )
}

const SkeletonFeedCard = () => (
  <Col style={{ paddingBottom: 70, height: '35vh' }} className='feed-container' lg='4' md='6' xs='12' >
    <Row style={{ paddingBottom: 5 }} className="align-items-center" >
      <Col xs='2' >
        <Skeleton variant="circle" animation='wave' width={30} height={30} />
      </Col>
      <Col >
        <Typography variant='h6' >
          <Skeleton variant='text' animation='wave' />
        </Typography>
      </Col>
    </Row>
    <Skeleton variant="rect" animation='wave' width='100%' height='100%' />
    <Typography variant='h6' >
      <Skeleton variant='text' animation='wave' />
    </Typography>
    <Typography variant='subtitle2' >
      <Skeleton variant='text' animation='wave' />
    </Typography>
  </Col>
)

export { FeedCard, SkeletonFeedCard, Commenting, TrendingCard }