import React, { useEffect, useState } from 'react'
import './PublicProfilePage.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Typography, Card, CardContent, Avatar, Button } from '@material-ui/core'
import { FeedCard, SkeletonFeedCard, Commenting, Loader } from 'components'
import { useParams } from 'react-router-dom'
import { getReview, getReviewDetails, getPublicProfile } from 'redux/api'
import { ReportDialog } from 'components/ReportDialog'

const ProfilePage = () => {
    const [myReview, setMyReview] = useState(null)
    const [openComment, setOpenComment] = useState(false)
    const [openReportAs, setopenReportAs] = useState(false)
    const [commentDetails, setCommentDetails] = useState(undefined)
    const [user, setUser] = useState(null)
    const { userId } = useParams()

    useEffect(() => {
        handleFetchUserDetails(userId)
    }, [])

    useEffect(() => {
        user &&
            handleFetchMyReview()
    }, [user])

    const fetchReviewDetails = async (_id) => {
        try {
            const res = await getReviewDetails(_id)
            setCommentDetails(res.data)
        } catch (e) {
            console.log('error on get review detail', e)
        }
    }

    const handleFetchMyReview = async () => {
        const query = {
            filter: { user: userId },
            options: { sort: { 'createdAt': -1 } }
        }
        try {
            const res = await getReview(query)
            setMyReview(res.data)
        } catch (e) {
            console.log('error with fetching reviews', e)
        }
    }

    const handleFetchUserDetails = async (id) => {
        try {
            const res = await getPublicProfile(id)
            setUser(res.data)
        } catch (e) {
            console.log('error with fetching reviews', e)
        }
    }

    const handleOpenComment = (_id) => {
        setOpenComment(true)
        fetchReviewDetails(_id)
    }

    const handleCloseComment = () => {
        setOpenComment(false)
        setCommentDetails(undefined)
    }

    const handleRefresh = (_id) => {
        fetchReviewDetails(_id)
    }

    return (
        <Container >
            <Commenting refresh={handleRefresh} data={commentDetails} onClose={handleCloseComment} open={openComment} />
            {
                userId &&
                <ReportDialog contentType='User' contentId={userId} open={openReportAs} onClose={() => setopenReportAs(false)} />
            }
            {
                user ?
                    <div>
                        <Card elevation={0} className='card-profile' >
                            <CardContent>
                                <Row>
                                    <Col className='d-flex justify-content-center align-items-center' sm={2} xs={12} >
                                        <Avatar id='avatar' src={user.avatar ? user.avatar : ''} >
                                            {user.avatar ? '' : String(user.name || 'test-bot').charAt(0).toUpperCase()} {/* check whether got avatar, if none then use initial as avatar. test-bot is only for testing purpose  */}
                                        </Avatar>
                                    </Col>
                                    <Col className='text-sm-left text-xs-center' sm={10} xs={12}>
                                        <div className='p-2' >
                                            <Typography variant='h4' className='font-weight-bold' >{user.name || 'test-bot'}</Typography>
                                        </div>
                                        {
                                            user.about &&
                                            <Typography className='font-weight-bold p-2' >About Me <p className='font-weight-normal' >{user.about ? user.about : ''}</p></Typography>
                                        }                                        <div className='d-flex justify-content-sm-start justify-content-center' >
                                            <Typography className='font-weight-bold p-2' >Review <p className='font-weight-normal' >{myReview ? myReview.length : 0}</p></Typography>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Button onClick={() => setopenReportAs(true)} fullWidth color='primary' variant='text' >Report {user.name.split(' ')[0]}</Button>
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                        <Card elevation={0} className='body-card' >
                            <CardContent style={{ width: 'inherit' }} >
                                <div>
                                    {
                                        myReview ?
                                            <Row className="justify-content-start" >
                                                {
                                                    myReview.length > 0 ?
                                                        myReview.map((data, key) => <FeedCard onClick={() => handleOpenComment(data._id)} data={data} key={key} xs='12' md='6' lg='4' />)
                                                        :
                                                        <div className='text-center h-100 w-100' >
                                                            <Typography variant='subtitle1' >No review yet</Typography>
                                                        </div>
                                                }
                                            </Row>
                                            :
                                            <Row >
                                                {
                                                    [1, 2, 3].map((x, key) => <SkeletonFeedCard key={key} />)
                                                }
                                            </Row>
                                    }
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    :
                    <Loader loading={true} />
            }
        </Container>
    )
}

export default ProfilePage